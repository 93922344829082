import httpCommon from "./http-common";

const WarehouseService = {
    getWarehouses() {
        let data = httpCommon.get("/warehouse");
        return data;
    },
    getWarehouseById(id) {
        let data = httpCommon.get(`/warehouse/${id}`);
        return data;
    },
    getMaterialsByWarehouse(id) {
        let data = httpCommon.get(`/warehouse/${id}/material`);
        return data;
    },
    saveNewWarehouse(data) {
        let response = httpCommon.post("/warehouse",JSON.stringify(data))
        return response;
    },
    updateWarehouse(data) {
        let response = httpCommon.put(`/warehouse/${data.id}`,JSON.stringify(data))
        return response;
    },   
    getReportByWarehouse(id) {
        let data = httpCommon.get(`/materialreport/by_warehouses/${id}/0`)
        return data;
    },
    getProductMovesByWarehouse(warehouse,product) {
        let data = httpCommon.get(`/transactions/inventorymovement/_all_/0/${product}/${warehouse}`)
        return data;
    }
};

export default WarehouseService;