import React, { useEffect, useState } from 'react';
import useFullPageLoader from "../../Hooks/useFullPageLoader"
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import usePageTitle from "../../util/head-element";
import MaterialGroupService from '../../services/material-group';
import DivisionService from '../../services/division-service';
import { FormControl } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { objectToCsv, searchInTable } from "../../util/commons-utilities";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const MaterialGroupsManagement = () => {
    const dispatch = useDispatch();
    usePageTitle("MaterialGroups | Warehouse");
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Material Groups",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);


    const [data, setData] = useState(null)

    const [modalMaterialGroup, setModalMaterialGroup] = useState(false);
    const [modal_success, setmodal_success] = useState(false);
    const [groupID, setGroupID] = useState(0);
    const [name, setName] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [divisions, setDivisions] = useState([]);
    const [division, setDivision] = useState('');

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const[editing, setEditing] = useState(false);
    const captions = ["Id","Name"];

    function setCurrentMaterialGroup(group){
        setGroupID(group.id);
        setName(group.name);
        setDivision(group.division.id);
        setModalTitle('Update ' + group.name);
    }

    function newMaterialGroup(){
        setCurrentMaterialGroup({
            id:0,
            name:"",
            division:{id:0, name:""}
        });
        setModalTitle('Add New');
        tog_MaterialGroup();
        setEditing(false);
    }

    function editMaterialGroup(group){
        setCurrentMaterialGroup(group);
        tog_MaterialGroup();
        setEditing(true);
    }

    function save_MaterialGroup() {
        let group = {
            id: groupID,
            division: division,
            name: name
        };
        showLoader();
        if(!editing){
            MaterialGroupService.saveNewMaterialGroup(group)
            .then((response) => {                
                refreshData(response.data);
                setModalMaterialGroup(false);
                setModalMessage('Material Group Added Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);},1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }
        else{
            MaterialGroupService.updateMaterialGroup(group)
            .then((response) => {               
                refreshData(response.data);
                setModalMaterialGroup(false);
                setModalMessage('Material Group Updated Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);},1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }       
    }

    function tog_MaterialGroup() {
        setModalMaterialGroup(!modalMaterialGroup);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    function refreshData(group){
        let currentData = data;
        if(!editing){
            currentData.push(group);
        }
        else{
            let index = currentData.findIndex(m => {return m.id === groupID;});
            currentData[index] = group;
        }
        setData(currentData);
    }

    useEffect(() => {
        showLoader();
        MaterialGroupService.getMaterialGroups()
            .then((response) => {              
                setData(response.data)
            })
            .catch(err => console.dir(err))
            .finally(() => {hideLoader()});
            
        DivisionService.getDivisions()
            .then((response) => {              
                setDivisions(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"material-types-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("groups", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newMaterialGroup()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="material-types-table-container" className="table-responsive">
                                        <table id="material-types-table" className="table table-hover mb-0">
                                            <thead>
                                                <tr className="header">
                                                    <th className="dark">Id</th>
                                                    <th className="dark">Division</th>
                                                    <th className="dark">Name</th>
                                                    <th className="dark">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {!!data && data.length > 0 ? data.map((item, key) => {
                                                return(
                                                    <tr key={`material-types-table-tbody-tr-${key}`}>
                                                        <td>{item.id}</td>
                                                        <td>{item.division.name}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            <Button variant="outline-secondary" size="sm" onClick={()=>editMaterialGroup(item)}><i className="bx bx-edit me-1"></i> Edit</Button>                                                        
                                                        </td>
                                                    </tr>
                                                )   
                                                }):(<tr><td colSpan={3}>No data found.</td></tr>)
                                            }
                                            </tbody>
                                        </table>    
                        </div>
                    </Row>
                </Container>
            </div>                
            {loader}

            {/* Add New Modal */}
            <Modal
                isOpen={modalMaterialGroup}
                toggle={() => {
                    tog_MaterialGroup();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="groupModalLabel">
                        {` ${modalTitle} `}
                    </h5>

                    <button
                        onClick={() => {
                            setModalMaterialGroup(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div key="material-general-info-row-1-col-2" className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="ddl-division">Division</label>
                                <Form.Select id="ddl-division" key="ddl-division" placeholder="Select a division"onChange={(e) => setDivision(e.target.value)} value={division}>
                                <option key="ddl-division-option-empty">Select the division</option>
                                {!!divisions && divisions.length > 0 ? divisions.map((item, key) => {
                                    return(
                                        <option key={`ddl-division-option-option-${key}`} value={item.id}>
                                            {item.name}
                                        </option>
                                    )   
                                    }):(<option></option>)
                                }                                    
                                </Form.Select>
                            </div>
                        </div>                        
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Name">Name</label>
                                <input type="text" className="form-control" placeholder="Enter Name" id="AddNew-Description" onChange={(e) => setName(e.target.value)} value={name} />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalMaterialGroup(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_MaterialGroup();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{modalMessage}</p>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default MaterialGroupsManagement;