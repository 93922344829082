import React, { useState, createContext } from "react";

export const initialContextState = {
  viewAccess:{},
  token: {},
  userInfo: {},
  currentRoute: ""
}
// const initialState = {
//   token : null,
//   viewAccess : {},
// }

export const UserContext = createContext(initialContextState);

export const UserContextProvider = ({children})=>{
    const [appData, setAppData] = useState(initialContextState);
    // const [viewAccess, setViewAccess] = useState(initialViewsState);

    return (

          <UserContext.Provider
            value={{
              appData,setAppData
              // token:token,
              // setToken: setToken,
              // viewAccess: viewAccess,
              // setViewAccess: setViewAccess,
            }}
          >
            {children}
          </UserContext.Provider>

      );
}