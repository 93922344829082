import axios from "axios";
//import TokenStorage from "../util/token-storage";
import { appHostName } from "../util/commons-utilities";

const instance = axios.create({
  baseURL: appHostName(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
});
// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});
export default instance;
