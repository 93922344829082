import React, { useContext, useEffect, useState } from 'react';
import useFullPageLoader from "../../Hooks/useFullPageLoader"
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import usePageTitle from "../../util/head-element";
import DivisionService from '../../services/division-service';
import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { objectToCsv, searchInTable } from "../../util/commons-utilities";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import LoginService from '../../services/login-service';
import { UserContext } from '../../context/userContext';


const MaterialDivisionsManagement = () => {
    const dispatch = useDispatch();
    usePageTitle("Divisions | Warehouse");
    /*
    set breadcrumbs
    */
    const { token} = useContext(UserContext);
    useEffect(() => {
        const breadCrumbItems = {
            title: "Divisions",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);


    const [data, setData] = useState(null)

    const [modal_Division, setmodal_Division] = useState(false);
    const [modal_success, setmodal_success] = useState(false);
    const [divisionID, setDivisionID] = useState(0);
    const [name, setName] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const[editing, setEditing] = useState(false);
    const captions = ["Id","Name"];

    function setCurrentDivision(dvs){
        setDivisionID(dvs.id);
        setName(dvs.name);
        setModalTitle('Update ' + dvs.name);
    }

    function newDivision(){
        setCurrentDivision({
            id:0,
            name:""
        });
        setModalTitle('Add new');
        tog_Division();
        setEditing(false);
    }

    function editDivision(dvs){
        setCurrentDivision(dvs);
        tog_Division();
        setEditing(true);
    }

    function save_Division() {
        let dvs = {
            id: divisionID,
            name: name
        };
        showLoader();
        if(!editing){
            DivisionService.saveNewDivision(dvs)
            .then((response) => {                
                refreshData(response.data);
                setmodal_Division(false);
                setModalMessage('Division Added Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);},1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }
        else{
            DivisionService.updateDivision(dvs)
            .then((response) => {               
                refreshData(response.data);
                setmodal_Division(false);
                setModalMessage('Division Updated Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);},1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }       
    }

    function tog_Division() {
        setmodal_Division(!modal_Division);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    function refreshData(dvs){
        let currentData = data;
        if(!editing){
            currentData.push(dvs);
        }
        else{
            let index = currentData.findIndex(m => {return m.id === divisionID;});
            currentData[index] = dvs;
        }
        setData(currentData);
    }

    useEffect(() => {
        showLoader();
        console.log("Division",token)
        
        DivisionService.getDivisions()
            .then((response) => {              
                setData(response.data)
            })
            .catch(err => console.dir(err))
            .finally(() => {hideLoader()})   
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"material-types-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("divisions", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newDivision()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="material-types-table-container" className="table-responsive">
                                        <table id="material-types-table" className="table table-hover mb-0">
                                            <thead>
                                                <tr className="header">
                                                    <th className="dark">Id</th>
                                                    <th className="dark">Name</th>
                                                    <th className="dark">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {!!data && data.length > 0 ? data.map((item, key) => {
                                                return(
                                                    <tr key={`material-types-table-tbody-tr-${key}`}>
                                                        <td>{item.id}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            <Button variant="outline-secondary" size="sm" onClick={()=>editDivision(item)}><i className="bx bx-edit me-1"></i> Edit</Button>                                                        
                                                        </td>
                                                    </tr>
                                                )   
                                                }):(<tr><td colSpan={3}>No data found.</td></tr>)
                                            }
                                            </tbody>
                                        </table>    
                        </div>
                    </Row>
                </Container>
            </div>
            {loader}

            {/* Add New Modal */}
            <Modal
                isOpen={modal_Division}
                toggle={() => {
                    tog_Division();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="divisionModalLabel">
                        {` ${modalTitle} `}
                    </h5>

                    <button
                        onClick={() => {
                            setmodal_Division(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Description">Name</label>
                                <input type="text" className="form-control" placeholder="Enter Name" id="AddNew-Description" onChange={(e) => setName(e.target.value)} value={name} />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_Division(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_Division();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{modalMessage}</p>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default MaterialDivisionsManagement;