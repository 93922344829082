import React, { useEffect, useState, useRef } from 'react';

import { useLocation } from "react-router-dom";
import {  Container, Row, Col  } from 'reactstrap';

import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";

import {searchInTable, usCurrency, formatDateTime } from '../../util/commons-utilities';
import CountryService from '../../services/country-service';
import DivisionService from '../../services/division-service';
import ReportService from '../../services/report-service';
import { useDownloadExcel } from 'react-export-table-to-excel';

const EntryMovesLog  = () =>{
    const dispatch = useDispatch();   
    /*
    set breadcrumbs
    */
    useEffect(() => {
        dispatch(setBreadcrumb({
            title: "Entry Moves Log",
        }));
    }, [dispatch]);
    
    usePageTitle("Entry Moves Log | Warehouse");
    const [query] = useState( new URLSearchParams(useLocation().search));
    const [materialId] = useState(query.get("material"));    
    const [divisionId] = useState(query.get("division"));
    const [countryId] = useState(query.get("country"));

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [movements, setMovements] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const [division, setDivision] = useState(0);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(0);
    
    const tableRef = useRef(null);
    
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'entry-moves',
        sheet: 'Moves'
    });
    
    function getData(filter){
        showLoader();
        
        ReportService.getEntryMovesLog(filter)
        .then((response) => {
            setMovements(response);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            hideLoader();
        }); 
    }
    
    useEffect(() => {
        DivisionService.getDivisions()
        .then((response) => {              
            setDivisions(response.data);
        })
        .catch(err => console.dir(err))
        .finally(() => {
        });
    
        CountryService.getCountries()
        .then((response) => {
            setCountries(response.data);
        })
        .catch(err => console.dir(err))
        
        let filter = {
            material: materialId,
            division: divisionId,
            country: countryId
        }
        getData(filter);
    }, []);

    return(
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"entry-moves-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button key="report-export-button" variant="outline-success" id="report-export-button" onClick={(e)=> onDownload()}>
                                                        <i className='fas fa-file-excel'></i>&nbsp;Export
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="entry-moves-table-container" className="table-responsive">
                            <table id="entry-moves-table" className="table table-hover mb-0" ref={tableRef}>
                                <thead>
                                    <tr className="header">
                                        <th className="dark">Warehouse</th>
                                        <th className="dark">Material</th>
                                        <th className="dark">Move Date</th>
                                        <th className="dark">Quantity</th>
                                        <th className="dark">Unit Price</th>
                                        <th className="dark">Move Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!!movements && movements.length > 0 ? movements.map((item, key) => {
                                    return(
                                        <tr key={`entry-moves-table-tbody-tr-${key}`}>
                                            <td>{item.warehouse}</td>
                                            <td>{item.material}</td>
                                            <td>{formatDateTime(item.move_date)}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.unit_price}</td>
                                            <td>{usCurrency.format(item.move_amount)}</td>
                                        </tr>
                                    )   
                                    }):(<tr><td colSpan={3}>No data found.</td></tr>)
                                }
                                </tbody>
                            </table>    
                        </div>
                    </Row>
                </Container>
            </div>
            {loader}
        </React.Fragment>
    
    )  
}

export default EntryMovesLog;