import { initialContextState } from "../context/userContext";

const UpdateContext = {
    updateToken(setAppData, appData, token) {
        if(token!==undefined && token!=="" && token!==null ){
            appData.token = token;
            localStorage.setItem('token',token);
        }
        else{
            localStorage.removeItem('token');
            appData.token = null;
        }
        setAppData({ ...appData });
                
    },
    updateViewAccess(setAppData, appData, data) {
        appData.viewAccess = data;
        setAppData({ ...appData });
    },
    updateUserInfo(setAppData, appData, data) {
        appData.userInfo = data;
        setAppData({ ...appData });
    },
    updateCurrentRoute(setAppData, appData, data) {
        appData.currentRoute = data;
        setAppData({ ...appData });
    },
    resetContext(setAppData, appData) {
        appData = initialContextState;
        localStorage.removeItem('token');
        setAppData({ ...appData });
    }
};

export default UpdateContext;