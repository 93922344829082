import React, { useEffect, useState } from 'react';
import useFullPageLoader from "../../Hooks/useFullPageLoader"
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import usePageTitle from "../../util/head-element";
import MaterialSubtypesService from '../../services/material-types-service';
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { objectToCsv, searchInTable } from "../../util/commons-utilities";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import MaterialTypesService from '../../services/material-types-service';


const MaterialSubtypesManagement = () => {
    const dispatch = useDispatch();
    usePageTitle("Subtypes | Warehouse");
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Subtypes",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);


    const [data, setData] = useState(null)

    const [modal_MaterialSubtype, setmodal_MaterialSubtype] = useState(false);
    const [modal_success, setmodal_success] = useState(false);
    const [types, setTypes] = useState(0);
    const [subtypeID, setSubtypeID] = useState(0);
    const [type, setType] = useState(0);
    const [materialSubtypeDescription, setMaterialSubtypeDescription] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const[editing, setEditing] = useState(false);
    const captions = ["Id","Name"];

    function setCurrentMaterialSubtype(assettype){
        setSubtypeID(assettype.id);
        setType(assettype.type?.id);
        setMaterialSubtypeDescription(assettype.value);
    }

    function newMaterialSubtype(){
        setCurrentMaterialSubtype({
            id:0,
            type:0,
            value:""
        });
        setModalTitle('Add New');
        tog_MaterialSubtype();
        setEditing(false);
    }

    function editMaterialSubtype(assettype){
        setCurrentMaterialSubtype(assettype);
        setModalTitle('Update ' + assettype.value);
        tog_MaterialSubtype();
        setEditing(true);
    }

    function save_MaterialSubtype() {
        let materialSubtype = {
            id: subtypeID,
            type: type,
            value: materialSubtypeDescription
        };
        showLoader();
        if(!editing){
            MaterialSubtypesService.saveNewMaterialSubtype(materialSubtype)
            .then((response) => {
                console.log(response.data);                
                refreshData(response.data);
                setmodal_MaterialSubtype(false);
                setModalMessage('Subtype Added Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);}, 1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }
        else{
            MaterialSubtypesService.updateMaterialSubtype(materialSubtype)
            .then((response) => {
                console.log(response.data);                
                refreshData(response.data);
                setmodal_MaterialSubtype(false);
                setModalMessage('Subtype Updated Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);}, 1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }       
    }

    function tog_MaterialSubtype() {
        setmodal_MaterialSubtype(!modal_MaterialSubtype);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    function refreshData(materialSubtype){
        let currentData = data;
        let objType = types.find(t => {
            return t.id === materialSubtype.type;
        });
        if(!editing){
            currentData.push(materialSubtype);
        }
        else{
            let index = currentData.findIndex(m => {return m.id === subtypeID;});
            materialSubtype.type = objType;
            currentData[index] = materialSubtype;
        }
        setData(currentData);
    }

    useEffect(() => {
        showLoader();
        MaterialSubtypesService.getMaterialSubtypes()
            .then((response) => {                
                setData(response.data)
            })
            .catch(err => console.dir(err))
            .finally(() => {hideLoader()})

        MaterialTypesService.getMaterialTypes()
        .then((response) => {                
            setTypes(response.data)
        })
        .catch(err => console.dir(err))

    }, [])

    return (
        <React.Fragment>
             <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"material-types-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("categories", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newMaterialSubtype()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="material-types-table-container" className="table-responsive">
                                        <table id="material-sub-types-table" className="table table-hover mb-0">
                                            <thead>
                                                <tr className="header">
                                                    <th className="dark">Id</th>
                                                    <th className="dark">Material Type</th>
                                                    <th className="dark">Description</th>
                                                    <th className="dark">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {!!data && data.length > 0 ? data.map((item,key) => {
                                                return(
                                                    <tr key={`material-sub-types-table-tbody-tr-${key}`}>
                                                        <td>{item.id}</td>
                                                        <td>{item.type?.value}</td>
                                                        <td>{item.value}</td>
                                                        <td>
                                                            <Button variant="outline-secondary" size="sm" onClick={()=>editMaterialSubtype(item)}><i className="bx bx-edit me-1"></i> Edit</Button>                                                        
                                                        </td>
                                                    </tr>
                                                )   
                                                }):(<tr><td colSpan={3}>No data found.</td></tr>)
                                            }
                                            </tbody>
                                        </table>    
                        </div>
                    </Row>
                </Container>
            </div>               

            {loader}

            {/* Add New Modal */}
            <Modal
                isOpen={modal_MaterialSubtype}
                toggle={() => {
                    tog_MaterialSubtype();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="assetTypeModalLabel">
                        {` ${modalTitle} `}
                    </h5>

                    <button
                        onClick={() => {
                            setmodal_MaterialSubtype(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="MaterialType">Type</label>
                                <Form.Select placeholder="Select a type" id="MaterialType" onChange={(e) => setType(e.target.value)} value={type}>
                                <option key="ddl-type-option-empty">Select the type</option>
                                {!!types && types.length > 0 ? types.map((item, key) => {
                                    return(
                                        <option key={`ddl-type-option-${key}`} value={item.id}>
                                            {item.value}
                                        </option>
                                    )   
                                    }):(<option></option>)
                                }                                    
                                </Form.Select>
                            </div>
                        </div>                        
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Description">Description</label>
                                <input type="text" className="form-control" placeholder="Enter Description" id="AddNew-Description" onChange={(e) => setMaterialSubtypeDescription(e.target.value)} value={materialSubtypeDescription} />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_MaterialSubtype(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_MaterialSubtype();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{modalMessage}</p>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default MaterialSubtypesManagement;