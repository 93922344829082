import moment from "moment/moment";
import config from "../config";
const appHostName = function () {
    return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? config.DEV_API_URL : config.PROD_API_URL;
}

const hasValue = function (obj) {
    return obj !== null && typeof obj !== 'undefined'
}
const isNullOrEmpty = function (obj) {
    return obj === null || typeof obj === 'undefined' || obj === ''
}
const isValidId = function (obj) {
    return !isNullOrEmpty(obj) && parseInt(obj)>0;
}
const isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const usCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const formatDate = function (date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getUTCDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const formatDateTime = function (date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let outDate = [year, month, day].join('-')
    let outTime = [hours, minutes, seconds].join(':')
    return outDate + ' ' + outTime;
}


const siteLocation = function (data) {
    if (!isNullOrEmpty(data)) {
        let outString = '';
        if (!isNullOrEmpty(data.address)) {
            outString = data.address + ', ';
        }
        if (!isNullOrEmpty(data.region)) {
            outString = outString + data.region.name + ', ';
        }
        if (!isNullOrEmpty(data.country)) {
            outString = outString + data.country.name;
        }
        return outString;
    }
    else {
        return "";
    }
}

const exportToCsv = function (data) {
    let csvContent = "data:text/csv;charset=utf-8,"
        + data.map(e => e.join(",")).join("\n");

    return csvContent;
}

const objectToCsv = function (filename, data, captions) {
    const titleKeys = Object.keys(data[0]);
    const refinedData = [];
    if (captions !== null && captions.length === titleKeys.length)
        refinedData.push(captions);
    else
        refinedData.push(titleKeys);

    data.forEach(item => {
        refinedData.push(Object.values(item))
    });

    let csvContent = '';

    refinedData.forEach(row => {
        csvContent += row.join(',') + '\n'
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    filename = (!isNullOrEmpty(filename) ? filename + ".csv" : document.title).replace(/[^a-zA-Z0-9]/g, '');
    link.setAttribute('href', objUrl);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const searchInTable = (props) => {
    const trs = document.querySelectorAll(`#${props.tableId} tr:not(.header)`)
    const filter = document.querySelector(`#${props.inputSearchId}`).value
    const regex = new RegExp(filter, 'i')
    const isFoundInTds = td => regex.test(td.innerHTML)
    const isFound = childrenArr => childrenArr.some(isFoundInTds)
    const setTrStyleDisplay = ({ style, children }) => {
        style.display = isFound([
            ...children // <-- All columns
        ]) ? '' : 'none'
    }

    trs.forEach(setTrStyleDisplay)
}

const getMaterials = (material) => {
    const response = material.map(m => { return { ...m.material, quantity: (m.qty - (m.in_transit_qty || 0)), inventory: m.qty } });
    return response;
}

const getQuantity = (quantity) => {
    return quantity !== "" ? parseFloat(quantity) : 0;
}

const getInventoryByMaterial = (material,allMaterials) => {
    const response = allMaterials?.filter(m => m.id===material.material.id );
    return response[0].quantity;
}

const validateExistInArrayById = (item, list) => {
    // console.log("validateExistInArray", item, list)
    return list.some(i => i.material.id === item.id);
}

const validateExistInArrayByField = (item, list, field) => {
    // console.log("validateExistInArrayByField", item, list, field)
    return list.some(i => i[field] === item);
}

const getFirstPath = (viewAccess) => {
    //console.log("getFirstPath method", viewAccess)
    if (viewAccess?.length > 0) {
        for (let i = 0; i < viewAccess.length; i++) {
            if (viewAccess[i].children?.length > 0 && viewAccess[i].children[0].path !== "") {
                //console.log("redirec path reponse",viewAccess[i].children[0].path)
                return viewAccess[i].children[0].path.toString();
            }
        }
    }
    else {
        return "/login";
    }
}

const currentWeek = function () {
    let curr = new Date();
    // get current date  
    let first = curr.getDate() - curr.getDay();
    // First day is the  day of the month - the day of the week  
    // let last = first + 6;
    // last day is the first day + 6   
    let firstday = new Date(curr.setDate(first));
    let lastday = new Date(curr.setDate(curr.getDate() + 6))
    return ({ firstday: firstday, lastday: lastday });
}

const toUTCDate = function (input) {
    let date;
    if (typeof input === 'string' || input instanceof String)
        date = new Date(input);
    else
        date = input;

    let dat_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());

    return dat_utc;
}

const resetAllInputRadios = () => {
    let radios = document.querySelectorAll('input[type=radio]');
    radios.forEach(radio => radio.checked = false);
}

const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}
const getValueMinDataToday = () => {
    return new Date().toISOString().split('T')[0]
}
const getTodayDate = () => {
    var NewDate = moment(new Date(), 'MM/DD/YYYY').format();
    NewDate = NewDate.split('T')[0];
    return NewDate;
}

const isGreaterOrEqualThanToday = function (input) {
    let date;
    if (typeof input === 'string' || input instanceof String)
        date = new Date(input);
    else
        date = input;

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    return (date >= today)
} 

const toggleVerticalMenu = function(){
    document.getElementById('btn-toggle-vertical-menu').click();
}

const tryParseJson = function(str){
    try {
        return { value: JSON.parse(str), valid: true };
    } catch (e) {
        return { value: str, valid: false };
    }   
}

export {
    appHostName,
    hasValue,
    isNullOrEmpty,
    usCurrency,
    formatDate,
    formatDateTime,
    siteLocation,
    exportToCsv,
    objectToCsv,
    searchInTable,
    isNumeric,
    getMaterials,
    getQuantity,
    validateExistInArrayById,
    currentWeek,
    toUTCDate,
    resetAllInputRadios,
    validateExistInArrayByField,
    getFirstPath,
    s2ab,
    getValueMinDataToday,
    isGreaterOrEqualThanToday,
    getTodayDate,
    getInventoryByMaterial,
    isValidId,
    toggleVerticalMenu,
    tryParseJson
}