import httpCommon from "./http-common";

const DivisionService = {
    getDivisions() {
        let data = httpCommon.get("/mdm/division")
        return data;
    },
    saveNewDivision(data) {
        let response = httpCommon.post("/mdm/division",JSON.stringify(data))
        return response;
    },
    updateDivision(data) {
        let response = httpCommon.put(`/mdm/division/${data.id}`,JSON.stringify(data))
        return response;
    }
};

export default DivisionService;