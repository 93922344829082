import { formatDateTime, usCurrency, hasValue } from '../../util/commons-utilities';

const InventoryMoveItem = ({keyrow, material, moves}) =>{
    return(
            !!material?(
                <>
                    <tr key={`moves-table-tbody-tr-${keyrow}`}>
                        <td colSpan={10}><b>{material.name}</b></td>   
                    </tr>
                    {!!moves && moves.length > 0 ? moves.map((item, movekey) => (
                        <tr key={`moves-table-tbody-tr-${keyrow}-${movekey}`}>
                            <td>&nbsp;</td>
                            <td>{hasValue(item.warehouse)?item.warehouse.name:item.pointofsale.mso_name}</td>
                            <td>{formatDateTime(item.move_date)}</td>
                            <td>{item.move_type}</td>
                            <td>{item.entry_number?.id??item.dispatch_number?.id}</td>
                            <td>{item.previous_balance}</td>
                            <td>{item.quantity} <i className={item.move_type==='entry'?'fas fa-arrow-up text-success': 'fas fa-arrow-down text-danger'}></i> </td>
                            <td>{item.new_balance}</td>
                            <td>{usCurrency.format(item.unit_price)}</td>
                            <td>{usCurrency.format(item.quantity * item.unit_price)}</td>
                        </tr>
                        )):(<tr><td colSpan={10}>No data found.</td></tr>)
                    }                   
                </>
        ):(<></>)
    
    )  
}

export default InventoryMoveItem;