import httpCommon from "./http-common";

const MaterialGroupService = {
    getMaterialGroups() {
        let data = httpCommon.get("/mdm/materialgroup")
        return data;
    },
    saveNewMaterialGroup(data) {
        let response = httpCommon.post("/mdm/materialgroup",JSON.stringify(data))
        return response;
    },
    updateMaterialGroup(data) {
        let response = httpCommon.put(`/mdm/materialgroup/${data.id}`,JSON.stringify(data))
        return response;
    },
    getMaterialSubgroups() {
        let data = httpCommon.get("/mdm/materialsubgroup")
        return data;
    },
    saveNewMaterialSubgroup(data) {
        let response = httpCommon.post("/mdm/materialsubgroup",JSON.stringify(data))
        return response;
    },
    updateMaterialSubgroup(data) {
        let response = httpCommon.put(`/mdm/materialsubgroup/${data.id}`,JSON.stringify(data))
        return response;
    }
};

export default MaterialGroupService;