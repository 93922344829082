import { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import EntryService from "../../services/material-entry-service";
import {isNullOrEmpty, hasValue, usCurrency, isNumeric, getQuantity} from "../../util/commons-utilities"
const MaterialEntryItem = ({keyRow, entryItem, status, setTotalQuantity,  setTotalAmount, removeItem, materials, setMaterials, toggleSavePermission, setToggleSavePermission }) =>{
    const [data, setData] = useState(null);
    const [entryItemId, setEntryItemId] = useState(0);
    const [entryId, setEntryId] = useState(0);
    const [materialId, setMaterialId] = useState(0);
    const [divisionName, setDivisionName] = useState('');
    const [typeName, setTypeName] = useState('');
    const [subTypeName, setSubtypeName] = useState('');    
    const [groupName, setGroupName] = useState('');
    const [materialName, setMaterialName] = useState('');
    const [materialQty, setMaterialQty] = useState(0);
      
    const [materialUnitPrice, setMaterialUnitPrice] = useState(0);
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorOnSave, setErrorOnSave] = useState(false);
    const [rowClassName, setRowClassName] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const [show, setShow] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [deleting, setDeleting] = useState(false);

    const [quantityValidationClassName, setQuantityValidationClassName] = useState('');
    const [quantityValidationMessage, setQuantityValidationMessage] = useState('');

    function showMessage(data){
        setAlertType(data.type);
        setAlertTitle(data.title);
        setAlertMessage(data.message);
        setShow(true);
        setTimeout(()=>{setShow(false)},10000);
    }

    function validateData(){
        let isValid = true;
        if(isNullOrEmpty(materialQty)){
            setQuantityValidationClassName("text-danger");
            setQuantityValidationMessage("The Quantity field is required.");
            isValid = false;
        }
        else{
            if(parseInt(materialQty)===0){
                setQuantityValidationClassName("text-danger");
                setQuantityValidationMessage("The quantity must be greater than zero.");
            }
            else{
                setQuantityValidationClassName("d-none");
                setQuantityValidationMessage("");
            }
        }
        return isValid;
    }

    function handleQuantityChange(e){
        const regex = /^[0-9\b]+$/;
        e.preventDefault();
        if (e.target.value === "" || regex.test(e.target.value)) {
            setSomethingChanged(true); 
            setMaterialQty(e.target.value);
            let row = e.target.parentElement.parentElement;
            let button = row.cells[8].getElementsByTagName('button')[0];
            button.style.display = "block";
            if (isNumeric(e.target.value)) {
                let quantity = getQuantity(e.target.value);
                setMaterialQty(quantity);
                updateQuantityInMaterials(quantity);
            }
        }     
        setToggleSavePermission(!toggleSavePermission);
        validateData();
    }

    const updateQuantityInMaterials = (value) => {
        const currentMaterials = materials;
        const foundIndex = currentMaterials.findIndex(x => x.material.id === materialId);
        currentMaterials[foundIndex].quantity = value;
        setMaterials(currentMaterials);

        let sumAmount = 0.0;
        let sumQuantity = 0;
        if(hasValue(currentMaterials)){
            currentMaterials.forEach(function(item) {
              sumAmount += item.quantity *  item.unit_price;
              sumQuantity += item.quantity;
            });
        }
        setTotalAmount(sumAmount);
        setTotalQuantity(sumQuantity);
        
    }

    useEffect(()=>{
        setEntryItemId(entryItem.id);
        setEntryId(hasValue(entryItem.entry.id)?entryItem.entry.id:entryItem.entry);
        setMaterialId(entryItem.material.id);
        setDivisionName(entryItem.material.division.name);
        setTypeName(entryItem.material.type.value);
        setSubtypeName(entryItem.material.subtype.value);
        setGroupName(entryItem.material.group.name);
        setMaterialName(entryItem.material.name);
        setMaterialQty(entryItem.quantity);
        setMaterialUnitPrice(entryItem.unit_price);
        setIsReadOnly(status!=='CREATED');
        setIsVisible(status!=='CREATED');
        setData(entryItem);
    }, [entryItem,status]);

    useEffect(()=>{
        let cssClassName = somethingChanged?"border border-primary":(errorOnSave?"border border-danger":"");
        setRowClassName(cssClassName);
        let visible = status!=='CREATED';
        setIsVisible(visible);
    },[somethingChanged,errorOnSave]);

    useEffect(() => {
        validateData();
    }, [materialQty]);

    function saveMaterialEntryItem(e){
        if(validateData()){
            if(somethingChanged){
                let detailItem = {
                    id: entryItemId,
                    quantity: materialQty,
                    received_qty: 0,
                    unit_price: materialUnitPrice,
                    notes: '',
                    entry: entryId,
                    material: materialId
                };
                setSaving(true);
                EntryService.updateEntryItem(detailItem)
                .then((response) => { 
                    setSomethingChanged(false);                    
                })
                .catch(err => {
                    setSomethingChanged(false);
                    setErrorOnSave(true);
                    setTimeout(()=>{
                        setErrorOnSave(false);
                    }, 3000);
                    showMessage({type:'danger', title: 'Error', message: err.request.responseText});
                })
                .finally(() => {
                    setToggleSavePermission(!toggleSavePermission);
                    setSaving(false);
                    if(e.target.tagName==="I"){
                        e.target.parentElement.style.display = "none";
                    }
                    else{
                        e.target.style.display = "none";
                    }
                }); 
            }
        }
    }

    const removeEntryItem = async () => {
        setDeleting(true);
        await removeItem(entryItem);
        setDeleting(false);
    };

    return(
        
            !!data?(
                <>
                    <tr className={rowClassName} 
                        key={`materials-table-tbody-tr-${keyRow}`}>
                        <td className="align-middle">
                            {divisionName}
                        </td>
                        <td className="align-middle">
                            {typeName}
                        </td>
                        <td className="align-middle">
                            {subTypeName}
                        </td>
                        <td className="align-middle">
                            {groupName}
                        </td>                                                                                                                               
                        <td className="align-middle">
                            {materialName}
                        </td>         
                        <td className="align-middle">
                            {materialUnitPrice}
                        </td>                              
                        <td className="align-middle">
                            {
                                !isReadOnly?(
                                    <>
                                        <input id={`material-item-${keyRow}-qty`}
                                        name={`material-item-${keyRow}-qty`}
                                        key={`material-item-${keyRow}-qty`} type="text" className="form-control" value={materialQty} onChange={handleQuantityChange} readOnly={isReadOnly}></input>                                    
                                        <span className={quantityValidationClassName} >{quantityValidationMessage}</span>
                                    </>                                    
                                ):(<>{materialQty}</>)
                            }                                                                   
                        </td>                  
                        <td className="align-middle">
                            {usCurrency.format(materialQty*materialUnitPrice)}                                             
                        </td> 
                        <td className="align-middle">
                            {
                                !isReadOnly?(
                                    <div className="btn-group">
                                        <button type="button" 
                                        key={`material-${keyRow}-button-save`}
                                        id={`material-${keyRow}-button-save`}
                                        name={`material-${keyRow}-button-save`}
                                        className="btn btn-sm btn-light text-body waves-effect btn-item-save"
                                        style={{display: isVisible ? 'block' : 'none' }}
                                        onClick={(e)=> saveMaterialEntryItem(e)}
                                        disabled={saving}><i className={!saving?"fas fa-save":"fas fa-spinner"}></i> {!saving?"Save":"Saving..."}</button>
                                        
                                    <button type="button"
                                        key={`material-${keyRow}-button-delete`}
                                        id={`material-${keyRow}-button-delete`}
                                        name={`material-${keyRow}-button-delete`}
                                        className="btn btn-sm btn-light text-body waves-effect"
                                        onClick={(e) => { removeEntryItem(); }}
                                        disabled={deleting}><i className={!deleting ? "fas fa-trash" : "fas fa-spinner"}></i> {!deleting ? "Remove" : "Removing..."}</button>
                                        
                                    </div>
                                ):(<>{" "}</>)
                            }
                        </td>
                    </tr>
                    {
                        show?(
                            <tr>
                                <td colSpan={9}>
                                    <Alert key="item-error-alert" variant={alertType} onClose={() => setShow(false)} dismissible>
                                        <Alert.Heading>{alertTitle}</Alert.Heading>
                                        <p>{alertMessage}</p>
                                    </Alert>         
                                </td>
                            </tr>    
                        ):(<></>)
                    }                   
                </>
        ):(<></>)
    
    )  
}

export default MaterialEntryItem;