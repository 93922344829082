import { useEffect, useState } from "react";
import { Row, Modal } from 'reactstrap';
import Image from 'react-bootstrap/Image';
import EntryCheckService from "../../services/entry-check-service";
import { isNullOrEmpty, hasValue } from "../../util/commons-utilities"
const MaterialEntryCheckItemPhotos = ({ keyRow, entryCheckItem, status, isReadOnlyD = false }) => {
    const [photos, setPhotos] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [photoPath, setPhotoPath] = useState(null);
    const [comments, setComments] = useState('');
    const [modalPhotos, setModalPhotos] = useState(false);
    const [modalPhotosTitle, setModalPhotosTitle] = useState('');

    const [photoViewerModal, setPhotoViewerModal] = useState(false);
    const [photoViewerModalTitle, setPhotoViewerModalTitle] = useState('');
    const [viewerPhotoPath, setViewerPhotoPath] = useState('');
    const [viewerComments, setViewerComments] = useState('');

    const [isReadOnly, setIsReadOnly] = useState(false);

    function handlePhotoChange(e) {
        setPhotoPath(URL.createObjectURL(e.target.files[0]));
        setPhoto(e.target.files[0]);
    }
    function tog_photo() {
        setPhotoPath('');
        setPhoto();
        setComments('');
        setModalPhotos(!modalPhotos);
    }
    function showPhotoViewerModal(item) {
        setPhotoViewerModalTitle(`${entryCheckItem.material.name}`);
        setViewerPhotoPath(item.photo);
        setViewerComments(item.comments);
        tog_photoViewer();
    }
    function tog_photoViewer() {
        setPhotoViewerModal(!photoViewerModal);
    }

    function refreshData(photo_response) {
        let currentPhotos = photos;
        currentPhotos.push(photo_response);
        setPhotos(currentPhotos);
    }

    function add_photo() {
        let photoData = {
            entrycheckmaterial: entryCheckItem.id,
            photo: photo,
            comments: comments
        };
        EntryCheckService.addPhoto(photoData)
            .then((response) => {
                refreshData(response);
                tog_photo();
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });
    }

    function showModalPhotos() {
        tog_photo();
    }

    useEffect(() => {
        if (!isReadOnlyD)
            setIsReadOnly(status === 'APPLIED');
        else setIsReadOnly(isReadOnlyD);
    }, []);

    useEffect(()=>{
        if(entryCheckItem.entrycheck?.id)
            EntryCheckService.getPhotosByMaterialEntryCheck(entryCheckItem.id)
                .then((response) => {  
                    if(hasValue(response.data)){
                        setPhotos(response.data);
                    }
                })
                .catch(err => console.dir(err))
                .finally(() => { 
                })
    },[]);

    return (
        <>
            <table key={`photos-table-${keyRow}`} style={{ width: "100%" }}>
                <tbody key={`photos-table-${keyRow}-tbody`}>
                    <tr key={`photos-table-${keyRow}-tbody-tr-${keyRow}`}>
                        <td className="align-middle" style={{ width: "80%" }}>
                            {!!photos && photos.length > 0 ? photos.map((item, key) => (
                                <>
                                    <Image key={`image-${keyRow}-${key}`} style={{ width: 24, height: 24, cursor: 'pointer' }} src={item.photo} roundedCircle onClick={(e) => showPhotoViewerModal(item)} />&nbsp;
                                </>
                            )) : (<></>)
                            }
                        </td>
                        <td className="align-middle" style={{ width: "20%" }}>
                            {
                                !isReadOnly ? (
                                    <button type="button"
                                        key={`photo-${keyRow}-button-add`}
                                        id={`photo-${keyRow}-button-add`}
                                        name={`photo-${keyRow}-button-add`}
                                        className="btn btn-sm btn-outline-secondary"
                                        onClick={(e) => showModalPhotos()} >
                                        <i className="fas fa-plus"></i>
                                    </button>
                                ) : (<>{" "}</>)
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <Modal
                isOpen={modalPhotos}
                toggle={() => {
                    tog_photo();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="photoModalLabel">
                        {modalPhotosTitle}
                    </h5>

                    <button
                        onClick={() => {
                            setModalPhotos(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">

                    <Row key="modal-photo-row-2">
                        <div className="col-md-12 mb-3">
                            <input className="mb-3" type="file" onChange={handlePhotoChange} />
                            <br />
                            <img style={{ width: 470, height: 500 }} src={photoPath} alt="material" />
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="PhotoComments">Comments</label>
                                <textarea className="form-control" placeholder="Enter Comments" id="PhotoComments" onChange={(e) => setComments(e.target.value)} value={comments} maxLength={250}></textarea>
                            </div>
                        </div>
                    </Row>
                    <Row key="modal-photo-row-3" className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalPhotos(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Close
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    add_photo();
                                }}
                            >
                                <i className="fas fa-plus-circle"></i> Add
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            <Modal
                isOpen={photoViewerModal}
                toggle={() => {
                    tog_photoViewer();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="photoViewerModalLabel">
                        {photoViewerModalTitle}
                    </h5>

                    <button
                        onClick={() => {
                            setPhotoViewerModal(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">

                    <Row key="modal-photo-row-2">
                        <div className="col-md-12 mb-3">
                            <img style={{ width: 470, height: 500 }} src={viewerPhotoPath} alt="material" />
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="viewerPhotoComments">Comments</label>
                                <textarea className="form-control" id="viewerPhotoComments" value={viewerComments} maxLength={250} disabled></textarea>
                            </div>
                        </div>
                    </Row>
                    <Row key="modal-photo-row-3" className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setPhotoViewerModal(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Close
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>
        </>


    )
}

export default MaterialEntryCheckItemPhotos;