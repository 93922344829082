import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Hooks/useFullPageLoader"
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import usePageTitle from "../../util/head-element";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import MaterialService from "../../services/material-service";
import MaterialTypesService from "../../services/material-types-service";
import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { hasValue, usCurrency, objectToCsv, searchInTable, isNullOrEmpty, isNumeric } from "../../util/commons-utilities";
import MaterialGroupService from "../../services/material-group";
import DivisionService from "../../services/division-service";

const MaterialsManagement = () => {
    const dispatch = useDispatch();
    usePageTitle("Materials | Warehouse");
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Materials",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [data, setData] = useState([]);
    const [types, setTypes] = useState([]);
    const [subtypes, setSubtypes] = useState([]);
    const [groups, setGroups] = useState(0);
    const [subgroups, setSubgroups] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [division, setDivision] = useState('');
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [filteredSubgroups, setFilteredSubgroups] = useState([]);

    const [modal_material, setModalMaterial] = useState(false);
    const [modal_success, setModalSuccess] = useState(false);
    const [materialID, setMaterialID] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [type, setType] = useState(0);
    const [subtype, setSubtype] = useState(0);
    const [group, setGroup] = useState(0);
    const [subgroup, setSubgroup] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [photo, setPhoto] = useState();
    const [photoPath, setPhotoPath] = useState();
    const [comments, setComments] = useState('');
    const [status, setStatus] = useState('');

    const [editing, setEditing] = useState(false);
    const [message, setMessage] = useState('');
    const [modaltitle, setModaltitle] = useState('');
    
    const [divisionValidationClassName, setDivisionValidationClassName] = useState('d-none');
    const [divisionValidationMessage, setDivisionValidationMessage] = useState('');
    const [typeValidationClassName, setTypeValidationClassName] = useState('d-none');
    const [typeValidationMessage, setTypeValidationMessage] = useState('');
    const [subtypeValidationClassName, setSubtypeValidationClassName] = useState('d-none');
    const [subtypeValidationMessage, setSubtypeValidationMessage] = useState('');
    const [groupValidationClassName, setGroupValidationClassName] = useState('d-none');
    const [groupValidationMessage, setGroupValidationMessage] = useState('');
    const [nameValidationClassName, setNameValidationClassName] = useState('d-none');
    const [nameValidationMessage, setNameValidationMessage] = useState('');
    const [priceValidationClassName, setPriceValidationClassName] = useState('d-none');
    const [priceValidationMessage, setPriceValidationMessage] = useState('');
    const [quantityValidationClassName, setQuantityValidationClassName] = useState('d-none');
    const [quantityValidationMessage, setQuantityValidationMessage] = useState('');

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const captions = ["Type", "Subtype", "Name", "Price"];    

    function validateData(){
        let isValid = true;
        //division
        if(!hasValue(division) || parseInt(division)===0){
            setDivisionValidationClassName("text-danger");
            setDivisionValidationMessage("The Division field is required.");
            isValid = false;
        }
        else{
            setDivisionValidationClassName("d-none");
            setDivisionValidationMessage("");
        }
        //type
        if(!hasValue(type) || parseInt(type)===0){
            setTypeValidationClassName("text-danger");
            setTypeValidationMessage("The Type field is required.");
            isValid = false;
        }
        else{
            setTypeValidationClassName("d-none");
            setTypeValidationMessage("");
        }

        //subtype
        if(!hasValue(subtype) || parseInt(subtype)===0){
            setSubtypeValidationClassName("text-danger");
            setSubtypeValidationMessage("The Subtype field is required.");
            isValid = false;
        }
        else{
            setSubtypeValidationClassName("d-none");
            setSubtypeValidationMessage("");
        }

        //group
        if(!hasValue(group) || parseInt(group)===0){
            setGroupValidationClassName("text-danger");
            setGroupValidationMessage("The Group field is required.");
            isValid = false;
        }
        else{
            setGroupValidationClassName("d-none");
            setGroupValidationMessage("");
        }          

        //name
        if(isNullOrEmpty(name)){
            setNameValidationClassName("text-danger");
            setNameValidationMessage("The Name field is required.");
            isValid = false;
        }
        else{
            setNameValidationClassName("d-none");
            setNameValidationMessage("");
        }  

        
        //price
        if(isNullOrEmpty(price) || parseFloat(price)===0){
            setPriceValidationClassName("text-danger");
            setPriceValidationMessage("The Price field is required.");
            isValid = false;
        }
        else{
            setPriceValidationClassName("d-none");
            setPriceValidationMessage("");
        }     

        //quantity
        if(isNullOrEmpty(quantity) || parseFloat(quantity)===0){
            setQuantityValidationClassName("text-danger");
            setQuantityValidationMessage("The Quantity field is required.");
            isValid = false;
        }
        else{
            setQuantityValidationClassName("d-none");
            setQuantityValidationMessage("");
        }          

        return isValid;
    }
    function resetValidationMessages(){
        setDivisionValidationClassName("d-none");
        setDivisionValidationMessage("");
        setTypeValidationClassName("d-none");
        setTypeValidationMessage("");
        setSubtypeValidationClassName("d-none");
        setSubtypeValidationMessage("");
        setGroupValidationClassName("d-none");
        setGroupValidationMessage("");
        setNameValidationClassName("d-none");
        setNameValidationMessage("");
        setPriceValidationClassName("d-none");
        setPriceValidationMessage("");
        setQuantityValidationClassName("d-none");
        setQuantityValidationMessage("");
    }

    function handleDivisionChange(e){
        setDivision(e.target.value);

        let objdiv = divisions.find(d => {
            return parseInt(d.id) === parseInt(e.target.value);
        });  

        if(hasValue(objdiv) && objdiv.name.toUpperCase()==="ALL"){
            setFilteredGroups(groups);
        }
        else{
            let result = groups.filter(g=> {return parseInt(g.division.id) === parseInt(e.target.value)});
            setFilteredGroups(result);
        }

    }
    
    function errorHandler(error){
        if(typeof error.request!='undefined'){
            console.dir(error.request.responseText);
        }
        console.dir(error)
    }

    function handlePhotoChange(e) {
        setPhotoPath(URL.createObjectURL(e.target.files[0]));
        setPhoto(e.target.files[0]);
    }

    function handleGroupChange(value){
        setGroup(value);
        let result = subgroups.filter(s=> {return parseInt(s.group.id) === parseInt(value)});
        setFilteredSubgroups(result);
    }

    function handleQuantityChange(e){
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setQuantity(e.target.value);
        }        
    }
    function handlePriceChange(e){
        if(isNumeric(e.target.value)){
            setPrice(e.target.value);
       }
       else{
        setPrice(0);
       }       
    }

    function newMaterial(){
        setCurrentMaterial({
            id:0,
            name:"",
            description:"",
            type:0,
            subtype:0,
            group:0,
            subgroup:0,
            division:0,
            price:0,
            quantity:0,
            status:"CREATED"
        });
        setComments('');
        setPhoto();
        setModaltitle('Add New')
        tog_material();
        setEditing(false);
        setTimeout(()=> {
            resetValidationMessages();
        }, 100);
    }


    function editMaterial(material){
        setCurrentMaterial(material);
        setModaltitle(`Edit ${material.name}`);
        tog_material();
        setEditing(true);
    }

    function setCurrentMaterial(material){
        setMaterialID(material.id);
        setName(material.name);
        setDescription(material.description);
        setPrice(material.unit_price);
        setType(material.type.id);
        setSubtype(material.subtype.id);
        setGroup(hasValue(material.group)?material.group.id:0);
        setStatus(material.status);
        setQuantity(material.quantity);
        setDivision(material.division.id);
        let result = groups.filter(g=> {return parseInt(g.division.id) === parseInt(hasValue(material.division.id)?material.division.id:0)});
        setFilteredGroups(result);
        setGroup(hasValue(material.group)?material.group.id:0);
        if(hasValue(material.photo) && !isNullOrEmpty(material.photo)){
            setPhotoPath(material.photo);
            setPhoto();
        }
        else{
            setPhoto();
            setPhotoPath("#");
        }
    }

    function save_material() {
        let material = {
            id:materialID,
            name: name,
            description:description,
            type: type,
            subtype: subtype,
            group: group,
            division: division,
            subgroup: null,
            unit_price: price,
            quantity: quantity,
            status: status
        }
        if(validateData()){
            showLoader();        
            if(!editing){
                MaterialService.saveNewMaterial(material)
                .then((response) => {    
                    let tempData = response.data;
                    console.log("new material: ", response.data);
                    if(hasValue(photo)){            
                        let photoData = {
                            id: response.data.id,
                            photo: photo,
                            comments: comments
                        }
                        MaterialService.uploadPhoto(photoData)
                        .then((result) => {
                            console.log("Photo result: ", result);   
                            tempData.photo = result;            
                            setModalMaterial(false);
                            setMessage("Material added successfully");
                            setModalSuccess(true);
                            setTimeout(()=>{setModalSuccess(false)},1000);
                        })
                        .catch(err => errorHandler(err))
                    }
                    else{
                        setModalMaterial(false);
                        setMessage("Material added successfully");
                        setModalSuccess(true);
                        setTimeout(()=>{setModalSuccess(false)},1000);
                    }
                    refreshData(tempData);
                })
                .catch(err => errorHandler(err))
                .finally(() => { 
                    hideLoader();
                })
            }
            else {            
                MaterialService.updateMaterial(material)
                .then((response) => {             
                    let tempData = response.data;
                    if(hasValue(photo)){
                        let photoData = {
                            id: material.id,
                            photo: photo,
                            comments: comments
                        }
                        MaterialService.uploadPhoto(photoData)
                        .then((result) => {
                            console.log("Photo result: ", result);
                            tempData.photo = result;  
                            setModalMaterial(false);
                            setMessage("Material updated successfully");
                            setModalSuccess(true);
                            setTimeout(()=>{setModalSuccess(false)},1000);
                        })
                        .catch(err => errorHandler(err))                   
                    }
                    else{
                        setModalMaterial(false);
                        setMessage("Material updated successfully");
                        setModalSuccess(true);
                        setTimeout(()=>{setModalSuccess(false)},1000);
                    }
                    refreshData(tempData);
                })
                .catch(err => console.dir(err))
                .finally(() => { 
                    hideLoader(); 
                    setEditing(false); 
                }) 
            }
        }
    }


    function tog_material() {
        setModalMaterial(!modal_material);
    }
    function tog_success() {
        setModalSuccess(!modal_success);
    }

    function refreshData(material){
        let objdiv = divisions.find(d => {
            return d.id === material.division;
        });
        let objType = types.find(t => {
            return t.id === material.type;
        });
        let objSubtype = subtypes.find(s => {
            return s.id === material.subtype;
        });

        let objGroup = groups.find(t => {
            return t.id === material.group;
        });
        /*let objSubgroup = subgroups.find(s => {
            return s.id === material.subgroup;
        });  */

        material.division = objdiv;
        material.type = objType;
        material.subtype = objSubtype;
        material.group = objGroup;
        /*material.subgroup = objSubgroup;*/

        let currentData = data;
        if(!editing){
            currentData.push(material);
        }
        else{
           let prodIndex = currentData.findIndex(p => {return parseInt(p.id) === parseInt(materialID);});
           material.id = materialID;
           currentData[prodIndex] = material;
        }
        setData(currentData);
    }
    
    useEffect(() => {
        validateData();
    }, [type, subtype, group, name, price, quantity])

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        showLoader();
        MaterialService.getMaterials()
        .then((response) => {              
            setData(response.data);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader()
        }); 
        
        MaterialTypesService.getMaterialTypes()
        .then((response) => {                
            setTypes(response.data);
        })
        .catch(err => console.dir(err))

        MaterialTypesService.getMaterialSubtypes()
        .then((response) => {            
            setSubtypes(response.data);
        })
        .catch(err => console.dir(err))
        
        MaterialGroupService.getMaterialGroups()
        .then((response) => {                
            setGroups(response.data);
        })
        .catch(err => console.dir(err))

        /*MaterialGroupService.getMaterialSubgroups()
        .then((response) => {                
            setSubgroups(response.data);
        })
        .catch(err => console.dir(err))*/

        DivisionService.getDivisions()
        .then((response) => {              
            setDivisions(response.data);
        })
        .catch(err => console.dir(err))
        .finally(() => {
        }); 

    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                        <Col key="page-content-container-row-1-col-1" md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col key="page-content-container-row-1-col-2" md={8}>
                            <Row key="page-content-container-row-1-col-2-row-1">
                                <Col key="page-content-container-row-1-col-2-row-1-col-1" md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"materials-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col key="page-content-container-row-1-col-2-row-1-col-2" md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("materials", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newMaterial()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row key="page-content-container-row-2">
                        <div id="materials-table-container" className="table-responsive">
                            <table key="materials-table" id="materials-table" className="table table-hover mb-0">
                                <thead key="materials-table=thead">
                                    <tr key="materials-table=thead-tr" className="header">
                                        <th className="dark">Division</th>
                                        <th className="dark">Type</th>
                                        <th className="dark">Subtype</th>
                                        <th className="dark">Group</th>                                 
                                        <th className="dark">Name</th>
                                        <th className="dark">Price</th>
                                        <th className="dark">Quantity</th>
                                        <th className="dark">Amount</th>
                                        <th className="dark">Status</th>
                                        <th className="dark">Actions</th>
                                    </tr>
                                </thead>
                                <tbody key="materials-table=tbody">
                                {!!data && data.length > 0 ? data.map((item, key) => {
                                    return(
                                        <tr key={`materials-table-tbody-tr-${key}`}>
                                            <td>{item.division.name}</td>
                                            <td>{item.type.value}</td>
                                            <td>{item.subtype.value}</td>
                                            <td>{item.group.name}</td>                                       
                                            <td>{item.name}</td>
                                            <td>{usCurrency.format(item.unit_price)}</td>
                                            <td>{item.quantity}</td>
                                            <td>{usCurrency.format(item.quantity*item.unit_price)}</td>
                                            <td>{item.status}</td>
                                            <td>
                                                <Link key={`materials-table-link-${key}`} className="btn btn-outline-secondary btn-sm waves-effect waves-light"
                                                    to={{
                                                            pathname: '/material-detail',
                                                            search: `?prod=${item.id}`
                                                        }}
                                                    > <i className="bx bx-grid me-1"></i> Details
                                                </Link>
                                                &nbsp; 
                                                {
                                                    item.status==="CREATED"?(
                                                        <Button key={`materials-table-button-${key}`}  variant="outline-secondary" size="sm" onClick={()=>editMaterial(item)}><i className="bx bx-edit me-1"></i> Edit</Button>                       
                                                    ):(<></>)
                                                }                                               
                                            </td>
                                        </tr>
                                    )   
                                    }):(<tr><td colSpan={3}>No data found.</td></tr>)
                                }
                                </tbody>
                            </table>    
                        </div>  
                    </Row>
                </Container>
            </div>
            
            {loader}

            {/* Add New Modal */}
            <Modal
                key="modal-material-key"
                isOpen={modal_material}
                toggle={() => {
                    tog_material();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="materialModalLabel">
                        {modaltitle}
                    </h5>

                    <button
                        onClick={() => {
                            setModalMaterial(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">

                    <Tabs
                        defaultActiveKey="material-general-info"
                        transition={true}
                        id="material-form-tab"
                        className="mb-3"
                        key="material-data"
                        >
                        <Tab key="material-general-info" eventKey="material-general-info" title="General Information">
                            <div key="material-general-info-row-1" className="row">                                                     
                                <div key="material-general-info-row-1-col-1" className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="SubGroup">Division</label>
                                        <br/>
                                        {!!divisions && divisions.length > 0 ? divisions.map((item, key) => {
                                            return(
                                                <>
                                                    <input
                                                        type='radio'
                                                        key={`form-radio-${key}`}
                                                        id={`form-radio-${key}`}
                                                        name="material-division"
                                                        value={item.id}
                                                        defaultChecked={item.id===division}
                                                        onChange={handleDivisionChange}
                                                    />&nbsp;<label>{item.name}</label>&nbsp;                           
                                                </>
                                            )   
                                            }):(<></>)
                                        }    
                                        <br/>
                                        <small className={divisionValidationClassName} >{divisionValidationMessage}</small>
                                    </div>
                                </div>
                                <div key="material-general-info-row-1-col-2" className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="MaterialType">Type</label>
                                        <Form.Select id="MaterialType" key="ddl-material-type" placeholder="Select a type"onChange={(e) => setType(e.target.value)} value={type}>
                                        <option key="ddl-material-type-option-empty">Select the type</option>
                                        {!!types && types.length > 0 ? types.map((item, key) => {
                                            return(
                                                <option key={`ddl-material-type-option-${key}`} value={item.id}>
                                                    {item.value}
                                                </option>
                                            )   
                                            }):(<option></option>)
                                        }                                    
                                        </Form.Select>
                                        <small className={typeValidationClassName} >{typeValidationMessage}</small>
                                    </div>
                                </div>   
                                <div key="material-general-info-row-1-col-3"  className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="MaterialSubtype">Subtype</label>
                                        <Form.Select id="MaterialSubtype" key="ddl-material-subtype" placeholder="Select the subtype" onChange={(e) => setSubtype(e.target.value)} value={subtype}>
                                            <option key="ddl-material-subtype-option-empty">Select the subtype</option>
                                        {!!subtypes && subtypes.length > 0 ? subtypes.map((item, key) => {
                                            return(
                                                <option key={`ddl-material-subtype-option-${key}`} value={item.id}>
                                                    {item.value}
                                                </option>
                                            )   
                                            }):(<option></option>)
                                        }                                    
                                        </Form.Select>
                                        <small className={subtypeValidationClassName} >{subtypeValidationMessage}</small>
                                    </div>
                                </div>                    
                                <div key="material-general-info-row-1-col-4" className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="Group">Group</label>
                                        <Form.Select id="Group" key="ddl-group" placeholder="Select a group" onChange={(e) => handleGroupChange(e.target.value)} value={group}>
                                        <option key="ddl-group-option-empty">Select the group</option>
                                        {!!filteredGroups && filteredGroups.length > 0 ? filteredGroups.map((item, key) => {
                                            return(
                                                <option key={`ddl-group-option-${key}`} value={item.id}>
                                                    {item.name}
                                                </option>
                                            )   
                                            }):(<option></option>)
                                        }                                    
                                        </Form.Select>
                                        <small className={groupValidationClassName}>{groupValidationMessage}</small>
                                    </div>
                                </div>   
    
                                <div key="material-general-info-row-1-col-5" className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="MaterialName">Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Name" id="MaterialName" onChange={(e) => setName(e.target.value)} value={name} />
                                        <small className={nameValidationClassName}>{nameValidationMessage}</small>
                                    </div>
                                </div>
                                <div key="material-general-info-row-1-col-6" className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="MaterialDescription">Description</label>
                                        <textarea className="form-control" placeholder="Enter Description" id="MaterialDescription" onChange={(e) => setDescription(e.target.value)} value={description} maxLength={250}></textarea>
                                    </div>
                                </div>                 
                                <div key="material-general-info-row-1-col-7" className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="MaterialPrice">Price</label>
                                        <input type="text" className="form-control" placeholder="Enter Price" id="MaterialPrice" onChange={handlePriceChange} value={price} />
                                        <small className={priceValidationClassName}>{priceValidationMessage}</small>
                                    </div>
                                </div>
                                <div key="material-general-info-row-1-col-8" className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="MaterialQuantity">Quantity</label>
                                        <input type="text" className="form-control" placeholder="Enter Quantity" id="MaterialQuantity" onChange={handleQuantityChange} value={quantity}/>
                                        <small className={quantityValidationClassName}>{quantityValidationMessage}</small>
                                    </div>
                                </div>
                                <div key="material-general-info-row-1-col-9" className="col-md-4">
                                    <div className="mb-3">
                                        <p className="text-muted"><b>Amount </b></p>
                                        <p className="text-muted"> {usCurrency.format((hasValue(price)?price:0)*(hasValue(quantity)?quantity:0))}</p>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab key="material-photo" eventKey="material-photo" title="Photo">
                            <div key="material-photo-row-1" className="row">
                                <div className="col-md-12 mb-3">
                                    <input className="mb-3" type="file" onChange={handlePhotoChange} />
                                    <br/>
                                    <img style={{ width: 470, height: 500 }} src={photoPath} alt="material"/>                                        
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="PhotoComments">Comments</label>
                                        <textarea className="form-control" placeholder="Enter Comments" id="PhotoComments" onChange={(e) => setComments(e.target.value)} value={comments} maxLength={250}></textarea>
                                    </div>
                                </div>                                    
                            </div>
                        </Tab>                      
                    </Tabs>                      
                    <Row key="modal-material-row-1" className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalMaterial(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_material();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                key="modal-success-key"
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{message}</p>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default MaterialsManagement;