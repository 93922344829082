import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
import {
    Col,
    Container,
    Row,
    Modal
} from 'reactstrap';
import { Link } from 'react-router-dom';
import usePageTitle from "../../util/head-element";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import PosService from "../../services/pos-service";
import DispatchService from "../../services/dispatch-service";
import WarehouseService from "../../services/warehouse-service";
import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { formatDate, getTodayDate, getValueMinDataToday, hasValue, isNullOrEmpty, isValidId, objectToCsv, searchInTable } from "../../util/commons-utilities";
import UserService from "../../services/user-service";
import Select from 'react-select';

const DistributionManagement = () => {
    const dispatch = useDispatch();
    usePageTitle("Distributions | Warehouse ");
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Distributions",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [data, setData] = useState([]);
    const [posList, setPosList] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [users, setUsers] = useState([]);

    const [modal_dispatch, setModalDispatch] = useState(false);
    const [modal_success, setModalSuccess] = useState(false);
    const [dispatchID, setDispatchID] = useState(0);

    const [warehouse, setWarehouse] = useState(0);
    const [receiver, setReceiver] = useState(0);
    const [shipToType, setShipToType] = useState('');
    const [shipToPos, setShipToPos] = useState(0);
    const [shipToWarehouse, setShipToWarehouse] = useState(0);
    const [shipDate, setShipDate] = useState(getTodayDate());
    const [status, setStatus] = useState('CREATED');
    const [comments, setComments] = useState('');

    const [editing, setEditing] = useState(false);
    const [message, setMessage] = useState('');
    const [modaltitle, setModaltitle] = useState('');

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const captions = ["Id", "Name", "From Date", "To Date", "Description", "Order No.", "Budget", "Created Date"];
    const [warehouseValid, setWarehouseValid] = useState(true);
    const [posValid, setPosValid] = useState(true);
    const [posOptions, setPosOptions] = useState([]);
    const [posSelected, setPosSelected] = useState(null);
    const [receiverOptions, setReceiverOptions] = useState([]);
    const [receiverSelected, setReceiverSelected] = useState(null);
    const [receiverValid, setReceiverValid] = useState(true);
    let history = useHistory();

    function fillPosOptions(data) {
        return new Promise((resolve, reject) => {
            let options = data.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                return ({ value: item.id, label: item.mso_name })
            })
            setPosOptions(options);
            setPosList(data);
            resolve(options);
        });
    }
    function fillReceiverOptions(data) {
        return new Promise((resolve, reject) => {
            let options = data.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                return ({ value: isValidId(item.id) ? item.id : 0, label: item.given_name })
            })
            setReceiverOptions(options);
            setUsers(data);
            resolve(options);
        });
    }
    function setCurrentDispatch(dispatch) {
        setDispatchID(dispatch.id);
        setWarehouse(dispatch.warehouse.id);
        setShipToType(dispatch.ship_to_type);
        setShipToWarehouse(hasValue(dispatch.ship_to_warehouse) ? dispatch.ship_to_warehouse.id : 0);
        setShipToPos(hasValue(dispatch.ship_to_pos) ? dispatch.ship_to_pos.id : 0);
        setShipDate(dispatch.ship_date);
        setStatus(dispatch.status);
        setComments(dispatch.comments);
        setReceiver(parseInt(dispatch.receiver?.id||0));
    }

    function newDispatch() {
        setCurrentDispatch({
            id: 0,
            warehouse: 0,
            ship_to_type: '',
            ship_to_warehouse: 0,
            ship_to_pos: 0,
            ship_date: getTodayDate(),
            status: 'CREATED',
            comments: '',
            receiver: 0,
        });
        setReceiverSelected(0);
        setPosSelected(0);
        setReceiverValid(true);
        setPosValid(true);
        setWarehouseValid(true);
        setModaltitle('Add New');
        tog_dispatch();
        setEditing(false);
    }

    function editDispatch(dispatch) {
        setCurrentDispatch({ ...dispatch });

        const pos = posOptions?.find(co => {
            return co.value === dispatch?.ship_to_pos?.id;
        });
        const receiverUser = receiverOptions?.find(co => {
            return co.value === dispatch?.receiver?.id.toString();
        });
        setPosSelected(pos);
        // console.log("edit dispatch", dispatch, receiverUser, "receiverOptions", receiverOptions)
        setReceiverSelected(receiverUser);
        setModaltitle(`Edit Distribution To ${dispatch.warehouse.name}`)
        tog_dispatch();
        setEditing(true);
    }

    const reloadData = async () => {
        DispatchService.getDispatchs()
            .then((response) => {
                setData(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                hideLoader()
            });
    };

    function save_dispatch() {
        const validForm = validateForm();
        const validRec = validateReceiver();
        if (validForm && validRec) {
            let dispatch = {
                id: dispatchID,
                receiver: receiver,
                warehouse: warehouse,
                ship_to_type: shipToType,
                ship_to_warehouse: shipToType === "WAREHOUSE" ? shipToWarehouse : null,
                ship_to_pos: shipToType === "POS" ? shipToPos : null,
                ship_date: shipDate,
                status: status,
                comments: comments
            }

            showLoader();
            if (!editing) {
                DispatchService.saveDispatch(dispatch)
                    .then((response) => {
                        refreshData(response.data);
                        setModalDispatch(false);
                        setMessage("Distribution added successfully");
                        setModalSuccess(true);
                        setTimeout(() => { setModalSuccess(false) }, 1000);
                    })
                    .catch(err => errorHandler(err))
                    .finally(() => {
                        hideLoader();
                    })
            }
            else {
                DispatchService.updateDispatch(dispatch)
                    .then((response) => {
                        refreshData(response.data);
                        setModalDispatch(false);
                        setMessage("Distribution updated successfully");
                        setModalSuccess(true);
                        setTimeout(() => { setModalSuccess(false) }, 1000);
                    })
                    .catch(err => console.dir(err))
                    .finally(() => {
                        hideLoader();
                        setEditing(false);
                    })
            }
        }
        // else {
        //     setModalDispatch(false);
        //     setMessage("You must to select a "+(shipToType === "POS"?"Point of Sale": "Warehouse"));
        //     setModalSuccess(true);
        //     setTimeout(() => { setModalSuccess(false) }, 3000);
        //     setModalDispatch(true);
        // }
    }
    const apply_dispatch = async (item) => {
        showLoader();
        setEditing(true);
        const dispatch = {
            id: item.id,
            ship_to_type: item.ship_to_type,
            pointofsale: item.pointofsale?.id,
            warehouse: item.warehouse?.id,
            comments: item.comments,
            date: item.date,
            status: 'APPLIED',
        };
        await DispatchService.updateDispatch(dispatch)
            .then((response) => {
                refreshData(response.data);
                setMessage('Release check Created successfully');
                setModalSuccess(true);
                setEditing(false);
                setTimeout(() => {
                    setModalSuccess(false);
                }, 2000);
            })
            .catch(err => console.dir(err))
            .finally(() => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                hideLoader();
            });
        reloadData();
    }

    function errorHandler(error) {
        console.dir(error)
    }

    function tog_dispatch() {
        setModalDispatch(!modal_dispatch);
    }
    function tog_success() {
        setModalSuccess(!modal_success);
    }

    function refreshData(dispatch) {
        let currentData = data;

        let objwh = warehouses.find(w => {
            return w.id === dispatch.warehouse;
        });
        let objwh2 = warehouses.find(w => {
            return w.id === dispatch.ship_to_warehouse;
        });

        let objpos = posList.find(p => {
            return p.id === dispatch.ship_to_pos;
        });
        // console.log("refresh data", dispatch, users);
        const objreceiver = users.find(p => {
            return p.id === dispatch.receiver.toString();
        });

        dispatch.warehouse = objwh;
        dispatch.ship_to_warehouse = objwh2;
        dispatch.ship_to_pos = objpos;
        dispatch.receiver = objreceiver;

            if (!editing) {
                currentData.push(dispatch);
            }
            else {
                let prodIndex = currentData.findIndex(p => { return p.id === dispatchID; });
                dispatch.id = dispatchID;
                currentData[prodIndex] = dispatch;
            }
        setData(currentData);
    }

    function handleSelectShipToType(value) {
        setShipToType(value);
        setPosValid(true);
        setWarehouseValid(true);
        if (value === "WAREHOUSE") {
            setShipToPos(0);
            setPosSelected(null);
            setShipToWarehouse(0);
        }
        else {
            setShipToPos(0);
            setPosSelected(null);
            setShipToWarehouse(0);
        }
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        showLoader();

        reloadData();

        WarehouseService.getWarehouses()
            .then((response) => {
                setWarehouses(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });


        PosService.getPos()
            .then((response) => {
                fillPosOptions(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });

        UserService.getUsers()
            .then((response) => {
                fillReceiverOptions(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });
    }, [])

    const validateForm = (posid = 0) => {
        if (shipToType === "POS") {
            if ((posid !== -1 && isValidId(shipToPos)) || isValidId(posid)) {
                setPosValid(true);
                return true;
            } else {
                setPosValid(false);
                return false;
            }
        }
        else {
            // console.log("Invalid",shipToWarehouse);
            if (isValidId(shipToWarehouse)) {
                setWarehouseValid(true);
                return true;
            } else {
                setWarehouseValid(false);
                return false;
            }
        }
    }
    const validateReceiver = (reciverid = 0) => {
        // console.log("reciverid",reciverid,receiver);
        if ((reciverid !== -1 && isValidId(reciverid)) || isValidId(receiver)) {
            setReceiverValid(true);
            return true;
        } else {
            setReceiverValid(false);
            return false;
        }
    }
    function handlePosChange(option) {
        if (hasValue(option) && option.hasOwnProperty('value')) {
            let value = option.value;
            setShipToPos(parseInt(value));
            setPosSelected(option);
            validateForm(parseInt(value));
        }
        else {
            setShipToPos(0);
            setPosSelected(null);
            validateForm(-1);
        }
    }
    function handleReceiverChange(option) {
        if (hasValue(option) && option.hasOwnProperty('value')) {
            let value = option.value;
            setReceiver(parseInt(value));
            setReceiverSelected(option);
            validateReceiver(parseInt(value));
        }
        else {
            setReceiver(0);
            setReceiverSelected(null);
            validateReceiver(-1);
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={() => searchInTable({ tableId: "dispatchs-table", inputSearchId: "filter-text" })}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("dispatchs", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newDispatch()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="dispatchs-table-containeer" className="table-responsive">
                            <table id="dispatchs-table" className="table table-hover mb-0">
                                <thead>
                                    <tr className="header">
                                        <th className="dark">Warehouse</th>
                                        <th className="dark">Ship To Type</th>
                                        <th className="dark" style={{ width: "15%" }}>Ship To</th>
                                        <th className="dark">Receiver</th>
                                        <th className="dark">Ship Date</th>
                                        <th className="dark">Status</th>
                                        <th className="dark">Comments</th>
                                        <th className="dark">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!data && data.length > 0 ? data.map((item, key) => {
                                        return (
                                            <tr key={`dispatchs-table-tbody-tr-${key}`}>
                                                <td>{item.warehouse?.name}</td>
                                                <td>{item.ship_to_type}</td>
                                                <td>{hasValue(item.ship_to_warehouse) ? item.ship_to_warehouse?.name : hasValue(item.ship_to_pos?.mso_name) ? item.ship_to_pos?.mso_name : ""}</td>
                                                <td>{item.receiver?.given_name}</td>
                                                <td>{formatDate(item.ship_date)}</td>
                                                <td>{item.status}</td>
                                                <td>{item.comments}</td>
                                                <td>
                                                    {
                                                        !(item.status === "CONFIRMED") ? (
                                                            <Button variant="outline-secondary" size="sm" onClick={() => editDispatch(item)}><i className="bx bx-edit me-1"></i> Edit</Button>
                                                        ) : (<></>)
                                                    }
                                                    {!(item.status === "CONFIRMED") && <>&nbsp;</>}
                                                    <Link key={`dispatchs_row_link_${key}`} className="btn btn-outline-secondary btn-sm waves-effect waves-light"
                                                        to={{
                                                            pathname: '/distribution-detail',
                                                            search: `?did=${item.id}`
                                                        }}
                                                    > <i className="bx bx-grid me-1"></i>Details
                                                    </Link>
                                                    {
                                                        (item.status === "CONFIRMED") ? (
                                                            <>&nbsp;
                                                                <Button variant="outline-secondary" size="sm" onClick={() => apply_dispatch(item)}><i className="bx bx-edit me-1"></i> Generate release check</Button>
                                                            </>
                                                        ) : (<>&nbsp;</>)
                                                    }


                                                </td>
                                            </tr>
                                        )
                                    }) : (<tr><td colSpan={3}>No data found.</td></tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Container>
            </div>

            {loader}

            {/* Add New Modal */}
            <Modal
                isOpen={modal_dispatch}
                toggle={() => {
                    tog_dispatch();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="dispatchModalLabel">
                        {modaltitle}
                    </h5>

                    <button
                        onClick={() => {
                            setModalDispatch(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="p-2 g-col-12">
                        <div className="position-absolute top-0 end-0">
                            <label className="text-primary mx-4">{status}</label>
                        </div>
                    </div>
                    <div className="p-2 g-col-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="dispatchWarehouseName">Warehouse</label>
                                    <Form.Select placeholder="Select the Warehouse" id="dispatchWarehouseName" onChange={(e) => setWarehouse(e.target.value)} value={warehouse}>
                                        <option key="ddl-wharehouse-option-empty">Select the warehouse</option>
                                        {!!warehouses && warehouses.length > 0 ? warehouses.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                                            return (
                                                <option key={`ddl-wharehouse-option-${key}`} value={item.id}>
                                                    {item.name}
                                                </option>
                                            )
                                        }) : (<option></option>)
                                        }
                                    </Form.Select>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="dispatchUser">Receiver</label>
                                    {/* <Form.Select placeholder="Select the Receiver" id="dispatchUser" onChange={(e) => setReceiver(parseInt(e.target.value))} value={receiver} required>
                                        <option key="ddl-user-option-empty">Select the receiver</option>
                                        {!!users && users.length > 0 ? users.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                                            return (
                                                <option key={`ddl-user-option-${key}`} value={item.id}>
                                                    {item.given_name}
                                                </option>
                                            )
                                        }) : (<option></option>)
                                        }
                                    </Form.Select> */}
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={receiverSelected}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="dispatchUser"
                                        options={receiverOptions}
                                        onChange={handleReceiverChange}
                                        value={receiverSelected}
                                    />
                                    {!receiverValid && <small className="text-danger">The Receiver field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="dispatchShipToType">Ship to type</label><br />
                                    <input type="radio" name="dispatchShipToType" value="WAREHOUSE" defaultChecked={shipToType === "WAREHOUSE" ? true : false} onChange={(e) => handleSelectShipToType(e.target.value)} />&nbsp;<span>Warehouse</span>&nbsp;
                                    <input type="radio" name="dispatchShipToType" value="POS" defaultChecked={shipToType === "POS" ? true : false} onChange={(e) => { handleSelectShipToType(e.target.value) }} />&nbsp;<span>Point Of Sale</span>&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className={shipToType === "WAREHOUSE" ? 'row' : 'row d-none'}>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="dispatchShipToWarehouse">Warehouse</label>
                                    <Form.Select placeholder="Select the Warehouse" id="dispatchShipToWarehouse" onChange={(e) => { setShipToWarehouse(parseInt(e.target.value)); validateForm(); }} value={shipToWarehouse}>
                                        <option key="ddl-wharehouse-option-empty">Select the warehouse</option>
                                        {!!warehouses && warehouses.length > 0 ? warehouses.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                                            return (
                                                <option key={`ddl-wharehouse-option-${key}`} value={item.id}>
                                                    {item.name}
                                                </option>
                                            )
                                        }) : (<option></option>)
                                        }
                                    </Form.Select>
                                    {!warehouseValid && <small className="text-danger">The Warehouse field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className={shipToType === "POS" ? 'row' : 'row d-none'}>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="dispatchPos">Point of sale</label>
                                    {/* <Form.Select placeholder="Select the Point Of Sale" id="dispatchPos" onChange={(e) => {setShipToPos(e.target.value);validateForm();}} value={shipToPos}>
                                        <option key="ddl-pos-option-empty">Select the Point Of Sale</option>
                                        {!!posList && posList.length > 0 ? posList.map((item, key) => {
                                            return (
                                                <option key={`ddl-pos-option-${key}`} value={item.id}>
                                                    {item.mso_name}
                                                </option>
                                            )
                                        }) : (<option></option>)
                                        }
                                    </Form.Select> */}
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={posSelected}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="dispatchPos"
                                        options={posOptions}
                                        onChange={handlePosChange}
                                        value={posSelected}
                                    />
                                    {!posValid && <small className="text-danger">The Poin of Sale field is required</small>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="DispatchDate">Ship Date</label>
                                    <input type="date" className="form-control" placeholder="Enter Ship date" id="DispatchDate"
                                        min={getValueMinDataToday()} onChange={(e) => setShipDate(e.target.value)} value={shipDate} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="DispatchComments">Comments</label>
                                    <textarea className="form-control" placeholder="Enter comments" id="DispatchComments" onChange={(e) => setComments(e.target.value)} value={comments}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalDispatch(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_dispatch();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{message}</p>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default DistributionManagement;