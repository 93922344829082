import httpCommon from "./http-common";

const LoginService = {
  loginPost(data) {
    let response = httpCommon.post(
      "/administration/token",
      JSON.stringify(data)
    );
    return response;
  },
  getTree (token){
    // console.log("getTree",httpCommon,"token",token);
    let response = httpCommon.get(
      "/administration/tree",
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
          accept: "application/json, text/plain, */*",
        }
      }
      
    );
    return response;
  }
};
 

export default LoginService;
