import React from "react";
import { Col, Row } from "reactstrap";
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import login from "../../assets/images/login-img.png";


const AuthSlider = () => {
  return (
    <React.Fragment>
      <Col className="col-xxl-6 col-lg-6 col-md-6">
        <div className="auth-bg bg-white py-md-5 p-4 d-flex">
          <div className="bg-overlay bg-white"></div>

          <Row className="justify-content-center align-items-center">
            <Col xl={8}>
              <div className="mt-4">
                <img src={login} className="img-fluid" alt="" />
              </div>
              <div className="p-0 p-sm-4 px-xl-0 py-5">
              <div className="carousel-inner w-75 mx-auto">

                <Carousel showThumbs={false} className="slider_css">

                    <div>
                      <div className="item">
                        <div className="testi-contain text-center">
                          <h5 className="font-size-20 mt-4">“Don’t find fault, find a remedy: anyone can complain.” - Henry Ford
                          </h5>
                          <p className="font-size-15 text-muted mt-3 mb-0">&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="item">
                        <div className="testi-contain text-center">
                          <h5 className="font-size-20 mt-4">“Talent wins games, but teamwork and intelligence wins championships.” - Michael Jordan</h5>
                          <p className="font-size-15 text-muted mt-3 mb-0">&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="item">
                        <div className="testi-contain text-center">
                          <h5 className="font-size-20 mt-4">“None of Us is as Good as All of Us.” - Ray Kroc</h5>
                          <p className="font-size-15 text-muted mt-3 mb-0">&nbsp;</p>
                        </div>
                      </div>
                    </div>
                </Carousel>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>

    </React.Fragment>
  );
};

export default AuthSlider;





