import axios from "axios";
import  TokenStorage from '../util/token-storage'
import { appHostName } from '../util/commons-utilities'

const ReportService = {
    getMaterials(filter) {
        let bodyFormData = new FormData();
        bodyFormData.append('report', 'materials');
        bodyFormData.append('start_date', new Date(filter.start_date).toISOString());
        bodyFormData.append('end_date',new Date(filter.end_date).toISOString());

        return axios({
            method: "post",
            url: appHostName() + "/transactions/inventorymovement",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },

    getMoves(filter) {
        let bodyFormData = new FormData();
        bodyFormData.append('report', 'moves');
        bodyFormData.append('division', filter.division);
        bodyFormData.append('country', filter.country);

        return axios({
            method: "post",
            url: appHostName() + "/transactions/inventorymovement",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },

    getMoves2(filter) {
        let bodyFormData = new FormData();
        bodyFormData.append('report', 'moves_2');
        bodyFormData.append('start_date', new Date(filter.start_date).toISOString());
        bodyFormData.append('end_date',new Date(filter.end_date).toISOString());

        return axios({
            method: "post",
            url: appHostName() + "/transactions/inventorymovement",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },

    getEntryMovesLog(filter) {
        let bodyFormData = new FormData();
        bodyFormData.append('report', 'entry-moves-log');
        bodyFormData.append('material', filter.material);
        bodyFormData.append('division', filter.division);
        bodyFormData.append('country', filter.country);

        return axios({
            method: "post",
            url: appHostName() + "/transactions/inventorymovement",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },

    getTransitMovesLog(filter) {
        let bodyFormData = new FormData();
        bodyFormData.append('report', 'intransit-moves-log');
        bodyFormData.append('material', filter.material);
        bodyFormData.append('division', filter.division);
        bodyFormData.append('country', filter.country);

        return axios({
            method: "post",
            url: appHostName() + "/transactions/inventorymovement",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },

    getPosMovesLog(filter) {
        let bodyFormData = new FormData();
        bodyFormData.append('report', 'pos-moves-log');
        bodyFormData.append('material', filter.material);
        bodyFormData.append('division', filter.division);
        bodyFormData.append('country', filter.country);

        return axios({
            method: "post",
            url: appHostName() + "/transactions/inventorymovement",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },

    exportMovesToExcel(filter) {
        let bodyFormData = new FormData();
        bodyFormData.append('report', 'export_moves');
        bodyFormData.append('start_date', new Date(filter.start_date).toISOString());
        bodyFormData.append('end_date',new Date(filter.end_date).toISOString());

        return axios({
            method: "post",
            url: appHostName() + "/transactions/inventorymovement",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    }
};

export default ReportService;