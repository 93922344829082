
import React, { useContext, useEffect } from "react";
import Icon from "react-eva-icons";
import usePageTitle from "../../util/head-element";
// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";


import { UserContext } from "../../context/userContext";
import { useState } from "react";
import LoginService from "../../services/login-service";
import UpdateContext from "../../util/update-context";

const Login = () => {
  // const { setToken, setViewAccess} = useContext(UserContext);
  const { setAppData, appData } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));
  let token=appData.token;

  usePageTitle("Login | Warehouse");

  useEffect(() => {
    if (layoutMode === "dark") {
      document.body.classList.remove("bg-transparent");
    } else {
      document.body.className = "bg-transparent";
    }
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const handlePasswordAddOnBtn = (e) => {
    e.preventDefault();
    let pwd = document.getElementById("password-input");
    "password" === pwd.type ? (pwd.type = "text") : (pwd.type = "password");
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    let loginInf = {
      user: email,
      password: password,
    };

    await LoginService.loginPost(loginInf).then(async (response) => {
      const tokenValue = response.data.AuthenticationResult.IdToken;
      token = tokenValue;
      localStorage.setItem("token", tokenValue);
      //console.log("response login",response,"token", tokenValue)
      UpdateContext.updateToken(setAppData,appData,token);
      // setToken(tokenValue);
    });
    

  };

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">

          <Row className="g-0 justify-content-center">
            <Col className="col-xxl-6 col-lg-6 col-md-6">
              <Row className="justify-content-center g-0">
                <Col xl={7}>
                  <div className="p-4">
                    <Card className="mb-0">
                      <CardBody>
                        <div className="auth-full-page-content rounded d-flex p-3 my-2">
                          <div className="w-100">
                            <div className="d-flex flex-column h-100">
                              <div className="mb-4 mb-md-5">
                                <Link to="!#" className="d-block auth-logo">
                                  <img
                                    src={logodark}
                                    alt=""
                                    height="50"
                                    className="auth-logo-dark me-start"
                                  />
                                  <img
                                    src={logolight}
                                    alt=""
                                    height="50"
                                    className="auth-logo-light me-start"
                                  />
                                </Link>
                              </div>

                              <div className="auth-content my-auto">
                                <div className="text-center">
                                  <h5 className="mb-0">Welcome</h5>
                                  <p className="text-muted mt-2">
                                    Sign in to continue
                                  </p>
                                </div>
                                <form
                                  onSubmit={handleSubmit}
                                  className="mt-4 pt-2"
                                >
                                  <div className="form-floating form-floating-custom mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="input-username"
                                      placeholder="Enter User Name"
                                      onChange={(e) => setEmail(e.target.value)}
                                      value={email}
                                    />
                                    <label htmlFor="input-username">
                                      Username
                                    </label>
                                    <div className="form-floating-icon">
                                      <Icon
                                        name="people-outline"
                                        fill="#555b6d"
                                      />
                                    </div>
                                  </div>

                                  <div className="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                    <input
                                      type="password"
                                      className="form-control pe-5"
                                      id="password-input"
                                      placeholder="Enter Password"
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      value={password}
                                    />

                                    <button
                                      type="button"
                                      className="btn btn-link position-absolute h-100 end-0 top-0"
                                      id="password-addon"
                                      onClick={handlePasswordAddOnBtn}
                                    >
                                      <i className="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                    </button>
                                    <label htmlFor="password-input">
                                      Password
                                    </label>
                                    <div className="form-floating-icon">
                                      <Icon
                                        name="lock-outline"
                                        fill="#555b6d"
                                      />
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <div className="col">
                                      <div className="form-check font-size-15">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="remember-check"
                                        />
                                        <label
                                          className="form-check-label font-size-13"
                                          htmlFor="remember-check"
                                        >
                                          Remember me
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    {/* <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Log In</button> */}

                                    <Button
                                      to="/dashboard"
                                      className="btn btn-primary w-100 waves-effect waves-light"
                                      type="submit"
                                    >
                                      Log In

                                    </Button>
                                  </div>
                                </form>
                              </div>

                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  © {new Date().getFullYear()} Warehouse Powered
                                  by Cheil Panamá
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
