import { hasValue, siteLocation } from "../../util/commons-utilities";
import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Card, CardBody, Col, Row } from 'reactstrap';
//SimpleBar
import SimpleBar from "simplebar-react";
import { usCurrency} from "../../util/commons-utilities";

const productsCount = function(data){
   if(hasValue(data)){
       return data.length;
   }
   else
    return 0;
}

const productsByCategory = function(data){
    if(hasValue(data)){
        let keys = [];
        let values = [];
        keys = [...new Set(data.map(item => item.material.type.value))];

        let counts = {};
        data.forEach(function(item) {
          if (counts.hasOwnProperty(item.material.type.value)) {
            counts[item.material.type.value] = counts[item.material.type.value] + 1;
          } else {
            counts[item.material.type.value] = 1;
          }
        });
        
        let holder = [];        
        for (var prop in counts) {
            values.push(counts[prop]);
            holder.push({ category: prop, value: counts[prop] });
        }

        return {keys: keys, values: values, summary: holder}
    }
 }

 const totalAmount = function (data){
    let sum = 0.0;
    if(hasValue(data)){
        data.forEach(function(item) {
          sum += item.qty *  item.material.unit_price;
        });
    }
    return usCurrency.format(sum);
}

 const Widgets = (props) => {
    let summary1 = productsByCategory(props.materials);
    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12} key="col-summary-1">               
                    <Card> 
                        <CardBody className="card-body" style={{height:290}}>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 text-primary">{props.warehouse.name}</h4>
                                    <p className="text-muted mb-1">{siteLocation(props.warehouse)} <i className="fas fa-map-marked-alt"></i>&nbsp;[{props.warehouse.latitude}, {props.warehouse.longitude}]</p>                                             
                                </div>
                            </div>                  
                            <hr className="mb-1" />                            
                            <Tabs
                                defaultActiveKey="warehouse-contact-person"
                                transition={true}
                                id="warehouse-detail-tab"
                                className="mb-1"
                                >
                                <Tab eventKey="warehouse-contact-person" title="Contact Person">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text-muted mb-1"><b>First Name: </b> {props.warehouse.contact_first_name}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-muted mb-1"><b>Last Name: </b> {props.warehouse.contact_last_name}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text-muted mb-1"><b>Phone Number: </b>{props.warehouse.contact_phone_number}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-muted mb-1"><b>Email Address: </b>{props.warehouse.contact_email_address}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="text-muted mb-1"><b>Office: </b>{props.warehouse.contact_office}</p>
                                        </div>
                                    </div>   
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="text-muted mb-1"><b>Memo: </b></p>
                                            <p className="text-muted mb-1">{props.warehouse.contact_memo}</p>
                                        </div>
                                    </div>                                           
                                </Tab>     
                                <Tab eventKey="warehouse-summaries" title="Summaries">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <p className="text-muted mb-1"><b>Total products: </b> {productsCount(props.materials)}</p>
                                            <p className="text-muted mb-1"><b>Total amount: </b> {totalAmount(props.materials)}</p>
                                        </div>
                                    </div>
                                    <SimpleBar className="mx-n4" style={{ maxHeight: "110px" }}>
                                        <div className="container-fluid mx-auto p-2">
                                            <table className="table table-light">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={3} className="text-center">Totals by material type</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Material Type</th>
                                                        <th>Quantity</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(summary1.summary || []).map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.category}</td>
                                                            <td>{item.value}</td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </SimpleBar>                                
                                </Tab>                    
                            </Tabs> 
                        </CardBody>
                    </Card>
                </Col>  

            </Row>
        </React.Fragment>
    );
};

 export  {
    Widgets
 }