import React, { useContext, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Footer from './Footer';
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { validateExistInArrayByField } from "../../util/commons-utilities";
import UpdateContext from "../../util/update-context";

const SidebarContent = (props) => {
  const ref = useRef();

  const history = useHistory();
  const { appData, setAppData } = useContext(UserContext);
  // console.log("navbar:", appData)

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = props.location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
    // if(!appData.viewAccess || appData.viewAccess === null || appData.viewAccess ===undefined) {
    // new MetisMenu("#side-menu");
    // }
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    if (appData.viewAccess && appData.viewAccess !== null) {
      activeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.viewAccess]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }


  const handleLogout = (e) => {
    UpdateContext.resetContext(setAppData, appData);
    window.location.href = "/login";
  };

  const validateAccessParenth = (accessParent) => {
    let valid = false;
    if (appData.viewAccess !== undefined && appData.viewAccess?.length > 0) {
      valid = validateExistInArrayByField(accessParent, appData.viewAccess, "name");
      return valid;
    }
    return valid;
  };
  const validateAccess = (access) => {
    let valid = false;
    if (appData.viewAccess !== undefined && appData.viewAccess?.length > 0) {
      appData.viewAccess.forEach(element => {
        if (element.name === access.split('_')[0]) {
          valid = validateExistInArrayByField(access, element.children, "name");
          return valid;
        }

      });
      return valid;
    }
    //  else return false;
  };

  return (
    <React.Fragment>
      <SimpleBar className="sidebar-menu-scroll" ref={ref}>
        <div id="sidebar-menu">
          <div className="btn-group w-100" role="group">
            <button
              type="button"
              className="btn btn-lg text-body waves-effect"
              onClick={history.goBack}
              title="Go back"
            >
              <i
                className="icon nav-icon"
                data-eva="arrow-ios-back-outline"
              ></i>
              &nbsp;
            </button>
          </div>
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title" data-key="t-menu">
              Menu
            </li>
            {validateAccessParenth("mdm") &&
              <li>
                <Link to="/#" data-key="t-management" className="has-arrow">
                  <i className="fas fa-layer-group"></i>
                  <span className="menu-item" >Management</span>
                </Link>
                <ul className="sub-menu">
                  {validateAccess("mdm_divisions") &&
                    <li>
                      <Link to="/divisions" data-key="t-types">
                        Divisions
                      </Link>
                    </li>
                  }
                  {validateAccess("mdm_pic") &&
                    <li>
                      <Link to="/peopleincharge" data-key="t-types">
                        People in charge
                      </Link>
                    </li>
                  }
                  {validateAccess("mdm_types") &&
                    <li>
                      <Link to="/types" data-key="t-types">
                        Material Types
                      </Link>
                    </li>
                  }
                  {validateAccess("mdm_subtypes") &&
                    <li>
                      <Link to="/subtypes" data-key="t-subtypes">
                        Material Subtypes
                      </Link>
                    </li>
                  }
                  {validateAccess("mdm_groups") &&
                    <li>
                      <Link to="/groups" data-key="t-groups">
                        Material Groups
                      </Link>
                    </li>
                  }
                  {validateAccess("mdm_subgroups") &&
                    <li>
                      <Link to="/subgroups" data-key="t-subgroups">
                        Material Subgroups
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {validateAccessParenth("material") &&
              <li>
                <Link to="/#" data-key="t-materials" className="has-arrow">
                  <i className="fas fa-cubes"></i>
                  <span className="menu-item" >Materials</span>
                </Link>
                <ul className="sub-menu">
                  {validateAccess("material_create") &&
                    <li>
                      <Link to="/materials" data-key="t-material-register">
                        Register
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {validateAccessParenth("entry") &&
              <li>
                <Link to="/#" data-key="t-entry-menu" className="has-arrow">
                  <i className="fas fa-shopping-cart"></i>
                  <span className="menu-item" >Entry</span>
                </Link>
                <ul className="sub-menu">
                  {validateAccess("entry_create") &&
                    <li>
                      <Link to="/material-entry" data-key="t-create-entry">
                        Create Entry
                      </Link>
                    </li>
                  }
                  {validateAccess("entry_check") &&
                    <li>
                      <Link to="/entry-check" data-key="t-entry-check">
                        Entry Check
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {validateAccessParenth("dispatch") &&
              <li>
                <Link to="/#" data-key="t-distribution-menu" className="has-arrow">
                  <i className="fas fa-truck-loading"></i>
                  <span className="menu-item" >Distribution</span>
                </Link>
                <ul className="sub-menu">
                  {validateAccess("dispatch_create") &&
                    <li>
                      <Link to="/distribution" data-key="t-distribution">
                        Create Distribution
                      </Link>
                    </li>
                  }
                  {validateAccess("dispatch_create") &&
                    <li>
                      <Link to="/distribution-check" data-key="t-release-check">
                        Release Check
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {validateAccess("pos") &&
              <li>
                <Link to="/pos" data-key="t-pos">
                  <i className="fab fa-shopify"></i>
                  <span className="menu-item">Point Of Sale</span>
                </Link>
              </li>
            }{validateAccess("warehouse") &&
              <li>
                <Link to="/warehouses" data-key="t-warehouse">
                  <i className="icon nav-icon" data-eva="pin-outline"></i>
                  <span className="menu-item">Warehouse</span>
                </Link>
              </li>
            }
            {validateAccessParenth("reports") &&
              <li>
                <Link to="/#" data-key="t-reports" className="has-arrow">
                  <i className="fas fa-file-invoice"></i>
                  <span className="menu-item">Reports</span>
                </Link>
                <ul className="sub-menu">
                  {validateAccess("reports_inventory") &&
                    <li><Link to="/inventory-report" data-key="t-inventory">Inventory report</Link></li>
                  }
                  {validateAccess("reports_movements") &&
                    <li><Link to="/inventory-moves" data-key="t-inventory-moves">Inventory moves</Link></li>
                  }
                </ul>
              </li>
            }
            <li>
                <Link to="/pdfviewer" data-key="t-help">
                  <i className="fas fa-book"></i>
                  <span className="menu-item">Help and Docs</span>
                </Link>                         
            </li>
            <li
              style={{
                display: "flex",
                justifyContent: "space-around",
                paddingTop: "30px",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-secondary"
                style={{ width: "220px" }}
                onClick={handleLogout}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
        <Footer />
      </SimpleBar>
    </React.Fragment >
  );
};
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(SidebarContent);
