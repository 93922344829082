import React, { useEffect, useState } from 'react';
import useFullPageLoader from "../../Hooks/useFullPageLoader"
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import usePageTitle from "../../util/head-element";
import CountryService from '../../services/country-service';
import PosService from '../../services/pos-service';
import { FormControl } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { hasValue, isNullOrEmpty, objectToCsv, searchInTable } from "../../util/commons-utilities";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

const PosManagement = () => {
    const dispatch = useDispatch();
    usePageTitle("Pos | Warehouse");
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Pos",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);


    const [data, setData] = useState(null)

    const [modalPos, setModalPos] = useState(false);
    const [modal_success, setmodal_success] = useState(false);
    const [posID, setPosID] = useState(0);
    const [name, setName] = useState('');
    const [gsmsitename, setGsmSiteName] = useState('');
    const [siteid, setSideId] = useState('');
    const [country, setCountry] = useState(0);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const[editing, setEditing] = useState(false);
    const captions = ["Id","Name"];
    const [countries, setCountries] = useState([]);

    const [nameValidationClassName, setNameValidationClassName] = useState('d-none');
    const [nameValidationMessage, setNameValidationMessage] = useState('');
    const [countryValidationClassName, setCountryValidationClassName] = useState('d-none');
    const [countryValidationMessage, setCountryValidationMessage] = useState('');
    const [gsmSiteNameValidationClassName, setGsmSiteNameValidationClassName] = useState('d-none');
    const [gsmSiteNameValidationMessage, setGsmSiteNameValidationMessage] = useState('');
    const [siteIdValidationClassName, setSiteIdValidationClassName] = useState('d-none');
    const [siteIdValidationMessage, setSiteIdValidationMessage] = useState('');

    function validateData(){
        let isValid = true;
        if(isNullOrEmpty(name)){
            setNameValidationClassName("text-danger");
            setNameValidationMessage("The Name field is required.");
            isValid = false;
        }
        else{
            setNameValidationClassName("d-none");
            setNameValidationMessage("");
        }

        if(!hasValue(country) || parseInt(country)===0){
            setCountryValidationClassName("text-danger");
            setCountryValidationMessage("The Country field is required.");
            isValid = false;
        }
        else{
            setCountryValidationMessage("d-none");
            setCountryValidationMessage("");
        }

        if(isNullOrEmpty(gsmsitename)){
            setGsmSiteNameValidationClassName("text-danger");
            setGsmSiteNameValidationMessage("The Gsm Site Name field is required.");
            isValid = false;
        }
        else{
            setGsmSiteNameValidationClassName("d-none");
            setGsmSiteNameValidationMessage("");
        }

        if(isNullOrEmpty(siteid)){
            setSiteIdValidationClassName("text-danger");
            setSiteIdValidationMessage("The Site Id field is required.");
            isValid = false;
        }
        else{
            setSiteIdValidationClassName("d-none");
            setSiteIdValidationMessage("");
        }
        return isValid;
    }

    function resetValidationMessages(){
        setNameValidationClassName("d-none");
        setNameValidationMessage("");
        setCountryValidationMessage("d-none");
        setCountryValidationMessage("");
        setGsmSiteNameValidationClassName("d-none");
        setGsmSiteNameValidationMessage("");
        setSiteIdValidationClassName("d-none");
        setSiteIdValidationMessage("");       
    }

    function errorHandler(error){
        alert(error.request.responseText);
        console.dir(error)
    }

    function setCurrentPos(pos){
        setPosID(pos.id);
        setName(pos.mso_name);
        setGsmSiteName(pos.gscm_site_name);
        setSideId(pos.site_id);
        setCountry(hasValue(pos.country)?pos.country.id:0);
        setModalTitle('Update ' + pos.mso_name);
    }

    function newPos(){
        setCurrentPos({
            id:0,
            mso_name:"",
            gscm_site_name: "",
            site_id: "",
            country:0
        });
        setModalTitle('Add New');
        tog_Pos();
        setEditing(false);
        setTimeout(()=> {
            resetValidationMessages();
        }, 100);
    }

    function editPos(pos){
        setCurrentPos(pos);
        tog_Pos();
        setEditing(true);
    }


    function save_Pos() {
        let pos = {
            id: posID,
            mso_name: name,
            gscm_site_name: gsmsitename,
            site_id: siteid,
            country: country
        };

        if(validateData()){
            showLoader();
            if(!editing){
                PosService.saveNewPos(pos)
                .then((response) => {                
                    refreshData(response.data);
                    setModalPos(false);
                    setModalMessage('Pos Added Successfully');
                    setmodal_success(true);
                    setTimeout(()=>{setmodal_success(false);},1000);
                })
                .catch(err => errorHandler(err))
                .finally(() => hideLoader()) 
            }
            else{
                PosService.updatePos(pos)
                .then((response) => {               
                    refreshData(response.data);
                    setModalPos(false);
                    setModalMessage('Pos Updated Successfully');
                    setmodal_success(true);
                    setTimeout(()=>{setmodal_success(false);},1000);
                })
                .catch(err => errorHandler(err))
                .finally(() => hideLoader()) 
            }
        }       
    }

    function tog_Pos() {
        setModalPos(!modalPos);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    function refreshData(pos){
        let currentData = data;
        let objCountry = countries.find(t => {
            return t.id === pos.country;
        });
        pos.country = objCountry;
        if(!editing){
            currentData.push(pos);
        }
        else{
            let index = currentData.findIndex(m => {return m.id === posID;});
            currentData[index] = pos;
        }
        setData(currentData);
    }

    useEffect(() => {
        validateData();
    }, [name,country,gsmsitename,siteid])

    useEffect(() => {
        showLoader();
        PosService.getPos()
            .then((response) => {           
                console.log(response.data);
                setData(response.data)
            })
            .catch(err => console.dir(err))
            .finally(() => {hideLoader()})   
 
        CountryService.getCountries()
            .then((response) => {
                setCountries(response.data);
            })
            .catch(err => console.dir(err))
            
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"pos-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("pos", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newPos()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="pos-table-container" className="table-responsive">
                            <table id="pos-table" className="table table-hover mb-0">
                                <thead>
                                    <tr className="header">
                                        <th className="dark">Id</th>
                                        <th className="dark">Country</th>
                                        <th className="dark">Name</th>
                                        <th className="dark">GsmSiteName</th>
                                        <th className="dark">Site Id</th>
                                        <th className="dark">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!!data && data.length > 0 ? data.map((item, key) => {
                                    return(
                                        <tr key={`pos-table-tbody-tr-${key}`}>
                                            <td>{item.id}</td>
                                            <td>{hasValue(item.country)?item.country.name:""}</td>
                                            <td>{item.mso_name}</td>
                                            <td>{item.gscm_site_name}</td>
                                            <td>{item.site_id}</td>
                                            <td>
                                                <Button variant="outline-secondary" size="sm" onClick={()=>editPos(item)}><i className="bx bx-edit me-1"></i> Edit</Button>                          
                                            </td>
                                        </tr>
                                    )   
                                    }):(<tr><td colSpan={3}>No data found.</td></tr>)
                                }
                                </tbody>
                            </table>    
                        </div>
                    </Row>
                </Container>
            </div>

            {loader}

            {/* Add New Modal */}
            <Modal
                isOpen={modalPos}
                toggle={() => {
                    tog_Pos();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="posModalLabel">
                        {` ${modalTitle} `}
                    </h5>

                    <button
                        onClick={() => {
                            setModalPos(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="Pos-Name">Name</label>
                                <input type="text" className="form-control" placeholder="Enter Name" id="Pos-Name" onChange={(e) => setName(e.target.value)} value={name} maxLength={100} />
                                <small className={nameValidationClassName} >{nameValidationMessage}</small>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="pos-country">Country</label>
                                <Form.Select placeholder="Select a country" id="pos-country" class="form-select" onChange={(e) => setCountry(e.target.value)} value={country}>
                                    <option value={0} key="ddl-pos-country-option-empty">Select the country</option>
                                    {!!countries && countries.length > 0 ? countries.map((item, key) => {
                                        return(
                                            <option key={`ddl-pos-country-option-${key}`} value={item.id}>
                                                {item.name}
                                            </option>
                                        )   
                                        }):(<option></option>)
                                    }                                    
                                </Form.Select>
                                <small className={countryValidationClassName} >{countryValidationMessage}</small>
                            </div>
                        </div>                          
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="Pos-GsmSiteName">Gsm Site Name</label>
                                <input type="text" className="form-control" placeholder="Enter Gsm Site Name" id="Pos-GsmSiteName" onChange={(e) => setGsmSiteName(e.target.value)} value={gsmsitename} maxLength={100}/>
                                <small className={gsmSiteNameValidationClassName} >{gsmSiteNameValidationMessage}</small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="Pos-SiteId">Site Id</label>
                                <input type="text" className="form-control" placeholder="Enter Site Id" id="Pos-SiteId" onChange={(e) => setSideId(e.target.value)} value={siteid} maxLength={10}/>
                                <small className={siteIdValidationClassName} >{siteIdValidationMessage}</small>
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalPos(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_Pos();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{modalMessage}</p>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default PosManagement;