import Routes from "./routes/index";
import { React, useEffect, useState } from "react";

import "./assets/scss/themes.scss";
// import { useHistory } from "react-router-dom";
// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { UserContext } from "./context/userContext";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";
// import LoginService from "./services/login-service";
// import UpdateContext from "./util/update-context";
// import { getFirstPath } from "./util/commons-utilities";

fakeBackend();

function App() {
  // const [token, setToken] = useState(localStorage.getItem("token"));
  // const [viewAccess, setViewAccess] = useState({});
  const [appData, setAppData] = useState({});
  // let history = useHistory();

  // useEffect(() => {
  //   if (!token || token===null) {
  //     history.push("/login");
  //     localStorage.removeItem("token");
  //   }else{
  //     LoginService.getTree(token).then((response) => {
  //       UpdateContext.updateViewAccess(setAppData,appData,response.data);
  //       // setViewAccess(response.data);
  //       const path = getFirstPath(response.data)
  //       //go to the first route in the view access
  //       history.push(path);
  //     }).catch((error) => {
  //       localStorage.removeItem("token");
  //       window.location.href = "/login";
  //     });

  //   }
  // }, [token]);

  return (
    <UserContext.Provider value={{
      appData, setAppData
      // token: token, setToken: setToken, viewAccess:viewAccess,setViewAccess:setViewAccess
    }}>
      <BrowserRouter>
        <Routes
        // setToken={setToken} token={token} viewAccess={viewAccess} setViewAccess={setViewAccess}
        />
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
