import React, { useEffect, useState, useRef} from 'react';
import {  
    Container, 
    Row } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";

//services
import ReportService from '../../services/report-service';
import InventoryMoveItem from './inventory-move-item';

//utilities
import { currentWeek, formatDate, s2ab } from '../../util/commons-utilities';
import { useDownloadExcel } from 'react-export-table-to-excel';

const InventoryMoves = () => {
    const dispatch = useDispatch();   
    /*
    set breadcrumbs
    */
    useEffect(() => {
        dispatch(setBreadcrumb({
            title: "Inventory Moves",
        }));
    }, [dispatch]);

    usePageTitle("Inventory Moves | Warehouse");

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [materials, setMaterials] = useState(null);
    const [movements, setMovements] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'inventory-moves',
        sheet: 'Moves'
    });

    function getData(filter){
        showLoader();
        ReportService.getMaterials(filter)
        .then((response) => {
            setMaterials(response);        
        })
        .catch(err => console.dir(err))
        .finally(() => {            
                // eslint-disable-next-line react-hooks/exhaustive-deps
                hideLoader();
        });
        
        ReportService.getMoves2(filter)
        .then((response) => {
            setMovements(response);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        }); 
    }
    
    function submitForm(){
        let filter = {
            start_date: startDate,
            end_date: endDate
        }
        getData(filter);
    }

    function resetForm(){
        setStartDate('');
        setEndDate('');
    }

    function downloadExcelFile(){

        let filter = {
            start_date: startDate,
            end_date: endDate
        }
        let filename = `inventory_moves_between_${formatDate(startDate).replace(new RegExp("-", 'g'), "")}_${formatDate(endDate).replace(new RegExp("-", 'g'), "")}.xlsx`;
        showLoader();
        ReportService.exportMovesToExcel(filter)
        .then((response) => {
            let data = response.replace(/['"]+/g, '');
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })
            let objUrl = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('href', objUrl);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        }); 
        
    }

    useEffect(() => {
        let dates = currentWeek();
        setStartDate(formatDate(dates.firstday));
        setEndDate(formatDate(dates.lastday));
        let filter = {
            start_date: formatDate(dates.firstday),
            end_date: formatDate(dates.lastday)
        }
        getData(filter);
    }, []);

    return (
        <React.Fragment>
            <div key="page-content-pd" className="page-content">
                <div key="page-content-card-header-pd" className="card-header">
                    <h4 key="page-content-card-header-pd-h4" className="card-title mb-0">&nbsp;</h4>
                </div>                
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1">
                        <Card key="card-filter">
                            <Card.Body>
                                <InputGroup key="inputgroup-1">                                                                                                         
                                    <Form.Control type='date' 
                                        id="inputgroup-formcontrol-start-date" 
                                        key="inputgroup-formcontrol-start-date"
                                        className='form-control' 
                                        placeholder='Enter start date' 
                                        aria-label='Enter start date'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        ></Form.Control>
                                     <Form.Control type='date' 
                                        id="inputgroup-formcontrol-end-date" 
                                        key="inputgroup-formcontrol-end-date"
                                        className='form-control' 
                                        placeholder='Enter end date' 
                                        aria-label='Enter end date'
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        ></Form.Control>                                       
                                    <Button key="report-clear-button" variant="outline-secondary" id="report-clear-button" onClick={(e)=> resetForm()}>
                                        <i className='fas fa-eraser'></i>&nbsp;Clear
                                    </Button>                                                    
                                    <Button key="report-filter-button" variant="outline-primary" id="report-filter-button" onClick={(e) => submitForm()}>
                                        <i className='fas fa-search'></i>&nbsp;Search
                                    </Button>
                                    <Button key="report-export-button" variant="outline-success" id="report-export-button" onClick={(e)=> onDownload()}>
                                        <i className='fas fa-file-excel'></i>&nbsp;Export
                                    </Button>
                                </InputGroup>                                 
                            </Card.Body>                              
                        </Card>          
                    </Row>                                                          
                    <Row key="page-content-container-row-2">
                        <div id="moves-tablecontainer" className="table-responsive">
                            <table id="materials-table" className="table table-hover mb-0" ref={tableRef}>
                                <thead key="moves-tablethead-1">
                                    <tr key="moves-tablethead-1-tr" className="header">                                        
                                        <th className="dark">Material</th>
                                        <th className="dark">Movement Place</th>
                                        <th className="dark">Move Date</th>                                 
                                        <th className="dark">Move Type</th>
                                        <th className="dark">Doc. Number</th>
                                        <th className="dark">Prev. Balance</th>
                                        <th className="dark">Quantity</th>
                                        <th className="dark">New Balance</th>
                                        <th className="dark">Unit Price</th>
                                        <th className="dark">Amount</th>
                                    </tr>
                                </thead>
                                <tbody key="moves-table-tbody">
                                {!!materials && materials.length > 0  && !!movements && movements.length > 0 ? materials.map((item, key) => (                                    
                                        <InventoryMoveItem  key={`tr-inventory-item-${key}`} keyrow={key} material={item} moves={movements.filter(m => m.material.id === item.id)}></InventoryMoveItem>
                                    )):(<tr><td colSpan={10}>No data found.</td></tr>)
                                }
                                </tbody>
                            </table>    
                        </div>
                    </Row>                        

                    {loader}

                </Container>                 
            </div>
        </React.Fragment>
    );
};

export default InventoryMoves;