import httpCommon from "./http-common";

const EntryService = {
    getEntries() {
        let data = httpCommon.get("/transactions/entry");
        return data;
    },
    getMaterialsByEntryId(id) {
        let data = httpCommon.get(`/transactions/entrymaterial/all/${id}`);
        return data;
    },
    getEntryByCode(entryCode) {
        let data = httpCommon.get(`/transactions/entry/0/${entryCode}`);
        return data;
    },
    getMaterialsByEntryConfirmed() {
        let data = httpCommon.get(`/transactions/entrymaterial/confirmed/0`);
        return data;
    },  
    getEntryById(id) {
        let data = httpCommon.get(`/transactions/entry/${id}`);
        return data;
    },
    saveEntry(data) {
        let response = httpCommon.post("/transactions/entry",JSON.stringify(data))
        return response;
    },
    updateEntry(data) {
        let response = httpCommon.put(`/transactions/entry/${data.id}`,JSON.stringify(data))
        return response;
    },
    saveMaterialToEntry(data) {
        let response = httpCommon.post(`/transactions/entrymaterial`,JSON.stringify(data))
        return response;
    },
    updateEntryItem(data) {
        let response = httpCommon.put(`/transactions/entrymaterial/${data.id}`,JSON.stringify(data))
        return response;
    },
    removeEntryItem(id) {
        let response = httpCommon.delete(`/transactions/entrymaterial/${id}`)
        return response;
    },
    confirmEntry(entry) {
        let response = httpCommon.put(`/transactions/entry/${entry.id}`,JSON.stringify(entry))
        return response;
    }
};

export default EntryService;