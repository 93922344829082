import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

//actions
import {
    changeLayout,
    changeTopbarTheme,
    changeLayoutWidth,
    showRightSidebarAction,
    changelayoutMode
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

//components
import NavBar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer";
import RightSidebar from "../../components/Common/RightSidebar";
const Layout = (props) => {

    const dispatch = useDispatch();

    const {
        topbarTheme, layoutWidth, breadCrumbItems, showRightSidebar,layoutMode
    } = useSelector(state => ({
        topbarTheme: state.Layout.topbarTheme,
        layoutWidth: state.Layout.layoutWidth,
        isPreloader: state.Layout.isPreloader,
        showRightSidebar: state.Layout.showRightSidebar,
        breadCrumbItems: state.Breadcrumb.breadCrumbItems,
        layoutMode: state.Layout.layoutMode,
    }));

    /*
    document title
    */
    useEffect(() => {
        const title = props.location.pathname;
        let currentage = title.charAt(1).toUpperCase() + title.slice(2);

        document.title =
            currentage + " | Warehouse";
    }, [props.location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    //hides right sidebar on body click
    const hideRightbar = (event) => {
        var rightbar = document.getElementById("right-bar");
        //if clicked in inside right bar, then do nothing
        if (rightbar && rightbar.contains(event.target)) {
            return;
        } else {
            //if clicked in outside of rightbar then fire action for hide rightbar
            dispatch(showRightSidebarAction(false));
        }
    };

    /*
    layout settings
    */
    useEffect(() => {
        dispatch(changeLayout("horizontal"));
    }, [dispatch]);

    useEffect(() => {
        //init body click event fot toggle rightbar
        document.body.addEventListener("click", hideRightbar, true);
    });

    useEffect(() => {
        console.log("layoutMode",layoutMode)
        if (layoutMode) {
            dispatch(changelayoutMode(layoutMode));
        }
    }, [dispatch, layoutMode]);

    useEffect(() => {
        if (topbarTheme) {
            dispatch(changeTopbarTheme(topbarTheme));
        }
    }, [dispatch, topbarTheme]);

    useEffect(() => {
        if (layoutWidth) {
            dispatch(changeLayoutWidth(layoutWidth));
        }
    }, [dispatch, layoutWidth]);

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const openMenu = () => {
        setIsMenuOpened(!isMenuOpened);
    };

    return (
        <React.Fragment>
            <header id="page-topbar" className="ishorizontal-topbar">
                <Header breadCrumbItems={breadCrumbItems} openLeftMenuCallBack={openMenu}/>
                <NavBar menuOpen={isMenuOpened}/>
            </header>
            <div className="main-content">{props.children}</div>
            {showRightSidebar ? <RightSidebar /> : null}
            <Footer />
        </React.Fragment>
    );
};

Layout.propTypes = {
    changeLayout: PropTypes.func,
    changeLayoutWidth: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any
};

export default withRouter(Layout);