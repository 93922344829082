import httpCommon from "./http-common";

const PosService = {
    getPos() {
        let data = httpCommon.get("/pos")
        return data;
    },
    saveNewPos(data) {
        let response = httpCommon.post("/pos",JSON.stringify(data))
        return response;
    },
    updatePos(data) {
        let response = httpCommon.put(`/pos/${data.id}`,JSON.stringify(data))
        return response;
    }
};

export default PosService;