const playbookmd = `
# Manual de Warehouse Management Web Application

- [Módulo de Gestión de Datos Maestros](#módulo-de-gestión-de-datos-maestros)
  - [Divisiones](#divisiones)
    - [Lista de divisiones (Division List)](#lista-de-divisiones-division-list)
    - [Formulario de creación y edición de divisiones](#formulario-de-creación-y-edición-de-divisiones)
  - [Personas a cargo (People in charge)](#personas-a-cargo-people-in-charge)
    - [Lista de personas a cargo (People in charge list)](#lista-de-personas-a-cargo-people-in-charge-list)
    - [Formulario de creación y edición de personas a cargo](#formulario-de-creación-y-edición-de-personas-a-cargo)
  - [Tipo de material (Material Types)](#tipo-de-material-material-types)
    - [Lista de tipos de material (Material type list)](#lista-de-tipos-de-material-material-type-list)
    - [Formulario de creación y edición de tipo de material](#formulario-de-creación-y-edición-de-tipo-de-material)
  - [Subtipos de materiales](#subtipos-de-materiales)
    - [Listado de subtipo de materiales (Material subtype List)](#listado-de-subtipo-de-materiales-material-subtype-list)
    - [Formulario de creación y edición de subtipo de material](#formulario-de-creación-y-edición-de-subtipo-de-material)
  - [Grupos de material](#grupos-de-material)
    - [Lista de grupo de materiales (Material Group list)](#lista-de-grupo-de-materiales-material-group-list)
    - [Formulario de creación y edición de grupo de materiales](#formulario-de-creación-y-edición-de-grupo-de-materiales)
- [Creación y registro de materiales](#creación-y-registro-de-materiales)
  - [Materiales](#materiales)
  - [Lista de Materiales](#lista-de-materiales)
  - [Formulario de creación y edición de Material - General Information Tab](#formulario-de-creación-y-edición-de-material-general-information-tab)
  - [Formulario de creación y edición de Material - Photo Tab](#formulario-de-creación-y-edición-de-material-photo-tab)
- [Orden de entradas a bodegas (Entries)](#orden-de-entradas-a-bodegas-entries)
  - [Crear Entrada (List of entries)](#crear-entrada-list-of-entries)
  - [Formulario de creación y edición de Entrada de Materiales](#formulario-de-creación-y-edición-de-entrada-de-materiales)
  - [Como crear una Entrada de Materiales](#como-crear-una-entrada-de-materiales)
- [Confirmación de recepción en bodega y registro de inventario (Entries Check)](#confirmación-de-recepción-en-bodega-y-registro-de-inventario-entries-check)
  - [1. Crear Verificación de Entrada a partir de una Entrada de Materiales](#1-crear-verificación-de-entrada-a-partir-de-una-entrada-de-materiales)
  - [2. Crear Verificación de Entrada (Entry Check)](#2-crear-verificación-de-entrada-entry-check)
    - [Formulario de creación y edición de Verificación de Entrada de Materiales](#formulario-de-creación-y-edición-de-verificación-de-entrada-de-materiales)
    - [Como crear una Verificación de Entrada de Materiales](#como-crear-una-verificación-de-entrada-de-materiales)
- [Módulo de Distribución](#módulo-de-distribución)
  - [Lista de Distribución](#lista-de-distribución)
    - [Cómo crear de Lista de Distribución](#cómo-crear-de-lista-de-distribución)
    - [Generar Confirmación de recepción de materiales](#generar-confirmación-de-recepción-de-materiales)
- [Confirmación de recepción de materiales (Release Check)](#confirmación-de-recepción-de-materiales-release-check)
  - [Detalle de Confirmación de recepción de materiales al receptor autorizado](#detalle-de-confirmación-de-recepción-de-materiales-al-receptor-autorizado)
  - [Detalle a receptor no autorizado](#detalle-a-receptor-no-autorizado)
- [Reports](#reports)
  - [Inventory Report](#inventory-report)
    - [Registro de movimientos de entrada](#registro-de-movimientos-de-entrada)
    - [Registro de movimientos en tránstio](#registro-de-movimientos-en-tránstio)
    - [Registro de movimientos en puntos de venta](#registro-de-movimientos-en-puntos-de-venta)

## Módulo de Gestión de Datos Maestros {#módulo-de-gestión-de-datos-maestros}

En el módulo de Gestión de Datos Maestros encontrará los listados y formularios para gestionar los catálogos maestros de la base de datos de Warehouse Management Web Application.

### Divisiones

En esta sección encontrarás los datos de las divisiones de materiales registradas en la base de datos. 

#### Lista de divisiones (Division List)

Lista de todas las divisiones de materiales. 

![Lista de divisiones](images/division-1.png)

#### Formulario de creación y edición de divisiones

![Formulario de creación y edición de divisiones](images/division-2.png)

### Personas a cargo (People in charge)

En esta sección encontrarás los datos de las personas a cargo de gestionar la recepción de materiales registradas en la base de datos. 

#### Lista de personas a cargo (People in charge list)

![Personas a cargo](images/people-in-charge-1.png)

#### Formulario de creación y edición de personas a cargo

![Editor de personas a cargo](images/people-in-charge-2.png)

### Tipo de material (Material Types)

En esta sección encontrarás los datos de los tipos de materiales registrados en la base de datos.

#### Lista de tipos de material (Material type list)

![Tipos de material](images/material-types-1.png)

#### Formulario de creación y edición de tipo de material

![Editor de tipos de material](images/material-types-2.png)

### Subtipos de materiales

En esta sección encontrarás los datos de los subtipos de materiales registrados en la base de datos.

#### Listado de subtipo de materiales (Material subtype List)

![Subtipos de material](images/material-subtypes-1.png)

#### Formulario de creación y edición de subtipo de material

![Editor de subtipos de material](images/material-subtypes-2.png)

### Grupos de material

En esta sección encontrarás los datos de los grupos de materiales registrados en la base de datos.

#### Lista de grupo de materiales (Material Group list)

![Grupos de material](images/material-groups-1.png)

#### Formulario de creación y edición de grupo de materiales

![Editor de grupos de material](images/material-groups-2.png)

## Creación y registro de materiales

### Materiales

En este módulo se registran y gestionan los materiales POP que serán distribuidos a los Almacenes y Puntos de Venta del SELA.

### Lista de Materiales

![Listado de materiales](images/materials-1.png)

### Formulario de creación y edición de Material - General Information Tab

![Formulario de creación y edición de Material - General Information Tab](images/materials-2.png)

### Formulario de creación y edición de Material - Photo Tab

![Formulario de creación y edición de Material - Photo Tab](images/materials-3.png)

## Orden de entradas a bodegas (Entries)

En esta sección encontrarás los datos de las ordenes de entrada a bodega registradas en la base de datos.

### Crear Entrada (List of entries)

![Crear Entrada (List of entries)](images/material-entry-1.png)

### Formulario de creación y edición de Entrada de Materiales

![Formulario de creación y edición de Entrada de Materiales](images/material-entry-2.png)

### Como crear una Entrada de Materiales

1. Haga clic en el botón "Add new" en la vista Create Entry (List of entries)
2. En el formulario de creación y edición de Entrada de Material
     - a. Introduzca un valor para Fecha de entrega prevista (Expected delivery date).
     - b. Seleccione un país del selector de Países (Country), esta acción llenará la lista de almacenes con los almacenes relacionados con el valor seleccionado.
     - c. Seleccione una bodega en el selector de bodegas (Warehouse).
     - d. Haga clic en el botón "Save".
     - e. Espere mientras la aplicación procesa la solicitud; cuando finalice, la aplicación lo redirigirá a la vista Detalles de entrada (Entry Detail).

     ![Detalle de entrada](images/material-entry-3.png)

3. Agregar lista de materiales de la entrada (detalle)

     ![Detalle de entrada](images/material-entry-4.png)

     - a. Haga clic en el botón "Add material" para mostrar el formulario de filtrado y selección de materiales.
     - b. Utilice los filtros para encontrar el material deseado, los filtros disponibles son División, Tipo de material, Subtipo de material y Nombre del material.
     - c. Haga clic en el botón "Search" para mostrar la lista de materiales que corresponden a los parámetros del filtro.
     - d. Haz clic en el botón "Add" de la fila del material que deseas agregar al detalle.
         Nota: La aplicación informará el resultado de la solicitud con una alerta ubicada en la parte superior del formulario de filtrado y selección.
     ![Detalle de entrada](images/material-entry-5.png)

4. Cuando termine de agregar y editar los detalles de la entrada de materiales, se habilitará la opción "Confirm Entry" en el botón "Save". En este punto se puede elegir entre guardar el estado actual de la entrada de materiales y su detalle con la opción "Save Changes" y poder realizar actualizaciones del detalle y comentarios posteriormente o pasar del estado CREATED al estado CONFIRMED en el cual ya no será posible realizar modificaciones.

     ![Detalle de entrada](images/material-entry-6.png)

5. Cuando hayas finalizado la edición y haz clic en el botón "Confirm Entry", la aplicación mostrará el mensaje "Entry confirmed successfully" cuando la petición haya sido procesada.
  
     ![Entry confirmed successfully](images/material-entry-7.png)

## Confirmación de recepción en bodega y registro de inventario (Entries Check)

En esta sección encontrarás los datos de las confirmaciones de recepción en bodega y registro de inventario registrados en la base de datos. Las verificaciones de entrada de materiales pueden ser creadas de dos maneras:

### 1. Crear Verificación de Entrada a partir de una Entrada de Materiales

Solamente debes hacer clic sobre el botón "Generate Entry Check", espere mientras la aplicación procesa la solicitud; cuando finalice, la aplicación lo redirigirá a la vista Detalles de verificación  de entrada (Entry Check Detail).

![Material entry](images/material-entry-8.png)

### 2. Crear Verificación de Entrada (Entry Check)

![Crear Verificación de Entrada](images/material-entry-check-1.png)

#### Formulario de creación y edición de Verificación de Entrada de Materiales

![Formulario de creación y edición de Verificación de Entrada de Materiales](images/material-entry-check-2.png)

#### Como crear una Verificación de Entrada de Materiales

1. Haga clic en el botón "Add new" en la vista Entry Check (Entries Check)
2. En el formulario de creación y edición de Verificación de Entrada de Material
     - a. Introduzca un valor para Fecha (Date).
     - b. Introduzca un valor para Código de entrada (Entry code).
     - c. Espera a que la aplicación procese la petición y complete el valor del campo País (Country) y el campo bodega (Warehouse).
     - e. Haga clic en el botón "Save", espere mientras la aplicación procesa la solicitud; cuando finalice, la aplicación lo redirigirá a la vista Detalles de verificación  de entrada (Entry Check Detail).

     ![Detalle de Verificación de Entrada de Materiales](images/material-entry-check-3.png)

     ![Visualizadores de imágen](images/material-entry-check-4.png)

3. Cuando termine de editar los detalles de la verificación de entrada de materiales, se habilitará la opción "Confirm Entry" en el botón "Save". En este punto se puede elegir entre guardar el estado actual de la verificación de entrada de materiales y su detalle con la opción "Save Changes" y poder realizar actualizaciones del detalle y comentarios posteriormente o pasar del estado CREATED al estado APPLIED en el cual ya no será posible realizar modificaciones.

     ![Detalle de la verificación de entrada](images/material-entry-check-5.png)

4. Cuando hayas finalizado la edición y haz clic en el botón "Confirm Entry", la aplicación mostrará el mensaje "Entry check APPLIED successfully" cuando la petición haya sido procesada.
  
     ![Mensaje de aplicación exitosa](images/material-entry-check-6.png)

## Módulo de Distribución

### Lista de Distribución

Puede crear una distribución para enviar productos provenientes de una bodega a otra bodega o a un punto de venta. La vista principal muestra todos los procesos de distribución creados.

![Lista de distribución](images/distribution-list-1.png)

#### Cómo crear de Lista de Distribución

1. Hacer clic en el botón "Add new" de la vista "Distributions"
2. En el formulario de creación y edición de lista de distribución
   - a. Selecciona la bodega de origen de los materiales (Warehouse)
   - b. Selecciona la persona que recibirá los materiales (Receiver)
   - c. Selecciona el tipo de destino de la salida de materiales (Ship to type)
   - d. Si el tipo de destino es "Wárehouse" entonces deberás selecciona la bodega de destino, si el tipo de destino es "Point Of Sale" entonces deberás seleccionar el Punto de venta de destino.
![Formulario](images/distribution-list-2.png)

3. Agregar lista de materiales de la distrución (detalle)

     ![Detalle de distribución](images/distribution-detail-1.png)

     - a. Haga clic en el botón "Add material" para mostrar el formulario de filtrado y selección de materiales.
     - b. Utilice los filtros para encontrar el material deseado, los filtros disponibles son División, Tipo de material, Subtipo de material y Nombre del material.
     - c. Haga clic en el botón "Search" para mostrar la lista de materiales que corresponden a los parámetros del filtro.
     - d. Haz clic en el botón "Add" de la fila del material que deseas agregar al detalle.
         Nota: La aplicación informará el resultado de la solicitud con una alerta ubicada en la parte superior del formulario de filtrado y selección.
4. Cuando termine de agregar y editar los detalles de la distribución de materiales, se habilitará la opción "Confirm Dispatch" en el botón "Save". En este punto se puede elegir entre guardar el estado actual de la distribución de materiales y su detalle con la opción "Save Changes" y poder realizar actualizaciones del detalle y comentarios posteriormente o pasar del estado CREATED al estado APPLIED en el cual ya no será posible realizar modificaciones.

     ![Detalle de distribución-2](images/distribution-detail-2.png)

5. Cuando hayas finalizado la edición y haz clic en el botón "Confirm Dispatch", la aplicación mostrará el mensaje "Dispatch applied successfully" cuando la petición haya sido procesada.
  
     ![Dispatch confirmed successfully](images/distribution-detail-3.png)

#### Generar Confirmación de recepción de materiales

![Generar verificación de liberación](images/distribution-list-3.png)

## Confirmación de recepción de materiales (Release Check)

A partir de una Lista de Distribución solamente debes hacer clic sobre el botón "Generate Release Check", espere mientras la aplicación procesa la solicitud; cuando finalice, la aplicación mostrará el siguiente mensaje:

![Mensaje](images/release-check-1.png)

Nota: Cuando generas una nueva Verificación de liberación se agrega al inventario de la bodega las cantidades indicadas como "cantidad en tránsito".

![Listado de Verificaciones de Liberación](images/release-check-2.png)

### Detalle de Confirmación de recepción de materiales al receptor autorizado

![Detalle de Confirmación de recepción de materiales](images/release-check-3.png)

### Detalle a receptor no autorizado

Si no eres el receptor, sólo podrás ver la información de la verificación de liberación:

![Detalle de Confirmación de recepción de materiales](images/release-check-4.png)

Cuando termine de agregar y editar los detalles de la distribución de materiales, se habilitará la opción "Confirm Release Dispatch" en el botón "Save". En este punto se puede elegir entre guardar el estado actual de la verificación de liberación de materiales y su detalle con la opción "Save Changes" y poder realizar actualizaciones del detalle y comentarios posteriormente o pasar del estado CREATED al estado APPLIED en el cual ya no será posible realizar modificaciones.
Si selecciona menos de la cantidad esperada y confirma la verificación de liberación, el sistema crea automáticamente una nueva verificación de liberación con los productos y cantidades no recibidos.

![Detalle de Confirmación de recepción de materiales](images/release-check-5.png)

Cuando hayas finalizado la edición y haz clic en el botón "Confirm Release Dispatch", la aplicación mostrará el mensaje "Release check applied successfully" cuando la petición haya sido procesada.

![Mensaje verificación de liberación aplicada](images/release-check-6.png)

## Reports

### Inventory Report

![Inventory report](images/inventory-report.png)

#### Registro de movimientos de entrada

![Inventory report](images/entry-moves-log.png)

#### Registro de movimientos en tránsto

![Inventory report](images/transit-moves-log.png)

#### Registro de movimientos en puntos de venta

![Inventory report](images/pos-moves-log.png)
`
export { playbookmd }