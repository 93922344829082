import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Breadcrumbs
import Breadcrumb from "./breadcrumb/reducer";

// Calendar
import calendar from "./calendar/reducer";

//chat
import Chat from "./chat/reducer";

// Authentication
// import forgetPassword from "./auth/forgetpwd/reducer";
// import login from "./auth/login/reducer";
// import profile from "./auth/profile/reducer";
// import account from "./auth/register/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Breadcrumb,
  calendar,
  Chat
  // forgetPassword,
  // login,
  // profile,
  // account,
});

export default rootReducer;
