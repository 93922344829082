import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import { Container, Row, Col } from 'reactstrap';
import Card from 'react-bootstrap/Card';

import MaterialService from "../../services/material-service";
import WarehouseService from "../../services/warehouse-service";

import { usCurrency, formatDateTime} from '../../util/commons-utilities';

const WarehouseMaterialHistory = () => {
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [product, setProduct] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [movements, setMovements] = useState(null);  

    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Product History",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    usePageTitle("Product History | Warehouses");

    const [query] = useState( new URLSearchParams(useLocation().search));
    const [qpr] = useState(query.get("qpr"));
    const [qwh] = useState(query.get("qwh"));

    const printDetail = () => {
        window.print();
    };

    useEffect(() => {
        showLoader();

        MaterialService.getMaterialById(qpr)
        .then((response) => {
            setProduct(response.data);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });
        
        MaterialService.getReportByWhAndProd(qwh,qpr)
        .then((response) => {
            setQuantity(response.data[0].qty);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });

        WarehouseService.getProductMovesByWarehouse(qwh,qpr)
        .then((response) => {
            setMovements(response.data);
        })
        .catch(err => console.dir(err))
        .finally(() => {
        });


  
    }, [qpr]);

    return (
        <React.Fragment>
            <div key="page-content-pd" className="page-content">
                <div key="page-content-card-header-pd" className="card-header">
                    <h4 key="page-content-card-header-pd-h4" className="card-title mb-0">&nbsp;</h4>
                </div>                
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                    {!!product?
                        (
                            <Card key="card-data-product">
                                <Card.Header>
                                    <h4 className="card-title fw-bolder">{product.name}</h4>
                                </Card.Header>
                                <Card.Body>  
                                    <Row key="card-row-1">
                                        <Col key="card-row-1-col-1">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <h5 className="text-truncate font-size-15">Type: </h5>
                                                    <p className="text-truncate">{product.type.value}</p>                                            
                                                </div>
                                            </div>                                           
                                        </Col>
                                        <Col key="card-row-1-col-2">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <h5 className="text-truncate font-size-15">Subtype: </h5>
                                                    <p className="text-truncate">{product.subtype.value}</p>                                            
                                                </div>
                                            </div>    
                                        </Col>
                                    </Row> 
                                    <Row key="card-row-2">
                                        <Col key="card-row-2-col-1">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <h5 className="text-truncate font-size-15">Group: </h5>
                                                    <p className="text-truncate">{product.group.name}</p>                                            
                                                </div>
                                            </div>                                           
                                        </Col>
                                        <Col key="card-row-2-col-2">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <h5 className="text-truncate font-size-15">Subgroup: </h5>
                                                    <p className="text-truncate">{product.subgroup.name}</p>                                            
                                                </div>
                                            </div>    
                                        </Col>
                                    </Row>  
                                    <Row key="card-row-3">
                                        <Col key="card-row-3-col-1">
                                            <Row key="card-row-3-col-1-row-1">
                                                <Col key="card-row-3-col-1-row-1-col-1">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1">
                                                            <h5 className="text-truncate font-size-15">Unit price: </h5>
                                                            <p className="text-truncate">{usCurrency.format(product.unit_price)}</p>                                            
                                                        </div>
                                                    </div>                                           
                                                </Col>
                                                <Col key="card-row-3-col-1-row-1-col-2">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1">
                                                            <h5 className="text-truncate font-size-15">Stock: </h5>
                                                            <p className="text-truncate">{quantity}</p>  
                                                                     
                                                        </div>
                                                    </div>    
                                                </Col>
                                                <Col key="card-row-3-col-1-row-1-col-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1">
                                                            <h5 className="text-truncate font-size-15">Amount: </h5>
                                                            <p className="text-truncate">{usCurrency.format(quantity * product.unit_price)}</p>                                                                                     
                                                        </div>
                                                    </div>    
                                                </Col>
                                            </Row>
                                        </Col>  
                                        <Col></Col>                                    
                                    </Row>                                                                                              
                                </Card.Body>
                                <Card.Footer className='d-print-none'>
                                    <div className="text-xxl-end">
                                        <button type="button" className="btn btn-light text-body waves-effect" onClick={()=>printDetail()}><i className="fas fa-print"></i>&nbsp;Print</button>
                                    </div>                                        
                                </Card.Footer>                                
                            </Card> 
                        ) : (<></>)
                    }                    
                    </Row>                   
                    <Row key="page-content-container-row-2">
                        <div id="materials-table-container" className="table-responsive">
                            <table id="materials-table" className="table table-hover mb-0">
                                <thead key="materials-table-thead-1">
                                    <tr key="materials-table-thead-1-tr" className="header">
                                        <th className="dark">Warehouse</th>
                                        <th className="dark">Move Date</th>                                 
                                        <th className="dark">Move Type</th>
                                        <th className="dark">Doc. Number</th>
                                        <th className="dark">Prev. Balance</th>
                                        <th className="dark">Quantity</th>
                                        <th className="dark">New Balance</th>
                                        <th className="dark">Unit Price</th>
                                        <th className="dark">Amount</th>
                                    </tr>
                                </thead>
                                <tbody key="materials-table-tbody">
                                {!!product && !!movements && movements.length > 0 ? movements.map((item, key) => (
                                    <tr key={`materials-table-tbody-tr-${key}`}>
                                        <td>{item.warehouse.name}</td>
                                        <td>{formatDateTime(item.move_date)}</td>
                                        <td>{item.move_type}</td>
                                        <td>{item.entry_number_id}</td>
                                        <td>{item.previous_balance}</td>
                                        <td>{item.quantity} <i className={item.move_type=='entry'?'fas fa-arrow-up text-success': 'fas fa-arrow-down text-danger'}></i> </td>
                                        <td>{item.new_balance}</td>
                                        <td>{usCurrency.format(item.unit_price)}</td>
                                        <td>{usCurrency.format(item.quantity * item.unit_price)}</td>
                                    </tr>
                                )):(<tr><td colSpan={8}>No data found.</td></tr>)
                                }
                                </tbody>
                            </table>    
                        </div>  
                    </Row>                        

                    {loader}

                </Container>      
            </div>         
        </React.Fragment>
    );
};

export default WarehouseMaterialHistory;