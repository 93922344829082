import httpCommon from "./http-common";

const MaterialTypesService = {
    getMaterialTypes() {
        let data = httpCommon.get("/mdm/materialtype")
        return data;
    },
    saveNewMaterialType(data) {
        let response = httpCommon.post("/mdm/materialtype",JSON.stringify(data))
        return response;
    },
    updateMaterialType(data) {
        let response = httpCommon.put(`/mdm/materialtype/${data.id}`,JSON.stringify(data))
        return response;
    },
    getMaterialSubtypes() {
        let data = httpCommon.get("/mdm/materialsubtype")
        return data;
    },
    saveNewMaterialSubtype(data) {
        let response = httpCommon.post("/mdm/materialsubtype",JSON.stringify(data))
        return response;
    },
    updateMaterialSubtype(data) {
        let response = httpCommon.put(`/mdm/materialsubtype/${data.id}`,JSON.stringify(data))
        return response;
    }
};

export default MaterialTypesService;