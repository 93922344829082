import React from "react";
import { Redirect } from "react-router-dom";

//Catalogs
import MdmManagement from "../pages/Mdm/mdm-management";
import MaterialTypesManagement from "../pages/Mdm/material-types-management";
import MaterialSubtypesManagement from "../pages/Mdm/material-subtypes-management";
import MaterialDivisionsManagement from "../pages/Mdm/material-division";
import MaterialPic from "../pages/Mdm/material-pic";
import MaterialGroupsManagement from "../pages/Mdm/material-group";
import MaterialSubgroupsManagement from "../pages/Mdm/material-subgroup";

// Users
import UserGrid from "../pages/Users/users-grid";
import UserList from "../pages/Users/user-list";
import UserProfile from "../pages/Users/user-profile";

//Materials
import MaterialsManagement from "../pages/Materials/materials-management";
import MaterialDetail from "../pages/Materials/material-detail";

//Warehouses
import WarehousesManagement from "../pages/Warehouse/warehouse-management";
import WarehousesList from "../pages/Warehouse/warehouse-list";
import WarehouseDetail from "../pages/Warehouse/warehouse-detail";
import WarehouseMaterialHistory from "../pages/Warehouse/warehouse-material-history";

// Auth
import AuthLogin from "../pages/Auth/login";
import Register from "../pages/Auth/Register";
import RecoverPassword from "../pages/Auth/RecoverPassword";
import LockScreen from "../pages/Auth/LockScreen";
import Logout from "../pages/Auth/Logout";
import ConfirmMail from "../pages/Auth/ConfirmMail";
import EmailVerification from "../pages/Auth/EmailVerification";
import TwoStepVerification from "../pages/Auth/TwoStepVerification";

// Utility
import Error404 from "../pages/Utility/pages-404";
import Error500 from "../pages/Utility/pages-500";

//Authentication
import Login from "../pages/Auth/login";
import PosManagement from "../pages/Pos/pos-management";

import MaterialEntry from "../pages/Entry/material-entries";
import MaterialEntryDetail from "../pages/Entry/material-entry-detail";

//entry check
import MaterialEntriesCheck from "../pages/Entrycheck/material-entries-check";
import MaterialEntryCheckDetail from "../pages/Entrycheck/material-entry-check-detail";

//reports
import InventoryReport from "../pages/Reports/inventory-report";
import InventoryMoves from "../pages/Reports/inventory-moves";
import EntryMovesLog from "../pages/Reports/entry-moves-log";
import TransitMovesLog from "../pages/Reports/transit-moves-log";
import PosMovesLog from "../pages/Reports/pos-moves-log";
import DistributionManagement from "../pages/Distribution/distribution-management";
import DistributionDetail from "../pages/Distribution/distribution-detail";
import MaterialDistributionCheck from "../pages/Distribution/distribution-check/material-distribution-check";
import DistributionCheckDetail from "../pages/Distribution/distribution-check-detail/distribution-check-detail";

import Playbook from "../playbook/playbook";
import Pdfviewer from "../playbook/pdfviewer";

const authProtectedRoutes = [
  // Contacts
  { path: "/users-grid", component: UserGrid },
  { path: "/user-list", component: UserList },
  { path: "/user-profile", component: UserProfile },

  { path: "/mdm", component: MdmManagement },
  { path: "/types", component: MaterialTypesManagement },
  { path: "/subtypes", component: MaterialSubtypesManagement },
  { path: "/divisions", component: MaterialDivisionsManagement },
  { path: "/peopleincharge", component: MaterialPic },
  { path: "/groups", component: MaterialGroupsManagement },
  { path: "/subgroups", component: MaterialSubgroupsManagement },

  //Materials
  { path: "/materials", component: MaterialsManagement },
  { path: "/material-detail", component: MaterialDetail },

  //Entry
  { path: "/material-entry", component: MaterialEntry },
  { path: "/material-entry-detail", component: MaterialEntryDetail },

  //Warehouses
  { path: "/warehouses", component: WarehousesManagement },
  { path: "/warehouse-list", component: WarehousesList },
  { path: "/warehouse-detail", component: WarehouseDetail },
  { path: "/warehouse-product-history", component: WarehouseMaterialHistory },

  //entry check
  { path: "/entry-check", component: MaterialEntriesCheck },
  { path: "/entry-check-detail", component: MaterialEntryCheckDetail },

  //pos
  { path: "/pos", component: PosManagement },

  //distribution
  { path: "/distribution", component: DistributionManagement },
  { path: "/distribution-detail", component: DistributionDetail },
  { path: "/distribution-check", component: MaterialDistributionCheck },

  { path: "/distribution-check-detail", component: DistributionCheckDetail },

  //reports
  {path:"/inventory-report", component:InventoryReport},
  {path:"/inventory-moves", component:InventoryMoves},
  {path:"/entry-moves-log",component:EntryMovesLog},
  {path: "/transit-moves-log",component:TransitMovesLog},
  {path:"/pos-moves-log", component:PosMovesLog},

  //Help and docs
  {path:"/playbook", component:Playbook},
  {path:"/pdfviewer", component:Pdfviewer},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/" /> },
];

const publicRoutes = [
  //login
  { path: "/login", component: Login },

  // Utility
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },

  // Auth
  { path: "/auth-login", component: AuthLogin },
  { path: "/auth-register", component: Register },
  { path: "/auth-recoverpw", component: RecoverPassword },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-logout", component: Logout },
  { path: "/auth-confirm-mail", component: ConfirmMail },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-two-step-verification", component: TwoStepVerification },
];

export { authProtectedRoutes, publicRoutes };
