import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import { Container, Row, Col, Modal } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from 'react-bootstrap/Alert';
import MaterialEntryCheckItem from './material-entry-check-item';
import EntryCheckService from '../../services/entry-check-service';
import { hasValue, tryParseJson } from '../../util/commons-utilities';


const MaterialEntryCheckDetail = () => {
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState(null);
    const [materials, setMaterials] = useState([]);
    const [message, setMessage] = useState('');
    const [modalSuccess, setModalSuccess] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isSavingAllowed, setIsSavingAllowed] = useState(true);
    const [toggleSavePermission, setToggleSavePermission] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const [comments, setComments] = useState('');
    
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);  

    const [itemsPendingSaving, setItemsPendingSaving] = useState(0);
    const [itemsPendingConfirmation, setItemsPendingConfirmation] = useState(0);
  
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Entry Check Detail",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    usePageTitle("Entry Detail | Warehouses");

    const [query] = useState( new URLSearchParams(useLocation().search));
    const [ckid] = useState(query.get("ckid"));

    function tog_success() {
        setModalSuccess(!modalSuccess);
    }

    function showMessage(data){
        setAlertType(data.type);
        setAlertTitle(data.title);
        setAlertMessage(data.message);
        setShowAlert(true);
        setTimeout(()=>{setShowAlert(false)},5000);
    }

    function handleError(err){
        console.dir(err);
        let response = tryParseJson(err);
        showMessage({ type: 'danger', title: 'Error', message: response.valid===true? response.value.detail : response.value });
        hideLoader();
    }

    function save_entry() {
        showLoader();
        let entry = {
            id: data.id,
            entry: data.entry.id,
            warehouse: data.entry.warehouse.id,
            comments: comments,
            date: data.date,
            status: data.status
        };

        EntryCheckService.updateEntryCheck(entry)
        .then((response) => {         
            setMessage('Entry updated successfully');
            setModalSuccess(true);
            setTimeout(()=>{
                setModalSuccess(false);
            }, 2000);
        })
        .catch(err => handleError(err))
        .finally(() => hideLoader())
    }

    function apply_entry(){
        showLoader();
        let entry = {
            id: data.id,
            entry: data.entry.id,
            warehouse: data.entry.warehouse.id,
            comments: comments,
            date: data.date,
            status: 'APPLIED'
        };
        EntryCheckService.applyEntry(entry)
        .then((response) => {             
            setMessage('Entry check APPLIED successfully');
            setData(null);
            setMaterials([]);
            setIsUpdated(true);   
            setModalSuccess(true);
            setTimeout(()=>{
                setModalSuccess(false);
            }, 2000);
        })
        .catch(err => handleError(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });
    }

    function refreshData(me_response, material_in){
        let currentMaterials = materials;
        me_response.material = material_in;
        currentMaterials.push(me_response);       
        setMaterials(currentMaterials);
        summarize(currentMaterials);
    }
    
    const summarize = function (data){
        let sumAmount = 0.0;
        let sumQuantity = 0;
        if(hasValue(data)){
            data.forEach(function(item) {
              sumAmount += item.quantity *  item.unit_price;
              sumQuantity += item.material.quantity;
            });
        }
        setTotalAmount(sumAmount);
        setTotalQuantity(sumQuantity);
    }

    const printEntry = () => {
        window.print();
    };

    useEffect(()=>{
        let saveButtons = document.querySelectorAll('.btn-item-save');
        let checkElements = [...document.querySelectorAll('input[id*=-checked]')];
        let count = 0;
        let checkedCount = 0;
        saveButtons.forEach(btn=>{ if(btn.style.display==='block'){ count++; } });
        checkElements.forEach(elem =>{ if(elem.checked === true){ checkedCount++; }});
        setIsSavingAllowed(count===0 && checkedCount === checkElements.length);
        setItemsPendingSaving(count);
        setItemsPendingConfirmation(checkElements.length - checkedCount);
    },[toggleSavePermission])

    useEffect(() => {
        showLoader();
        EntryCheckService.getEntryCheckById(ckid)
        .then((response) => {
            setData(response.data);
            setComments(response.data.comments);
            setIsReadOnly(response.data.status==='APPLIED');

        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });        

        EntryCheckService.getMaterialsByEntryCheckId(ckid)
        .then((response) => {
            setMaterials(response.data);            
        })
        .catch(err => console.dir(err))
        .finally(() => {
            setTimeout(()=>{
                let checkElements = [...document.querySelectorAll('input[id*=-checked]')];
                let checkedCount = 0;
                checkElements.forEach(elem =>{ if(elem.checked === true){ checkedCount++; }});
                setItemsPendingConfirmation(checkElements.length - checkedCount);
            }, 500);
        });

    }, [ckid,isUpdated]);

    return (
        <React.Fragment>
            <div key="page-content-pd" className="page-content">
                <div key="page-content-card-header-pd" className="card-header">
                    <h4 key="page-content-card-header-pd-h4" className="card-title mb-0">&nbsp;</h4>
                </div>                
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                    {!!data?
                        (
                            <Card key="card-data-project">
                                <Card.Body>
                                    <Row key="card-row-1">
                                        <Col key='card-row-1-col-1'>
                                            <Row key='card-row-1-col-1-row-1'>
                                                <Col key='card-row-1-col-1-row-1-col-1'>
                                                    <div key='card-row-1-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-1-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-1-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Date: </h5>
                                                            <p key='card-row-1-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.date}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col key='card-row-1-col-1-row-1-col-2'>
                                                    <div key='card-row-1-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-1-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-1-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Status: </h5>
                                                            <p key='card-row-1-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{(data.status??'').toUpperCase()}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>                                                
                                            </Row>
                                        </Col>
                                    </Row>     
                                    <Row key="card-row-2">
                                        <Col key='card-row-2-col-1'>
                                            <Row key='card-row-2-col-1-row-1'>
                                                <Col key='card-row-2-col-1-row-1-col-1'>
                                                    <div key='card-row-2-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-2-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-2-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Country: </h5>
                                                            <p key='card-row-2-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.entry.warehouse.country.name}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col key='card-row-2-col-2'>
                                            <Row key='card-row-2-col-2-row-1'>
                                                <Col key='card-row-2-col-2-row-1-col-1'>
                                                    <div key='card-row-2-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-2-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-2-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Warehouse: </h5>
                                                            <p key='card-row-2-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.entry.warehouse.name}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row key="card-row-3">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label font-size-15" htmlFor="entryComments">Comments:</label>
                                                {
                                                    !isReadOnly?(
                                                                <textarea id="entryComments" name="entryComments" className="form-control" value={comments} onChange={(e) => setComments(e.target.value)} maxLength={255} disabled={isReadOnly}></textarea>
                                                    ):(<><p>{comments}</p></>)
                                                }
                                            </div>
                                        </div> 
                                    </Row>                           
                                </Card.Body>
                                <Card.Footer className='d-print-none'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="d-flex align-items-start">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted mb-1"><b>Items pending saving: </b> {itemsPendingSaving}</p>
                                                    <p className="text-muted mb-1"><b>Items pending confirmation: </b> {itemsPendingConfirmation}</p>
                                                </div>
                                            </div> 
                                        </div>
                                        <div className='col-6'>
                                            <div className="text-xxl-end">
                                                {
                                                    !isReadOnly?(
                                                        <DropdownButton variant='secondary' title="&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;">
                                                            <Dropdown.Item eventKey="1" onClick={()=> save_entry()}><i className="fas fa-save"></i> Save Changes</Dropdown.Item>
                                                            {isSavingAllowed && <Dropdown.Item eventKey="2" onClick={()=> apply_entry()}><i className="fas fa-check"></i> Confirm Entry</Dropdown.Item>}
                                                        </DropdownButton>
                                                    )
                                                    :
                                                    (<button type="button" className="btn btn-light text-body waves-effect" onClick={()=>printEntry()}><i className="fas fa-print"></i>&nbsp;Print</button>)
                                                }
                                            </div> 
                                        </div>
                                    </div>  
                                    {
                                        showAlert?(
                                            <div className='row'>
                                                <Alert key="custom-alert" variant={alertType} onClose={() => setShowAlert(false)} dismissible>
                                                    <Alert.Heading>{alertTitle}</Alert.Heading>
                                                    <p>{alertMessage}</p>
                                                </Alert>  
                                            </div>
                                        ):(<></>)
                                    }                                          
                                </Card.Footer>                                
                            </Card> 
                        ) : (<></>)
                    }                    
                    </Row>
                                      
                    <Row key="page-content-container-row-3">
                        <div key="materials-table-container" id="materials-table-container" className="table-responsive">
                            <table key="materials-table" id="materials-table" className="table table-hover mb-0">
                                <thead key="materials-table-thead-0">
                                    <tr key="materials-table-thead-tr-0" className="header">                                        
                                        <th className="dark text-center" colSpan={14}>MATERIAL DETAILS</th>
                                    </tr>
                                    <tr key="materials-table-thead-tr-1" className="header"> 
                                        <th className="dark">Check</th>                                       
                                        <th className="dark">Name</th>
                                        <th className="dark">Expected Qty</th>
                                        <th className="dark">Unit Price</th>
                                        <th className="dark">Expected Amount</th>
                                        <th className="dark">Received Qty</th>
                                        <th className="dark">Difference</th>
                                        <th className="dark">Received Amount</th>
                                        <th className="dark">Entry Date</th>
                                        <th className="dark">Section in W.</th>
                                        <th className="dark">Notes</th>
                                        <th className="dark">Mat. Photo</th>
                                        <th className="dark">Photos</th>
                                        <th className="dark">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody key="materials-table-tbody">
                                {!!data && !!materials && materials.length > 0 ? materials.map((item, key) => (
                                    <MaterialEntryCheckItem 
                                    key={`tr-entry-check-item-${key}`} 
                                    keyRow={key} 
                                    entryCheckItem={item} 
                                    status={(data.status??'')}
                                    toggleSavePermission={toggleSavePermission}
                                    setToggleSavePermission={setToggleSavePermission}></MaterialEntryCheckItem>  
                                )):(<tr><td colSpan={8}>No data found.</td></tr>)
                                }
                                </tbody>                                                          
                            </table>    
                        </div>  
                    </Row>                        

                    {loader}

                </Container>                

                {/* Success Modal */}
                <Modal
                    isOpen={modalSuccess}
                    toggle={() => {
                        tog_success();
                    }}
                    id="success-btn"
                    centered
                >
                    <div className="modal-body">
                        <div className="text-center">
                            <i className="bx bx-check-circle display-1 text-success"></i>
                            <p className="mt-3">{message}</p>
                        </div>
                    </div>
                </Modal>        
            </div>         
        </React.Fragment>
    );
};

export default MaterialEntryCheckDetail;