import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import usePageTitle from "../../../util/head-element";
import useFullPageLoader from "../../../Hooks/useFullPageLoader";
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../store/actions";

import { Container, Row, Col, Modal } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import MaterialDistributionCheckItem from '../material-distribution-check-item/material-distribution-check-item';
import DispatchCheckSeDispatch from '../../../services/dispatch-check-service';
import { getMaterials, hasValue } from '../../../util/commons-utilities';
import DispatchCheckService from '../../../services/dispatch-check-service';
import { UserContext } from '../../../context/userContext';


const DistributionCheckDetail = () => {
    const {appData} = useContext(UserContext)
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState(null);
    const [materials, setMaterials] = useState([]);
    const [message, setMessage] = useState('');
    const [modalSuccess, setModalSuccess] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [comments, setComments] = useState('');
    const [allowConfirm, setAllowConfirm] = useState(false);
    
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);


    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Release Check Detail",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    usePageTitle("Release Detail | Warehouses");

    const [query] = useState( new URLSearchParams(useLocation().search));
    const [ckid] = useState(query.get("ckid"));

    function tog_success() {
        setModalSuccess(!modalSuccess);
    }

    function save_dispath() {
        showLoader();
        let dispatch = {
            id: data.id,
            dispatch: data.dispatch.id,
            comments: comments,
            date: data.date,
            status: data.status
        };

        DispatchCheckSeDispatch.updateDispatchCheck(dispatch)
        .then((response) => {         
            setMessage('Release updated successfully');
            setModalSuccess(true);
            setTimeout(()=>{
                setModalSuccess(false);
            }, 2000);
        })
        .catch(err => console.dir(err))
        .finally(() => hideLoader())
    }

    function apply_dispatch(){
        showLoader();
        let dispatch = {
            id: data.id,
            dispatch: data.dispatch.id,
            comments: comments,
            date: data.date,
            status: 'APPLIED'
        };
        DispatchCheckSeDispatch.applyDispatch(dispatch)
        .then((response) => {             
            setMessage('Release check APPLIED successfully');
            setData(null);
            setMaterials([]);
            setIsUpdated(true);   
            setModalSuccess(true);
            setTimeout(()=>{
                setModalSuccess(false);
            }, 2000);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });
    }

    function refreshData(me_response, material_in){
        let currentMaterials = materials;
        me_response.material = material_in;
        currentMaterials.push(me_response);       
        setMaterials(currentMaterials);
        summarize(currentMaterials);
    }
    
    const summarize = function (data){
        let sumAmount = 0.0;
        let sumQuantity = 0;
        if(hasValue(data)){
            data.forEach(function(item) {
              sumAmount += item.quantity *  item.unit_price;
              sumQuantity += item.material.quantity;
            });
        }
        setTotalAmount(sumAmount);
        setTotalQuantity(sumQuantity);
        setAllowConfirm(sumQuantity>0?true:false);
    }

    const printRelease = () => {
        window.print();
    };
    const calculateTotalQuantity = (data) => {
        let totalQty = 0;
        data.forEach((item) => {
            if(parseInt(item.received_qty))
                totalQty+=item.received_qty;
        })
        setAllowConfirm(totalQty>0?true:false);
        setTotalQuantity(totalQty)
    };


    useEffect(() => {
        showLoader();
        DispatchCheckService.getDispatchCheckById(ckid)
        .then((response) => {
            setData(response.data);
            setComments(response.data?.dispatch?.comments);
            setIsReadOnly(response.data.status==='APPLIED' || response.data.dispatch?.receiver.id !== appData.userInfo.id);

        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });        

        DispatchCheckService.getMaterialsByDispatchCheckId(ckid)
        .then((response) => {
            calculateTotalQuantity(response.data);
            setMaterials(response.data);            
        })
        .catch(err => console.dir(err))
        .finally(() => {
        });

    }, [ckid,isUpdated]);

    // useEffect(() => {
    //     //allow confirm dispatch if one meterial has received at less one
    //     console.log("UseEffect materials",materials); 
    //     if(totalQuantity>0 ){
    //         materials.forEach((material) => {
    //             if(material.received_qty>0){
    //                 setAllowConfirm(true);
    //                 return;
    //             }
    //         });
    //     }
    // },[totalQuantity]);

    return (
        <React.Fragment>
            <div key="page-content-pd" className="page-content">
                <div key="page-content-card-header-pd" className="card-header">
                    <h4 key="page-content-card-header-pd-h4" className="card-title mb-0">&nbsp;</h4>
                </div>                
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                    {!!data?
                        (
                            <Card key="card-data-project">
                                <Card.Body>
                                    <Row key="card-row-1">
                                        <Col key='card-row-1-col-1'>
                                            <Row key='card-row-1-col-1-row-1'>
                                                <Col key='card-row-1-col-1-row-1-col-1'>
                                                    <div key='card-row-1-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-1-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-1-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Date: </h5>
                                                            <p key='card-row-1-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.date}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col key='card-row-1-col-1-row-1-col-2'>
                                                    <div key='card-row-1-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-1-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-1-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Status: </h5>
                                                            <p key='card-row-1-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{(data.status??'').toUpperCase()}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>                                                
                                            </Row>
                                        </Col>
                                    </Row>     
                                    <Row key="card-row-2">
                                        <Col key='card-row-2-col-1'>
                                            <Row key='card-row-2-col-1-row-1'>
                                                <Col key='card-row-2-col-1-row-1-col-1'>
                                                    <div key='card-row-2-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-2-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-2-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Ship Type: </h5>
                                                            <p key='card-row-2-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.dispatch?.ship_to_type==="POS"?"Point Of Sale":"Warehouse"}</p>                                           

                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col key='card-row-2-col-2'>
                                            <Row key='card-row-2-col-2-row-1'>
                                                <Col key='card-row-2-col-2-row-1-col-1'>
                                                    <div key='card-row-2-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-2-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-2-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Origen  Warehouse: </h5>
                                                            <p key='card-row-2-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.dispatch?.warehouse?.name}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row key="card-row-31">
                                    <Col key='card-row-2-col-2-row-1-col-1'>
                                                    <div key='card-row-2-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-2-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-2-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Destination {data.dispatch?.ship_to_type==="POS"?"Point of Sale":"Warehouse"}: </h5>
                                                            <p key='card-row-2-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.dispatch?.ship_to_type==="POS"?data.dispatch?.ship_to_pos?.mso_name:data.dispatch?.ship_to_warehouse?.name}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                    </Row> 
                                    <Row key="card-row-54">
                                        <Col key='card-row-4-col-2'>
                                                <Row key='card-row-4-col-2-row-1'>
                                                    <Col key='card-row-4-col-2-row-1-col-1'>
                                                        <div key='card-row-4-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-4-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-4-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Receiver: </h5>
                                                                <p key='card-row-4-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.dispatch.receiver?.given_name}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>   
                                    <Row key="card-row-3">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label font-size-15" htmlFor="entryComments">Comments:</label>
                                                {
                                                    !isReadOnly?(
                                                                <textarea id="entryComments" name="entryComments" className="form-control" value={comments} onChange={(e) => setComments(e.target.value)} maxLength={255} disabled={isReadOnly}></textarea>
                                                    ):(<><p>{comments}</p></>)
                                                }
                                            </div>
                                        </div> 
                                    </Row>                           
                                </Card.Body>
                                <Card.Footer className='d-print-none'>
                                    <div className="text-xxl-end">
                                        {
                                            !isReadOnly?(
                                                <DropdownButton variant='secondary' title="&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;">
                                                    <Dropdown.Item eventKey="1" onClick={()=> save_dispath()}><i className="fas fa-save"></i> Save Changes</Dropdown.Item>
                                                    <Dropdown.Item eventKey="2" style={{display:allowConfirm?"block":"none"}} onClick={()=> apply_dispatch()}><i className="fas fa-check"></i> Confirm Release Dispatch</Dropdown.Item>
                                                </DropdownButton>
                                            )
                                            :
                                            (<button type="button" className="btn btn-light text-body waves-effect" onClick={()=>printRelease()}><i className="fas fa-print"></i>&nbsp;Print</button>)
                                        }
                                    </div>                                        
                                </Card.Footer>                                
                            </Card> 
                        ) : (<></>)
                    }                    
                    </Row>
                                      
                    <Row key="page-content-container-row-3">
                        <div id="materials-table-container" className="table-responsive">
                            <table id="materials-table" className="table table-hover mb-0">
                                <thead key="materials-table-thead-0">
                                    <tr key="materials-table-thead-tr-0" className="header">                                        
                                        <th className="dark text-center" colSpan={14}>MATERIAL DETAILS</th>
                                    </tr>
                                    <tr key="materials-table-thead-tr-1" className="header"> 
                                        <th className="dark">Check</th>                                       
                                        <th className="dark">Name</th>
                                        <th className="dark">Expected Qty</th>
                                        {/* <th className="dark">Unit Price</th> */}
                                        {/* <th className="dark">Expected Amount</th> */}
                                        <th className="dark">Received Qty</th>
                                        <th className="dark">Difference</th>
                                        {/* <th className="dark">Received Amount</th> */}
                                        <th className="dark">Registration Date</th>
                                        <th className="dark">Section in W.</th>
                                        <th className="dark">Notes</th>
                                        <th className="dark">Mat. Photo</th>
                                        <th className="dark">Photos</th>
                                        <th className="dark">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody key="materials-table-tbody">
                                {!!data && !!materials && materials.length > 0 ? materials.map((item, key) => (
                                    <MaterialDistributionCheckItem key={key} keyRow={key} entryCheckItem={item} status={(data.status??'')} isReadOnlyD={isReadOnly}
                                    setAllowConfirm={setAllowConfirm} setTotalQuantity={setTotalQuantity} totalQuantity={totalQuantity}></MaterialDistributionCheckItem>  
                                )):(<tr><td colSpan={8}>No data found.</td></tr>)
                                }
                                </tbody>
                                                          
                            </table>    
                        </div>  
                    </Row>                        

                    {loader}

                </Container>                

                {/* Success Modal */}
                <Modal
                    isOpen={modalSuccess}
                    toggle={() => {
                        tog_success();
                    }}
                    id="success-btn"
                    centered
                >
                    <div className="modal-body">
                        <div className="text-center">
                            <i className="bx bx-check-circle display-1 text-success"></i>
                            <p className="mt-3">{message}</p>
                        </div>
                    </div>
                </Modal>        
            </div>         
        </React.Fragment>
    );
};

export default DistributionCheckDetail;