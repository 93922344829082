import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router-dom";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import { Container, Row, Col, Modal } from 'reactstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';

import DivisionService from "../../services/division-service";
import MaterialTypesService from '../../services/material-types-service';
import DispatchService from '../../services/dispatch-service';
import { getMaterials, hasValue, resetAllInputRadios, validateExistInArrayById } from '../../util/commons-utilities';

import DistributionDetailItem from './distribution-detail-item';
import WarehouseService from '../../services/warehouse-service';
import styles from './distribution-detail.scss';

const DistributionDetail = () => {
    const dispatch = useDispatch();
    const [query] = useState(new URLSearchParams(useLocation().search));
    const [did] = useState(query.get("did"));
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [data, setData] = useState(null);
    const [comments, setComments] = useState('');
    const [materials, setMaterials] = useState(null);

    const [dispatchID, setDispatchID] = useState(null);
    const [materialName, setMaterialName] = useState('');
    const [selecting, setSelecting] = useState(false);
    const [saving, setSaving] = useState(false);

    const [types, setTypes] = useState(0);
    const [subtypes, setSubtypes] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [allMaterials, setAllMaterials] = useState(null);
    const [filteredMaterials, setFilteredMaterials] = useState(null);
    const [division, setDivision] = useState('');
    const [type, setType] = useState(0);
    const [subtype, setSubtype] = useState(0);
    const [filteredSubtypes, setFilteredSubtypes] = useState([]);

    const [message, setMessage] = useState('');
    const [modalSuccess, setModalSuccess] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [allowConfirm, setAllowConfirm] = useState(false);

    const inputRef = useRef(null);
    const focus = () => {
        inputRef.current.focus();
    };

    usePageTitle("Distribution Detail | Distribution");
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Distribution Detail",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    function handleDivisionChange(value) {
        let objdiv = divisions.find(d => {
            return parseInt(d.id) === parseInt(value);
        });
        if (objdiv)
            setDivision(objdiv);
        else setDivision('');
    }

    function handleTypeChange(value) {
        setType(value);
        let result = subtypes.filter(st => { return parseInt(st.type.id) === parseInt(value) });
        setFilteredSubtypes(result);
    }

    function tog_success() {
        setModalSuccess(!modalSuccess);
    }


    const summarize = function (data) {
        let sumAmount = 0.0;
        let sumQuantity = 0;
        if (hasValue(data)) {
            data.forEach(function (item) {
                sumAmount += item.quantity * item.unit_price;
                sumQuantity += item.quantity;
            });
        }
        setTotalAmount(sumAmount);
        setTotalQuantity(sumQuantity);
        setAllowConfirm(sumQuantity>0?true:false);
    }

    const printDispatch = () => {
        window.print();
    };
    function save_dispatch() {

        showLoader();
        let dispatch = {
            id: data.id,
            warehouse: data.warehouse.id,
            ship_to_type: data.ship_to_type,
            ship_to_warehouse: data.ship_to_warehouse?.id,
            ship_to_pos: data.ship_to_pos?.id,
            ship_date: data.ship_date,
            total_amount: totalAmount,
            comments: comments,
            status: data.status
        };

        DispatchService.updateDispatch(dispatch)
            .then((response) => {
                setMessage('Dispatch updated successfully');
                setModalSuccess(true);
                setTimeout(() => {
                    setModalSuccess(false);
                }, 2000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader())

    }

    function confirm_dispatch() {
        showLoader();
        let dispatch = {
            id: data.id,
            warehouse: data.warehouse.id,
            ship_to_type: data.ship_to_type,
            ship_to_warehouse: hasValue(data.ship_to_warehouse) ? data.ship_to_warehouse?.id : null,
            ship_to_pos: hasValue(data.ship_to_pos) ? data.ship_to_pos?.id : null,
            ship_date: data.ship_date,
            comments: comments,
            status: 'CONFIRMED'
        };
        DispatchService.confirmDispatch(dispatch)
            .then((response) => {
                setMessage('Dispatch confirmed successfully');
                setData(null);
                setMaterials([]);
                setIsUpdated(true);
                setModalSuccess(true);
                setTimeout(() => {
                    setModalSuccess(false);
                }, 2000);
            })
            .catch(err => console.dir(err))
            .finally(() => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                hideLoader();
            });
    }

    function filterMaterials() {
        if (!selecting) {
            const result = allMaterials?.filter(item =>
                item.status === "CREATED"
                && (!hasValue(division) || division === "" || item.division.id === parseInt(division?.id))
                && (!hasValue(type) || parseInt(type) === 0 || item.type.id === parseInt(type))
                && (!hasValue(subtype) || parseInt(subtype) === 0 || item.subtype.id === parseInt(subtype))
                && item.name.toUpperCase().includes(materialName.toUpperCase())
            );
            setFilteredMaterials(result);
        }
        else {
            setFilteredMaterials(null);
        }
    }


    function resetForm() {
        let inputSearch = document.querySelector("#inputgroup-formcontrol-search");
        inputSearch.value = '';
        setFilteredMaterials(allMaterials);
        setDivision(undefined);
        setType(0);
        setSubtype(0);
        setMaterialName('');
        resetAllInputRadios();
    }

    function showMessage(data) {
        setAlertType(data.type);
        setAlertTitle(data.title);
        setAlertMessage(data.message);
        setShow(true);
        setTimeout(() => { setShow(false) }, 2000);
    }

    function saveMaterialToDispatch(item) {
        if (!saving && hasValue(item)) {
            //validate if product is part of the list
            const exist = validateExistInArrayById(item, materials)
            if (!exist) {
                let data = {
                    dispatch: dispatchID,
                    material: item.id,
                    quantity: 0,//item.quantity,
                    unit_price: item.unit_price
                };
                setSaving(true);
                DispatchService.saveMaterialToDispatch(data)
                    .then((response) => {
                        refreshData(response.data, item);
                        showMessage({ type: 'success', title: 'Success', message: 'Product successfully added to list.' });
                        resetForm();
                    })
                    .catch((err) => {
                        console.dir(err);
                        showMessage({ type: 'danger', title: 'Error', message: err.request.responseText });
                    })
                    .finally(() => {
                        setSaving(false);
                    })
            } else {
                showMessage({ type: 'danger', title: 'Alert', message: 'Product Exist in list.' });
            }

        }
    }

    function refreshData(d_response, material_in) {
        let currentMaterials = materials;
        d_response.material = material_in;
        currentMaterials.push(d_response);
        setMaterials(currentMaterials);
        summarize(currentMaterials);
    }

    const deleteMaterialToDispatch = async (item) => {
        setSaving(true);
        await DispatchService.deleteMaterialToDispatch(item.id)
            .then((response) => {
                removeData(item);
                showMessage({ type: 'success', title: 'Success', message: 'Product successfully deleted to list.' });
                resetForm();
            })
            .catch((err) => {
                console.dir(err);
                showMessage({ type: 'danger', title: 'Error', message: err.request.responseText });
            })
            .finally(() => {
                setSaving(false);
            })


    }
    function removeData(itemR) {
        const currentMaterials = materials.filter((item) => item.id !== itemR.id);
        setMaterials(currentMaterials);
        summarize(currentMaterials);
    }

    function selectMaterial(item) {
        //setSelecting(true);
        //setFilteredMaterials(null);
        //setSelecting(false);

        saveMaterialToDispatch(item);
    }


    function handleKeyUp(event) {
        if (event.key === 'Enter') {
            saveMaterialToDispatch();
        }
    }

    useEffect(() => {
        showLoader();
        setDispatchID(did);
        DispatchService.getDispatchById(did)
            .then((response) => {
                setData(response.data);
                setComments(response.data.comments);
                setIsReadOnly(response.data.status === 'CONFIRMED' || response.data.status === 'APPLIED');
            })
            .catch(err => console.dir(err))
            .finally(() => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                hideLoader();
            });


    }, [did, isUpdated]);

    useEffect(() => {
        if (data === null) return;
        DispatchService.getMaterialsByDispatchId(did)
            .then((response) => {
                setMaterials(response.data);
                summarize(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });
        WarehouseService.getMaterialsByWarehouse(data?.warehouse?.id)
            .then((response) => {
                const materials = getMaterials(response.data);
                setAllMaterials(materials);
                setFilteredMaterials(materials.sort((a, b) => a.name > b.name ? 1 : -1).slice(0, 10));
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });

        DivisionService.getDivisions()
            .then((response) => {
                setDivisions(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });

        MaterialTypesService.getMaterialTypes()
            .then((response) => {
                setTypes(response.data);
            })
            .catch(err => console.dir(err))

        MaterialTypesService.getMaterialSubtypes()
            .then((response) => {
                setSubtypes(response.data);
            })
            .catch(err => console.dir(err))


    }, [data, isUpdated]);

    return (
        <React.Fragment>
            <div key="page-content-pd" className="page-content">
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                        {!!data ?
                            (
                                <Card>
                                    <Card.Header>
                                        <h4 className="card-title fw-bolder">Distribution To {data.warehouse.name}</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row key="card-row-2">
                                            <Col key='card-row-2-col-1'>
                                                <Row key='card-row-2-col-1-row-1'>
                                                    <Col key='card-row-2-col-1-row-1-col-1'>
                                                        <div key='card-row-2-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-2-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-2-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Ship date: </h5>
                                                                <p key='card-row-2-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.ship_date}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col key='card-row-2-col-1-row-1-col-2'>
                                                        <div key='card-row-2-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-2-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-2-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Status: </h5>
                                                                <p key='card-row-2-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{(data.status ?? '').toUpperCase()}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row key="card-row-3">
                                            <Col key='card-row-3-col-1'>
                                                <Row key='card-row-3-col-1-row-1'>
                                                    <Col key='card-row-3-col-1-row-1-col-1'>
                                                        <div key='card-row-3-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-3-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-3-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Country: </h5>
                                                                <p key='card-row-3-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.warehouse.country.name}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col key='card-row-3-col-2'>
                                                <Row key='card-row-3-col-2-row-1'>
                                                    <Col key='card-row-3-col-2-row-1-col-1'>
                                                        <div key='card-row-3-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-3-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-3-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Warehouse: </h5>
                                                                <p key='card-row-3-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.warehouse.name}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row key="card-row-4">
                                            <Col key='card-row-4-col-1'>
                                                <Row key='card-row-4-col-1-row-1'>
                                                    <Col key='card-row-4-col-1-row-1-col-1'>
                                                        <div key='card-row-4-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-4-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-4-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Ship to type: </h5>
                                                                <p key='card-row-4-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.ship_to_type}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col key='card-row-4-col-2'>
                                                <Row key='card-row-4-col-2-row-1'>
                                                    <Col key='card-row-4-col-2-row-1-col-1'>
                                                        <div key='card-row-4-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-4-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-4-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">{data.ship_to_type === "WAREHOUSE" ? "Ship to Warehouse" : "Ship to Pos"}: </h5>
                                                                <p key='card-row-4-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.ship_to_type === "WAREHOUSE" ? data.ship_to_warehouse?.name : data.ship_to_pos?.mso_name}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                        <Row key="card-row-54">
                                            <Col key='card-row-4-col-2'>
                                                <Row key='card-row-4-col-2-row-1'>
                                                    <Col key='card-row-4-col-2-row-1-col-1'>
                                                        <div key='card-row-4-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                            <div key='card-row-4-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                                <h5 key='card-row-4-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Receiver: </h5>
                                                                <p key='card-row-4-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.receiver?.given_name}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row key="card-row-5">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="form-label font-size-15" htmlFor="entryComments">Comments:</label>
                                                    {
                                                        !isReadOnly ? (
                                                            <textarea id="entryComments" name="entryComments" className="form-control" value={comments} onChange={(e) => setComments(e.target.value)} maxLength={255} disabled={isReadOnly}></textarea>
                                                        ) : (<><p>{comments}</p></>)
                                                    }
                                                </div>
                                            </div>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className='d-print-none'>
                                        <div className="text-xxl-end">
                                            {
                                                !isReadOnly ? (
                                                    <DropdownButton variant='secondary' title="&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;">
                                                        <Dropdown.Item eventKey="1" onClick={() => save_dispatch()}><i className="fas fa-save"></i> Save Changes</Dropdown.Item>
                                                        <Dropdown.Item eventKey="2" style={{display:allowConfirm?"block":"none"}} onClick={() => confirm_dispatch()}><i className="fas fa-check"></i> Confirm Dispatch</Dropdown.Item>
                                                    </DropdownButton>
                                                )
                                                    :
                                                    (<button type="button" className="btn btn-light text-body waves-effect" onClick={() => printDispatch()}><i className="fas fa-print"></i>&nbsp;Print</button>)
                                            }
                                        </div>
                                    </Card.Footer>
                                </Card>
                            ) : (<></>)
                        }
                    </Row>
                    {
                        !isReadOnly ? (
                            <Row key="page-content-container-row-2" className="align-items-center">
                                <Accordion key="page-content-container-row-accordion" flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header key="row-2-accordion-header">
                                            <h4 className="card-title"><i className="bx bx-plus-circle" ></i> Add Material</h4>
                                        </Accordion.Header>
                                        <Accordion.Body key="row-2-accordion-body">
                                            {
                                                show ? (
                                                    <Alert variant={alertType} onClose={() => setShow(false)} dismissible>
                                                        <Alert.Heading>{alertTitle}</Alert.Heading>
                                                        <p>{alertMessage}</p>
                                                    </Alert>
                                                ) : (<></>)
                                            }

                                            <div id="filter-container" key="filter-container" className="table-responsive">
                                                <InputGroup key="inputgroup-1">
                                                    {!!divisions && divisions.length > 0 ? divisions.map((item, key) => {
                                                        return (
                                                            <div style={{ display: "contents" }} key={"filt-" + key} className={key===0?"firstRadio":"inputRadio"}>
                                                                <InputGroup.Radio
                                                                    key={`form-radio-${key}-d`}
                                                                    id={`form-radio-${key}`}
                                                                    name="material-division"
                                                                    value={item.id}
                                                                    onChange={(e) => handleDivisionChange(e.target.value)}
                                                                    className={key===0?"firstRadio":""}
                                                                    style={{ borderRadius: "0" }}
                                                                />
                                                                <InputGroup.Text key={`input-group-radio-text-${key}`}
                                                                    style={{ borderRadius: "0" ,marginLeft: "-1px", marginRight:"-1px" }}
                                                                >{item.name}</InputGroup.Text>
                                                            </div>
                                                        )
                                                    }) : (<></>)
                                                    }
                                                    <Form.Select key="ddl-material-type" placeholder="Select a type" id="MaterialType" onChange={(e) => handleTypeChange(e.target.value)} value={type}>
                                                        <option key="ddl-type-option-empty" value={0}>Select the type</option>
                                                        {!!types && types.length > 0 ? types.map((item, key) => {
                                                            return (
                                                                <option key={`ddl-type-option-${key}`} value={item.id}>
                                                                    {item.value}
                                                                </option>
                                                            )
                                                        }) : (<option></option>)
                                                        }
                                                    </Form.Select>
                                                    <Form.Select key="ddl-material-subtype" placeholder="Select the subtype" id="MaterialSubtype" onChange={(e) => setSubtype(e.target.value)} value={subtype}>
                                                        <option key="ddl-material-subtype-option-empty" value={0}>Select the subtype</option>
                                                        {!!filteredSubtypes && filteredSubtypes.length > 0 ? filteredSubtypes.map((item, key) => {
                                                            return (
                                                                <option key={`ddl-material-subtype-option-${key}`} value={item.id}>
                                                                    {item.value}
                                                                </option>
                                                            )
                                                        }) : (<option></option>)
                                                        }
                                                    </Form.Select>
                                                    <Form.Control type='text'
                                                        id="inputgroup-formcontrol-search"
                                                        key="inputgroup-formcontrol-search"
                                                        className='form-control'
                                                        placeholder='Enter material name'
                                                        aria-label='Enter material name'
                                                        value={materialName}
                                                        onChange={(e) => setMaterialName(e.target.value)}
                                                    ></Form.Control>
                                                    <Button key="material-clear-button" variant="outline-secondary" id="material-clear-button" onClick={(e) => resetForm()}>
                                                        <i className='fas fa-eraser'></i>&nbsp;Clear
                                                    </Button>
                                                    <Button key="material-filter-button" variant="outline-success" id="material-filter-button" onClick={(e) => filterMaterials()}>
                                                        <i className='fas fa-search'></i>&nbsp;Search
                                                    </Button>
                                                </InputGroup>
                                                {!!filteredMaterials ?
                                                    (
                                                        <Table key="filtered-materials-table"  className='filter-table-header' striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>Division</th>
                                                                    <th>Type</th>
                                                                    <th>SubType</th>
                                                                    <th>Group</th>
                                                                    <th>Name</th>
                                                                    <th>Inventory</th>
                                                                    <th>Available</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody key="filtered-materials-table-tbody">
                                                                {!!filteredMaterials && filteredMaterials.length > 0 ? filteredMaterials.sort((a, b) => a.name > b.name ? 1 : -1).map((item, key) => {
                                                                    return (
                                                                        <tr key={`filtered-materials-table-tbody-tr-${key}`}>
                                                                            <td>{item.division?.name}</td>
                                                                            <td>{item.type?.value}</td>
                                                                            <td>{item.subtype?.value}</td>
                                                                            <td>{item.group?.name}</td>
                                                                            <td>{item.name}</td>
                                                                            {/* <td>{usCurrency.format(item.unit_price)}</td> */}
                                                                            <td>{item.inventory}</td>
                                                                            <td>{item.quantity}</td>
                                                                            {/* <td>{usCurrency.format(item.quantity * item.unit_price)}</td> */}
                                                                            <td>
                                                                                <button type="button"
                                                                                    key={`material-${key}-button-add`}
                                                                                    id={`material-${key}-button-add`}
                                                                                    name={`material-${key}-button-add`}
                                                                                    className="btn btn-sm btn-light text-body waves-effect"
                                                                                    onClick={(e) => selectMaterial(item)}><i className="fas fa-plus-circle"></i> Add</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) : (<tr><td colSpan={3}>No data found.</td></tr>)
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    )
                                                    : (<></>)
                                                }
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Row>
                        )
                            :
                            (<></>)
                    }
                    <Row key="page-content-container-row-3">
                        <div id="materials-table-container" className="table-responsive">
                            <table key="materials-table" id="materials-table" className="table table-hover mb-0">
                                <thead key="materials-table-thead-0">
                                    <tr key="materials-table-thead-tr-0" className="header">
                                        <th className="dark text-center" colSpan={10}>MATERIAL DETAILS</th>
                                    </tr>
                                    <tr key="materials-table-thead-tr-1" className="header">
                                        <th className="dark">Division</th>
                                        <th className="dark">Type</th>
                                        <th className="dark">Subtype</th>
                                        <th className="dark">Group</th>
                                        <th className="dark">Name</th>
                                        {/* <th className="dark">Price</th> */}
                                        <th className="dark">Avalible</th>
                                        <th className="dark">Quantity</th>
                                        {/* <th className="dark">Amount</th> */}
                                        <th className="dark">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody key="materials-table-tbody">
                                    {!!data && !!materials && materials.length > 0 && !!allMaterials && allMaterials.length > 0 ? materials.map((item, key) => (
                                        <DistributionDetailItem key={key} keyRow={key} dispatchItem={item} status={(data.status ?? '')} setTotal={setTotalQuantity} setTotalAmount={setTotalAmount} total={totalQuantity}
                                        setAllowConfirm={setAllowConfirm} totalAmount={totalAmount} removeItem={deleteMaterialToDispatch} allMaterials={allMaterials} materials={materials} setMaterials={setMaterials}></DistributionDetailItem>
                                    )) : (<tr><td colSpan={9}>No data found.</td></tr>)
                                    }
                                </tbody>
                                {!!data && !!materials && materials.length > 0 && allMaterials && allMaterials!==null && allMaterials.length>0 ? (
                                    <tfoot className="table-group-divider">
                                        <tr>
                                            <th colSpan={6} className="">Totals </th>
                                            <th>{totalQuantity}</th>
                                            {/* <th>{usCurrency.format(totalAmount)}</th> */}
                                        </tr>
                                    </tfoot>
                                ) : (<></>)
                                }
                            </table>
                        </div>
                    </Row>

                    {loader}

                </Container>

                {/* Success Modal */}
                <Modal key="message-modal"
                    isOpen={modalSuccess}
                    toggle={() => {
                        tog_success();
                    }}
                    id="success-btn"
                    centered
                >
                    <div className="modal-body">
                        <div className="text-center">
                            <i className="bx bx-check-circle display-1 text-success"></i>
                            <p className="mt-3">{message}</p>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default DistributionDetail;