import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-eva-icons';
import { connect } from 'react-redux';

//import images 
import logoDark from '../../assets/images/logo-dark-sm.png';
import logoLight from '../../assets/images/logo-light.png';
import logoLightSm from '../../assets/images/logo-light-sm.png';

import { Link } from 'react-router-dom';

//redux
import { useDispatch } from "react-redux";

// Redux Store
import {
    showRightSidebarAction,
    toggleLeftmenu,
} from "../../store/actions";
import BreadCrumb from '../../components/Common/BreadCrumb';

const Header = ({ breadCrumbItems, showRightSidebar, leftMenu }) => {
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <Link to="/" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src={logoDark} alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src={logoDark} alt="" height="22" />
                            </span>
                        </Link>

                        <Link to="/" className="logo logo-light">
                            <span className="logo-lg">
                                <img src={logoLight} alt="" height="22" />
                            </span>
                            <span className="logo-sm">
                                <img src={logoLightSm} alt="" height="22" />
                            </span>
                        </Link>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content"
                        onClick={() => {
                            dispatch(toggleLeftmenu(!leftMenu));
                        }} >
                        <i className="fa fa-fw fa-bars"></i>
                    </button>

                    <BreadCrumb breadCrumbItems={breadCrumbItems} />

                </div>

                <div className="d-none">
                    <div className="dropdown d-inline-block">                        
                    <button
                            onClick={() => {
                                dispatch(showRightSidebarAction(!showRightSidebar));
                            }}
                            type="button"
                            className="btn header-item noti-icon right-bar-toggle"
                            id="right-bar-toggle">
                            <Icon name="settings-outline" fill="#555b6d" size="large" />
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

Header.propTypes = {
    leftMenu: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const { layoutType, showRightSidebar, leftMenu } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
})((Header));