import httpCommon from "./http-common";
import axios from "axios";
import  TokenStorage from '../util/token-storage'
import { appHostName } from "../util/commons-utilities";

const EntryCheckService = {
    getEntriesCheck() {
        let data = httpCommon.get("/transactions/entrycheck");
        return data;
    },
    getMaterialsByEntryCheckId(id) {
        let data = httpCommon.get(`/transactions/entrycheckmaterial/${id}`);
        return data;
    },
    getEntryCheckById(id) {
        let data = httpCommon.get(`/transactions/entrycheck/${id}`);
        return data;
    },
    getPhotosByMaterialEntryCheck(id) {
        let data = httpCommon.get(`/transactions/entrycheckmaterial/photos/${id}`);
        return data;
    },
    saveEntryCheck(data) {
        console.log("entry check data: ", data)
        let response = httpCommon.post("/transactions/entrycheck",JSON.stringify(data))
        return response;
    },
    saveMaterialToEntryCheck(data) {
        let response = httpCommon.post("/transactions/entrycheckmaterial",JSON.stringify(data))
        return response;
    },
    addPhoto(photoData) {
        console.log("photoData: ", photoData);
        let bodyFormData = new FormData();
        bodyFormData.append('entrycheckmaterial', photoData.entrycheckmaterial);
        bodyFormData.append('photo', photoData.photo);
        bodyFormData.append('comments', photoData.comments);
        return axios({
            method: "post",
            url: appHostName() + "/transactions/entrycheckmaterial/photos",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },
    updateEntryCheck(data) {
        let response = httpCommon.put(`/transactions/entrycheck/${data.id}`,JSON.stringify(data))
        return response;
    },
    updateEntryCheckItem(data) {
        let response = httpCommon.put(`/transactions/entrycheckmaterial/${data.id}`,JSON.stringify(data))
        return response;
    },
    applyEntry(entry) {
        let response = httpCommon.put(`/transactions/entrycheck/${entry.id}`,JSON.stringify(entry))
        return response;
    }
};

export default EntryCheckService;