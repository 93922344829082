import React, { useEffect, useState, useRef } from 'react';
import {  
    Container, 
    Row, 
    Modal } from 'reactstrap';
import Image from 'react-bootstrap/Image';    
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from 'react-router-dom';
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";

import { formatDate, formatDateTime, hasValue, isNullOrEmpty, usCurrency } from '../../util/commons-utilities';
import CountryService from '../../services/country-service';
import DivisionService from '../../services/division-service';
import ReportService from '../../services/report-service';
import { useDownloadExcel } from 'react-export-table-to-excel';

const InventoryReport = () => {
    const dispatch = useDispatch();   
    /*
    set breadcrumbs
    */
    useEffect(() => {
        dispatch(setBreadcrumb({
            title: "Inventory Report",
        }));
    }, [dispatch]);

    usePageTitle("Inventory Report | Warehouse");

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [movements, setMovements] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const [division, setDivision] = useState(0);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(0);

    const [modalPhotosTitle, setModalPhotosTitle] = useState('');
    const [modalPhotos, setModalPhotos] = useState(false);
    const [photoPath, setPhotoPath] = useState('');
    const [comments, setComments] = useState('');

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'inventory-report',
        sheet: 'Moves'
    });
    
    function getFilter(){
        let filter = {
            division: division,
            country: country
        }
        return filter;
    }

    function getData(filter){
        showLoader();
        
        ReportService.getMoves(filter)
        .then((response) => {
            setMovements(response);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            hideLoader();
        }); 
    }
    
    function submitForm(){
        getData(getFilter());
    }

    function resetForm(){
        setDivision(0);
        setCountry(0);
        getData( {
            division: 0,
            country: 0
        });
    }

    function showModalPhotos(item){
        setModalPhotosTitle(`${item.name}`);
        setPhotoPath(item.photo);
        setComments(item.comments);
        tog_photo();
    }

    function tog_photo(){
        setModalPhotos(!modalPhotos);
    }

    useEffect(() => {
        DivisionService.getDivisions()
        .then((response) => {              
            setDivisions(response.data);
        })
        .catch(err => console.dir(err))
        .finally(() => {
        });

        CountryService.getCountries()
        .then((response) => {
            setCountries(response.data);
        })
        .catch(err => console.dir(err))
        getData(getFilter());
    }, []);

    return (
        <React.Fragment>
            <div key="page-content-pd" className="page-content">
                <div key="page-content-card-header-pd" className="card-header">
                    <h4 key="page-content-card-header-pd-h4" className="card-title mb-0">&nbsp;</h4>
                </div>                
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1">
                        <Card key="card-filter">
                            <Card.Body>
                                <InputGroup key="inputgroup-1">                                                                                                         
                                    <Form.Select id="ddl-division" key="ddl-division" placeholder="Select a division"onChange={(e) => setDivision(e.target.value)} value={division}>
                                    <option value={0} key="ddl-division-option-empty">Select the division</option>
                                    {!!divisions && divisions.length > 0 ? divisions.map((item, key) => {
                                        return(
                                            <option key={`ddl-division-option-option-${key}`} value={item.id}>
                                                {item.name}
                                            </option>
                                        )   
                                        }):(<option></option>)
                                    }                                    
                                    </Form.Select>
                                    <Form.Select placeholder="Select a country" id="ddl-country" className="form-select" onChange={(e) => setCountry(e.target.value)} value={country}>
                                        <option value={0} key="ddl-country-option-empty">Select the country</option>
                                        {!!countries && countries.length > 0 ? countries.map((item, key) => {
                                            return(
                                                <option key={`ddl-country-option-${key}`} value={item.id}>
                                                    {item.name}
                                                </option>
                                            )   
                                            }):(<option></option>)
                                        }                                    
                                    </Form.Select>                                       
                                    <Button key="report-clear-button" variant="outline-secondary" id="report-clear-button" onClick={(e)=> resetForm()}>
                                        <i className='fas fa-eraser'></i>&nbsp;Clear
                                    </Button>                                                    
                                    <Button key="report-filter-button" variant="outline-primary" id="report-filter-button" onClick={(e) => submitForm()}>
                                        <i className='fas fa-search'></i>&nbsp;Search
                                    </Button>
                                    <Button key="report-export-button" variant="outline-success" id="report-export-button" onClick={(e)=> onDownload()}>
                                        <i className='fas fa-file-excel'></i>&nbsp;Export
                                    </Button>
                                </InputGroup>                                 
                            </Card.Body>                              
                        </Card>          
                    </Row>                                                          
                    <Row key="page-content-container-row-2">
                        <div id="materials-table-container" key="materials-table-container" className="table-responsive">
                            <table id="materials-table" key="materials-table" className="table table-hover mb-0"  ref={tableRef}>
                                <thead key="materials-table-thead-0">
                                    <tr key="materials-table-thead-tr-1" className="header">
                                        <th className="dark">Division</th>
                                        <th className="dark" colSpan={2}>Material</th>
                                        <th className="dark">Entry Date</th> 
                                        <th className="dark">Entries</th>
                                        <th className="dark">Entries Amount</th>
                                        <th className="dark">Remaining</th>
                                        <th className="dark">Remaining Amount</th>                                         
                                        <th className="dark">In Transit</th>
                                        <th className="dark">In Transit Amount</th>
                                        <th className="dark">At Pos</th>
                                        <th className="dark">At Pos Amount</th>                                     
                                        <th className="dark">Last Date</th>                                       
                                        <th className="dark">No Mvt Days</th>
                                    </tr>
                                </thead>
                                <tbody key="moves-table-tbody">
                                {!!movements && movements.length > 0 ? movements.sort((a, b) => a.entry_date < b.entry_date ? 1 : -1).map((item, key) => (                                    
                                        <tr key={`materials-table-tbody-tr-${key}`}>
                                            <td>{item.division}</td>
                                            <td>{item.name}</td>
                                            <td className="align-middle">  
                                                {
                                                    !isNullOrEmpty(item.photo) &&
                                                    <Image key={`materials-image-${key}`} style={{ width: 24, height: 24, cursor:'pointer' }} src={item.photo} roundedCircle onClick={(e)=> showModalPhotos(item)} /> 
                                                }                                                  
                                            </td>
                                            <td>{formatDate(item.entry_date)}</td>
                                            <td>
                                                {
                                                    hasValue(item.entries) &&
                                                        <b>
                                                            <Link key={`entry_moves_log_link_${key}`} className='btn btn-outline-secondary btn-sm waves-effect waves-light w-100'
                                                                to={{
                                                                        pathname: '/entry-moves-log',
                                                                        search: `?material=${item.material_id}&division=${division}&country=${country}`
                                                                    }}
                                                                >{item.entries}
                                                            </Link>
                                                        </b>
                                                       
                                                }                                                  
                                            </td>
                                            <td>
                                                {hasValue(item.entries_amount)?usCurrency.format(item.entries_amount):""}
                                            </td>
                                            <td>
                                                {item.remaining}
                                            </td>
                                            <td>
                                                {hasValue(item.remaining_amount)?usCurrency.format(item.remaining_amount):""}
                                            </td>                                            
                                            <td>
                                                {
                                                    hasValue(item.in_transit) &&
                                                        <b>
                                                            <Link key={`transit_moves_link_${key}`} className='btn btn-outline-secondary btn-sm waves-effect waves-light w-100'
                                                                to={{
                                                                        pathname: '/transit-moves-log',
                                                                        search: `?material=${item.material_id}&division=${division}&country=${country}`
                                                                    }}
                                                                >{item.in_transit}
                                                            </Link>
                                                        </b>
                                                }                                                
                                            </td>
                                            <td>
                                                {hasValue(item.in_transit_amount)?usCurrency.format(item.in_transit_amount):""}
                                            </td>
                                            <td>
                                                {
                                                    hasValue(item.at_pos) &&
                                                        <b>
                                                            <Link key={`pos_moves_link_${key}`} className='btn btn-outline-secondary btn-sm waves-effect waves-light w-100'
                                                                to={{
                                                                        pathname: '/pos-moves-log',
                                                                        search: `?material=${item.material_id}&division=${division}&country=${country}`
                                                                    }}
                                                                >{item.at_pos}
                                                            </Link>
                                                        </b>    
                                                }                                           
                                            </td>
                                            <td>
                                                {hasValue(item.at_pos_amount)?usCurrency.format(item.at_pos_amount):""}
                                            </td>
                                            <td>{hasValue(item.last_date)?formatDateTime(item.last_date):""}</td>
                                            <td>{item.no_mvt_days}</td>
                                        </tr>
                                    )):(<tr><td colSpan={12}>No data found.</td></tr>)
                                }                                    
                                </tbody>
                            </table>    
                        </div>  
                    </Row>                        

                    {loader}
                    <Modal key={`materials-modal-photos`}
                        isOpen={modalPhotos}
                        toggle={() => {
                            tog_photo();
                        }}
                        centered
                        >
                        <div className="modal-header">
                            <h5 className="modal-title" id="photoModalLabel">
                                {modalPhotosTitle}
                            </h5>

                            <button
                                onClick={() => {
                                    setModalPhotos(false);
                                }}
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">

                            <Row key="modal-photo-row-2">
                                <div className="col-md-12 mb-3">
                                    <img style={{ width: 470, height: 500 }} src={photoPath} alt="material"/>                                        
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="PhotoComments">Comments</label>
                                        <textarea className="form-control" id="PhotoComments" value={comments} maxLength={250} disabled></textarea>
                                    </div>
                                </div>                                    
                            </Row>                                          
                            <Row key="modal-photo-row-3" className="mt-2">
                                <div className="col-12 text-end">
                                    <button
                                        type="button"
                                        className="btn btn-danger me-2"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setModalPhotos(false);
                                        }}
                                    >
                                        <i className="bx bx-x me-1"></i> Close
                                    </button>
                                </div>
                            </Row>
                        </div>
                    </Modal>   
                </Container>                 
            </div>
        </React.Fragment>
    );
};

export default InventoryReport;