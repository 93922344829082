import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import { Container, Row, Col, Modal } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';

import MaterialEntryItem from './material-entry-item';
import DivisionService from "../../services/division-service";
import MaterialService from '../../services/material-service';
import MaterialTypesService from '../../services/material-types-service';
import EntryService from '../../services/material-entry-service';
import { hasValue, usCurrency } from '../../util/commons-utilities';


const MaterialEntryDetail = () => {
    const dispatch = useDispatch();
    const [query] = useState( new URLSearchParams(useLocation().search));
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState(null);

    const [types, setTypes] = useState(0);
    const [subtypes, setSubtypes] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [allMaterials, setAllMaterials] = useState(null);
    const [filteredMaterials, setFilteredMaterials] = useState(null);
    const [division, setDivision] = useState(0);
    const [type, setType] = useState(0);
    const [subtype, setSubtype] = useState(0);
    const [filteredSubtypes, setFilteredSubtypes]  = useState([]);

    const [comments, setComments] = useState('');
    const [materials, setMaterials] = useState(null);  
    const [show, setShow] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [message, setMessage] = useState('');
    const [modalSuccess, setModalSuccess] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isSavingAllowed, setIsSavingAllowed] = useState(true);
    const [toggleSavePermission, setToggleSavePermission] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);

    const [entryID, setEntryID] = useState(null);
    const [materialName, setMaterialName] = useState('');
    const [selecting, setSelecting] = useState(false);
    const [saving, setSaving] = useState(false);

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);

    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Entry Detail",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    usePageTitle("Entry Detail | Warehouses");
    const [meid] = useState(query.get("meid"));

    function handleDivisionChange(value){
        let objdiv = divisions.find(d => {
            return parseInt(d.id) === parseInt(value);
        });  
        if(objdiv)
            setDivision(objdiv.id);
        else
            setDivision(0);
    }

    function handleTypeChange(value){
        setType(value);
        let result = subtypes?.filter(st=> {return parseInt(st.type.id) === parseInt(value)});
        setFilteredSubtypes(result);
    }

    function tog_success() {
        setModalSuccess(!modalSuccess);
    }

    function save_entry() {

        showLoader();
        let entry = {
            id: data.id,
            warehouse: data.warehouse.id,
            comments: comments,
            date: data.date,
            status: data.status,
            hash_code: data.hash_code
        };

        EntryService.updateEntry(entry)
        .then((response) => {         
            setMessage('Entry updated successfully');
            setModalSuccess(true);
            setTimeout(()=>{
                setModalSuccess(false);
            }, 2000);
        })
        .catch(err => console.dir(err))
        .finally(() => hideLoader())

    }

    function confirm_entry(){
        showLoader();
        let entry = {
            id: data.id,
            country: data.country.id,
            warehouse: data.warehouse.id,
            comments: comments,
            date: data.date,
            expected_delivery_date: data.expected_delivery_date,
            status: 'CONFIRMED'
        };
        EntryService.confirmEntry(entry)
        .then((response) => {             
            console.log("response: ", response);
            setMessage('Entry confirmed successfully');
            setData(null);
            setMaterials([]);
            setIsUpdated(true);   
            setModalSuccess(true);
            setTimeout(()=>{
                setModalSuccess(false);
            }, 2000);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });
    }

    function filterMaterials(){
        console.log("!selecting: ", !selecting, "division: ", division, ", type: ", type, ", subtype: ", subtype, ", materialName: ", materialName);
        if(!selecting){
            let result = allMaterials?.filter(item => 
            item.status==="CREATED"
            && (!hasValue(division) || parseInt(division) === 0 || item.division.id === parseInt(division))
            && (!hasValue(type) || parseInt(type) === 0 || item.type.id === parseInt(type))
            && (!hasValue(subtype) || parseInt(subtype) <= 0 || item.subtype.id === parseInt(subtype))
            && item.name.toUpperCase().includes(materialName.toUpperCase()) 
            );
            setFilteredMaterials(result);
        }
        else{
            setFilteredMaterials(null);
        }
    }

    function selectMaterial(item){
        setSelecting(true);
        setFilteredMaterials(null);
        setSelecting(false);
        saveMaterialToEntry(item);
    }
    
    function resetForm(){
        let radios = document.querySelectorAll('input[type=radio]');
        radios.forEach(radio=> radio.checked = false);  
        let inputSearch = document.querySelector("#inputgroup-formcontrol-search");
        inputSearch.value = '';
        setFilteredMaterials([]);
        setDivision(0);
        setType(0);
        setFilteredSubtypes([]);
        setSubtype(0);
        setMaterialName("");
        filterMaterials();           
    }

    function showMessage(data){
        setAlertType(data.type);
        setAlertTitle(data.title);
        setAlertMessage(data.message);
        setShow(true);
        setTimeout(()=>{setShow(false)},5000);
    }

    function saveMaterialToEntry(item){
        if(!saving && hasValue(item)){
            let available_qty = parseInt(item.quantity - item.ordered_qty);
            if(available_qty>0){
                let data = {
                    entry: entryID,
                    material: item.id,
                    quantity: available_qty,
                    unit_price: item.unit_price,
                    notes: ""
                };
                setSaving(true);
                EntryService.saveMaterialToEntry(data)
                .then((response) => {   
                    refreshData(response.data, item);
                    showMessage({type:'success', title: 'Success', message:'Material successfully added to list.'});
                })
                .catch((err) => {
                        console.dir(err);
                        showMessage({type:'danger', title: 'Error', message: err.request.responseText});
                    })
                .finally(() => {
                    console.log("saveMaterialToEntry: finally")
                    setSaving(false);
                    resetForm();
                }) 
            }
            else {
                showMessage({type:'danger', title: 'Error', message: "There is no longer any quantity available to order."});
            }
        }
    }

    const printEntry = () => {
        window.print();
    };

    function refreshData(me_response, material_in){
        let currentMaterials = materials;
        me_response.material = material_in;
        currentMaterials.push(me_response);       
        setMaterials(currentMaterials);
        summarize(currentMaterials);
    }

    const removeEntryItem = async (item)=> {
        setSaving(true);
        await EntryService.removeEntryItem(item.id)
            .then((response) => {
                removeData(item);
                showMessage({ type: 'success', title: 'Success', message: 'Product successfully deleted from list.' });
                resetForm();
            })
            .catch((err) => {
                console.dir(err);
                showMessage({ type: 'danger', title: 'Error', message: err.request.responseText });
            })
            .finally(() => {
                setSaving(false);
            })
    }

    function removeData(itemR) {
        const currentMaterials = materials.filter((item) => item.id !== itemR.id);
        setMaterials(currentMaterials);
        summarize(currentMaterials);
    }
        
    const summarize = function (data){
        if(!hasValue(data))
            data = materials;
        let sumAmount = 0.0;
        let sumQuantity = 0;
        if(hasValue(data)){
            data.forEach(function(item) {
              sumAmount += item.quantity *  item.unit_price;
              sumQuantity += item.quantity;
            });
        }
        setTotalAmount(sumAmount);
        setTotalQuantity(sumQuantity);
    }

    function getAdditionalData(){
        MaterialService.getMaterials()
        .then((response) => {
            if(hasValue(response.data)){
                setAllMaterials(response.data);
                setFilteredMaterials(response.data.sort((a, b) => a.name > b.name ? 1 : -1).slice(0, 10));
            }
        })
        .catch(err => console.dir(err))
        .finally(() => {
        });

        DivisionService.getDivisions()
        .then((response) => {              
            setDivisions(response.data);
        })
        .catch(err => console.dir(err))
        .finally(() => {
        });

        MaterialTypesService.getMaterialTypes()
        .then((response) => {                
            setTypes(response.data);
        })
        .catch(err => console.dir(err))

        MaterialTypesService.getMaterialSubtypes()
        .then((response) => {            
            setSubtypes(response.data);
        })
        .catch(err => console.dir(err)) 
    }

    useEffect(()=>{
        let saveButtons = document.querySelectorAll('.btn-item-save');
        let count = 0;
        saveButtons.forEach(btn=>{ if(btn.style.display==='block'){ count++; } })
        
        let quantityInputText = 0;
        let qtyGreatherThanZero = 0;
        let inputElements = [...document.querySelectorAll('input[id^=material-item-]')].filter(elem => elem.type === "text");
        quantityInputText = inputElements.length;
        inputElements.forEach(elem =>{if(elem.value > 0){ qtyGreatherThanZero++ }});

        setIsSavingAllowed(count===0 && qtyGreatherThanZero === quantityInputText);
    },[toggleSavePermission])

    useEffect(() => {
        showLoader();
        
        EntryService.getEntryById(meid)
        .then((response) => {
            setEntryID(meid);
            setData(response.data);
            setComments(response.data.comments);
            let read_only = response.data.status==='CONFIRMED' || response.data.status==='APPLIED';
            setIsReadOnly(read_only);
            if(!read_only){
                getAdditionalData();
            }
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });        

        EntryService.getMaterialsByEntryId(meid)
        .then((response) => {
            setMaterials(response.data);   
            summarize(response.data);         
        })
        .catch(err => console.dir(err))
        .finally(() => {
        });
  
    }, [meid,isUpdated]);


    return (
        <React.Fragment>
            <div key="page-content-pd" className="page-content">
                <div key="page-content-card-header-pd" className="card-header">
                    <h4 key="page-content-card-header-pd-h4" className="card-title mb-0">&nbsp;</h4>
                </div>                
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                    {!!data?
                        (
                            <Card key="card-data-entry">
                                <Card.Body>
                                    <Row key="card-row-1">
                                        <Col key='card-row-1-col-1'>
                                            <Row key='card-row-1-col-1-row-1'>
                                                <Col key='card-row-1-col-1-row-1-col-1'>
                                                    <div key='card-row-1-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-1-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-1-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Code: </h5>
                                                            <p key='card-row-1-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.hash_code}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>                                              
                                            </Row>
                                        </Col>
                                    </Row>                                     
                                    <Row key="card-row-2">
                                        <Col key='card-row-2-col-1'>
                                            <Row key='card-row-2-col-1-row-1'>
                                                <Col key='card-row-2-col-1-row-1-col-1'>
                                                    <div key='card-row-2-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-2-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-2-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Date: </h5>
                                                            <p key='card-row-2-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.date}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col key='card-row-2-col-1-row-1-col-2'>
                                                    <div key='card-row-2-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-2-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-2-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Status: </h5>
                                                            <p key='card-row-2-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{(data.status??'').toUpperCase()}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>                                                
                                            </Row>
                                        </Col>
                                    </Row>     
                                    <Row key="card-row-3">
                                        <Col key='card-row-3-col-1'>
                                            <Row key='card-row-3-col-1-row-1'>
                                                <Col key='card-row-3-col-1-row-1-col-1'>
                                                    <div key='card-row-3-col-1-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-3-col-1-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-3-col-1-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Country: </h5>
                                                            <p key='card-row-3-col-1-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.warehouse.country.name}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col key='card-row-3-col-2'>
                                            <Row key='card-row-3-col-2-row-1'>
                                                <Col key='card-row-3-col-2-row-1-col-1'>
                                                    <div key='card-row-3-col-2-row-1-col-1-d1' className="d-flex align-items-center">
                                                        <div key='card-row-3-col-2-row-1-col-1-d1-d1' className="flex-grow-1">
                                                            <h5 key='card-row-3-col-2-row-1-col-1-d1-d1-h5' className="text-truncate font-size-15">Warehouse: </h5>
                                                            <p key='card-row-3-col-2-row-1-col-1-d1-d1-p' className="text-muted mb-1">{data.warehouse.name}</p>                                           
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row key="card-row4">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label font-size-15" htmlFor="entryComments">Comments:</label>
                                                {
                                                    !isReadOnly?(
                                                                <textarea id="entryComments" name="entryComments" className="form-control" value={comments} onChange={(e) => setComments(e.target.value)} maxLength={255} disabled={isReadOnly}></textarea>
                                                    ):(<><p>{comments}</p></>)
                                                }
                                            </div>
                                        </div> 
                                    </Row>                           
                                </Card.Body>
                                <Card.Footer className='d-print-none'>
                                    <div className="text-xxl-end">
                                        {
                                            !isReadOnly?(
                                                <DropdownButton variant='secondary' title="&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;">
                                                    <Dropdown.Item eventKey="1" onClick={()=> save_entry()}><i className="fas fa-save"></i> Save Changes</Dropdown.Item>
                                                    {isSavingAllowed && <Dropdown.Item eventKey="2" onClick={()=> confirm_entry()}><i className="fas fa-check"></i> Confirm Entry</Dropdown.Item>}
                                                </DropdownButton>
                                            )
                                            :
                                            (<button type="button" className="btn btn-light text-body waves-effect" onClick={()=>printEntry()}><i className="fas fa-print"></i>&nbsp;Print</button>)
                                        }
                                    </div>                                        
                                </Card.Footer>                                
                            </Card> 
                        ) : (<></>)
                    }                    
                    </Row>    
                    {
                        !isReadOnly?(
                            <Row key="page-content-container-row-2" className="align-items-center">
                                {
                                    !hasValue(allMaterials) || !hasValue(divisions) || !hasValue(types) || !hasValue(subtypes) || allMaterials?.length===0 || divisions?.length===0 || types?.length===0 ?(
                                        <div className='row mb-4'>
                                            <div className="spinner-border text-primary m-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>                                
                                            <span>Loading additional data...</span>
                                        </div>
                                    ):(
                                    <Accordion key="page-content-container-row-accordion" flush>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header key="row-2-accordion-header">
                                                <h4 className="card-title"><i className="bx bx-plus-circle" ></i> Add Material</h4>
                                            </Accordion.Header>
                                            <Accordion.Body key="row-2-accordion-body">
                                                {
                                                    show?(
                                                        <Alert key="filter-alert" variant={alertType} onClose={() => setShow(false)} dismissible>
                                                            <Alert.Heading>{alertTitle}</Alert.Heading>
                                                            <p>{alertMessage}</p>
                                                        </Alert>     
                                                    ):(<></>)
                                                }
                                        
                                                <div key="filter-container" className="table-responsive">
                                                    <InputGroup key="inputgroup-1">
                                                        {!!divisions && divisions.length > 0 ? divisions.map((item, key) => {
                                                            return(
                                                                <div style={{ display: "contents" }} key={"filt-" + key} className={key===0?"firstRadio":"inputRadio"}>
                                                                    <InputGroup.Radio
                                                                        key={`form-radio-${key}`}
                                                                        id={`form-radio-${key}`}
                                                                        name="material-division"
                                                                        value={item.id}
                                                                        onChange={(e)=> handleDivisionChange(e.target.value)}
                                                                    />
                                                                    <InputGroup.Text key={`input-group-radio-text-${key}`} className='input-radio-label'>
                                                                        {item.name}
                                                                    </InputGroup.Text>                           
                                                                </div>
                                                            )   
                                                            }):(<></>)
                                                        }  
                                                        <Form.Select key="ddl-material-type" placeholder="Select a type" id="MaterialType" onChange={(e) => handleTypeChange(e.target.value)} value={type}>
                                                            <option key="ddl-type-option-empty" value={0}>Select the type</option>
                                                            {!!types && types.length > 0 ? types.map((item, key) => {
                                                                return(
                                                                    <option key={`ddl-type-option-${key}`} value={item.id}>
                                                                        {item.value}
                                                                    </option>
                                                                )   
                                                                }):(<option></option>)
                                                            }                                    
                                                        </Form.Select>
                                                        <Form.Select key="ddl-material-subtype" placeholder="Select the subtype" id="MaterialSubtype" onChange={(e) => setSubtype(e.target.value)} value={subtype}>
                                                            <option key="ddl-material-subtype-option-empty" value={0}>Select the subtype</option>
                                                            {!!filteredSubtypes && filteredSubtypes.length > 0 ? filteredSubtypes.map((item, key) => {
                                                                return(
                                                                    <option key={`ddl-material-subtype-option-${key}`} value={item.id}>
                                                                        {item.value}
                                                                    </option>
                                                                )   
                                                                }):(<option></option>)
                                                            }                                    
                                                        </Form.Select>                                                                                                            
                                                        <Form.Control type='text' 
                                                            id="inputgroup-formcontrol-search" 
                                                            key="inputgroup-formcontrol-search"
                                                            className='form-control' 
                                                            placeholder='Enter material name' 
                                                            aria-label='Enter material name'
                                                            value={materialName}
                                                            onChange={(e) => setMaterialName(e.target.value)}
                                                            ></Form.Control>
                                                        <Button key="material-clear-button" variant="outline-secondary" id="material-clear-button" onClick={resetForm}>
                                                            <i className='fas fa-eraser'></i>&nbsp;Clear
                                                        </Button>                                                    
                                                        <Button key="material-filter-button" variant="outline-success" id="material-filter-button" onClick={filterMaterials}>
                                                            <i className='fas fa-search'></i>&nbsp;Search
                                                        </Button>
                                                    </InputGroup>                                         
                                                    {!!filteredMaterials? 
                                                        (
                                                            <Table key="filtered-materials-table" className='filter-table-header' striped bordered hover> 
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Division</th>
                                                                            <th>Type</th>
                                                                            <th>Subtype</th>
                                                                            <th>Group</th>
                                                                            <th>Name</th>
                                                                            <th>Original Qty</th>
                                                                            <th>Ordered Qty</th>
                                                                            <th>Available Qty</th>
                                                                            <th>Unit price</th>
                                                                            <th>Amount</th>                                                                            
                                                                            <th>&nbsp;</th>
                                                                        </tr>
                                                                    </thead>                                                 
                                                                    <tbody key="filtered-materials-table-tbody">
                                                                        {!!filteredMaterials && filteredMaterials.length > 0 ? filteredMaterials.sort((a, b) => a.name > b.name ? 1 : -1).map((item, key) => {
                                                                            return(
                                                                                <tr key={`filtered-materials-table-tbody-tr-${key}`}>
                                                                                    <td>{item.division.name}</td>
                                                                                    <td>{item.type.value}</td>
                                                                                    <td>{item.subtype.value}</td>
                                                                                    <td>{item.group.name}</td>                                      
                                                                                    <td>{item.name}</td>
                                                                                    <td>{item.quantity}</td>
                                                                                    <td>{item.ordered_qty}</td>
                                                                                    <td>{item.quantity - item.ordered_qty}</td>
                                                                                    <td>{usCurrency.format(item.unit_price)}</td>
                                                                                    <td>{usCurrency.format((item.quantity - item.ordered_qty)*item.unit_price)}</td>
                                                                                    <td>
                                                                                        <button type="button" 
                                                                                        key={`material-${key}-button-add`}
                                                                                        id={`material-${key}-button-add`}
                                                                                        name={`material-${key}-button-add`}
                                                                                        className="btn btn-sm btn-light text-body waves-effect"
                                                                                        onClick={(e)=> selectMaterial(item)}><i className="fas fa-plus-circle"></i> Add</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )   
                                                                            }):(<tr><td colSpan={9}>No data found.</td></tr>)
                                                                        }                                
                                                                    </tbody>
                                                            </Table>
                                                        )
                                                        :(<></>)
                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    )
                                }
                            </Row>        
                        )
                        :
                        (<></>)
                    }                                                       
                    <Row key="page-content-container-row-3">
                        <div id="materials-table-container" className="table-responsive">
                            <table key="materials-table" id="materials-table" className="table table-hover mb-0">
                                <thead key="materials-table-thead-0">
                                    <tr key="materials-table-thead-tr-0" className="header">                                        
                                        <th className="dark text-center" colSpan={9}>MATERIAL DETAILS</th>
                                    </tr>
                                    <tr key="materials-table-thead-tr-1" className="header">
                                        <th className="dark">Division</th>
                                        <th className="dark">Type</th>
                                        <th className="dark">Subtype</th>
                                        <th className="dark">Group</th>                                  
                                        <th className="dark">Name</th>
                                        <th className="dark">Price</th>
                                        <th className="dark">Quantity</th>
                                        <th className="dark">Amount</th>
                                        <th className="dark">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody key="materials-table-tbody">
                                {!!data && !!materials && materials.length > 0 ? materials.map((item, key) => (
                                        <MaterialEntryItem key={key} 
                                        keyRow={key} 
                                        entryItem={item} 
                                        status={(data.status??'')}
                                        setTotalQuantity={setTotalQuantity}
                                        setTotalAmount={setTotalAmount}
                                        removeItem={removeEntryItem} 
                                        materials={materials} 
                                        setMaterials={setMaterials}
                                        toggleSavePermission={toggleSavePermission}
                                        setToggleSavePermission={setToggleSavePermission}></MaterialEntryItem>
                                    )):(<tr><td colSpan={9}>No data found.</td></tr>)
                                }
                                </tbody>
                                {!!data && !!materials && materials.length > 0 ?(
                                        <tfoot key="materials-table-tfoot" className="table-group-divider">
                                            <tr>
                                                <th colSpan={6} className="">Totals </th>
                                                <th>{totalQuantity}</th>
                                                <th>{usCurrency.format(totalAmount)}</th>
                                            </tr>
                                        </tfoot>
                                    ):(<></>) 
                                }
                            </table>    
                        </div>  
                    </Row>                        

                    {loader}

                </Container>
                

                {/* Success Modal */}
                <Modal key="message-modal"
                    isOpen={modalSuccess}
                    toggle={() => {
                        tog_success();
                    }}
                    id="success-btn"
                    centered
                >
                    <div className="modal-body">
                        <div className="text-center">
                            <i className="bx bx-check-circle display-1 text-success"></i>
                            <p className="mt-3">{message}</p>
                        </div>
                    </div>
                </Modal>        
            </div>         
        </React.Fragment>
    );
};

export default MaterialEntryDetail;