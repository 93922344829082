import httpCommon from "./http-common";

const DispatchService = {
    getDispatchs() {
        let data = httpCommon.get("/transactions/dispatch");
        return data;
    },
    getMaterialsByDispatchId(id) {
        let data = httpCommon.get(`/transactions/dispatch/${id}/material`);
        return data;
    },
    getDispatchById(id) {
        let data = httpCommon.get(`/transactions/dispatch/${id}`);
        return data;
    },
    saveDispatch(data) {        
        let response = httpCommon.post("/transactions/dispatch",JSON.stringify(data));
        return response;
    },
    updateDispatch(data) {
        let response = httpCommon.put(`/transactions/dispatch/${data.id}`,JSON.stringify(data))
        return response;
    },
    confirmDispatch(data) {
        let response = httpCommon.put(`/transactions/dispatch/${data.id}`,JSON.stringify(data))
        return response;
    },    
    saveMaterialToDispatch(data) {
        let response = httpCommon.post("/transactions/dispatch/material",JSON.stringify(data))
        return response;
    },
    deleteMaterialToDispatch(id) {
        let response = httpCommon.delete(`/transactions/dispatch/material/${id}`)
        return response;
    },
    updateDispatchItem(data) {
        let response = httpCommon.put(`/transactions/dispatch/${data.id}/material`,JSON.stringify(data))
        return response;
    },
    applyDispatch(dispatch) {
        let response = httpCommon.put(`/transactions/dispatch/${dispatch.id}`,JSON.stringify(dispatch))
        return response;
    }
};

export default DispatchService;