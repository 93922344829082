import { useEffect, useState } from "react";
import { Row, Modal } from 'reactstrap';
import Image from 'react-bootstrap/Image';
import MaterialEntryCheckItemPhotos from "../../Entrycheck/material-entry-check-item-photos";

import { isNullOrEmpty, formatDate, isNumeric, getValueMinDataToday, getTodayDate } from "../../../util/commons-utilities"
import DispatchCheckService from "../../../services/dispatch-check-service";
const MaterialDistributionCheckItem = ({ keyRow, entryCheckItem, status, isReadOnlyD, setAllowConfirm, setTotalQuantity, totalQuantity }) => {
    const [data, setData] = useState(null);
    const [entryItemId, setEntryItemId] = useState(0);
    const [entryCheckId, setEntryCheckId] = useState(0);
    const [materialId, setMaterialId] = useState(0);
    const [materialName, setMaterialName] = useState('');
    const [materialEQty, setMaterialEQty] = useState(0);
    const [materialQty, setMaterialQty] = useState(0);
    const [materialLastQty, setMaterialLastQty] = useState(0);
    const [materialUnitPrice, setMaterialUnitPrice] = useState(0);
    const [entryNotes, setEntryNotes] = useState('');
    const [checkDate, setCheckDate] = useState(getTodayDate());
    const [selected, setSelected] = useState(false);
    const [section, setSection] = useState('');
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorOnSave, setErrorOnSave] = useState(false);
    const [rowClassName, setRowClassName] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(false);

    const [modalPhotosTitle, setModalPhotosTitle] = useState('');
    const [modalPhotos, setModalPhotos] = useState(false);
    const [photoPath, setPhotoPath] = useState('');
    const [comments, setComments] = useState('');

    function showModalPhotos(item) {
        setModalPhotosTitle(`${item.material.name}`);
        setPhotoPath(item.material.photo);
        tog_photo();
    }
    function tog_photo() {
        setModalPhotos(!modalPhotos);
    }

    useEffect(() => {
        setEntryItemId(entryCheckItem.id);
        setEntryCheckId(entryCheckItem.dispatchcheck?.id);
        setMaterialId(entryCheckItem.material?.id);
        setMaterialName(entryCheckItem.material?.name);
        setMaterialEQty(entryCheckItem.expected_qty);
        setMaterialQty(entryCheckItem.received_qty);
        setMaterialLastQty(entryCheckItem.received_qty);
        setMaterialUnitPrice(entryCheckItem.unit_price);
        setEntryNotes(entryCheckItem.notes ?? '');
        setCheckDate(entryCheckItem.check_date||getTodayDate());
        setSelected(entryCheckItem.checked);
        setSection(entryCheckItem.section ?? '');
        setData(entryCheckItem);
        setIsReadOnly(isReadOnlyD);
    }, []);

    useEffect(() => {
        let cssClassName = somethingChanged ? "border border-primary" : (errorOnSave ? "border border-danger" : "");
        setRowClassName(cssClassName);
    }, [somethingChanged, errorOnSave]);

    function saveMaterialDispatchCheckItem() {
        if (somethingChanged) {
            if (materialQty !== materialLastQty) {
                let entry = {
                    id: entryItemId,
                    expected_qty: materialEQty,
                    received_qty: materialQty,
                    unit_price: materialUnitPrice,
                    notes: entryNotes,
                    checked: selected,
                    section: section,
                    dispatchcheck: entryCheckId,
                    material: materialId,
                    check_date: !isNullOrEmpty(checkDate) ? checkDate : (selected ? formatDate(new Date()) : null)
                };
                setSaving(true);
                DispatchCheckService.updateDispatchCheckItem(entry)
                    .then((response) => {
                        setSomethingChanged(false);
                        let newQtyTotal = 0;
                        if (entry.received_qty > 0 && response.data) {
                            newQtyTotal = totalQuantity + entry.received_qty;
                        } else {
                            newQtyTotal= totalQuantity - materialLastQty;
                        }
                        // console.log("newQtyTotal",newQtyTotal);
                        setTotalQuantity(newQtyTotal);
                        setAllowConfirm(newQtyTotal>0?true:false);

                    })
                    .catch(err => {
                        console.dir(err);
                        setSomethingChanged(false);
                        setErrorOnSave(true);
                        setTimeout(() => {
                            setErrorOnSave(false);
                        }, 3000);
                    })
                    .finally(() => {
                        setSaving(false);
                    });
            }

        }
    }

    const changeQuantity = (e) => {
        e.preventDefault();
        
        setMaterialLastQty(materialQty);
        try {
            if (isNumeric(e.target.value)) {
                const value = parseFloat(e.target.value);
                if (value > materialEQty) {
                    setMaterialQty(materialEQty);
                }
                else {
                    setMaterialQty(value);
                }
                setSomethingChanged(true);
            }
            else {
                setMaterialQty(0);
                setSomethingChanged(true);
            }
        } catch (e) {

            setMaterialQty(0);
        }
    };
    return (

        !!data ? (
            <>
                <tr className={rowClassName}
                    key={`materials-table-tbody-tr-${keyRow}`}>
                    <td className="align-middle">
                        <input
                            id={`materials-${keyRow}-checked`}
                            name={`materials-${keyRow}-checked`}
                            key={`materials-${keyRow}-checked`} className="form-check-input" type="checkbox" defaultChecked={selected} value="" onChange={(e) => { setSomethingChanged(true); setSelected(!selected); }} disabled={isReadOnly}></input>
                    </td>
                    <td className="align-middle">
                        {materialName}
                    </td>
                    <td className="align-middle">
                        {materialEQty}
                    </td>
                    {/* <td className="align-middle">
                        {usCurrency.format(materialUnitPrice)}
                    </td> */}
                    {/* <td className="align-middle">
                        {usCurrency.format(materialEQty * materialUnitPrice)}
                    </td> */}
                    <td className="align-middle">
                        {
                            !isReadOnly ? (
                                <input id={`materials-${keyRow}-qty`}
                                    name={`materials-${keyRow}-qty`}
                                    key={`materials-${keyRow}-qty`} type="text" className="form-control" value={materialQty} onChange={(e) => { changeQuantity(e) }} readOnly={isReadOnly}></input>
                            ) : (<>{materialQty}</>)
                        }
                    </td>
                    <td className="align-middle">
                        {materialEQty - materialQty}
                    </td>
                    {/* <td className="align-middle">
                        {usCurrency.format(materialQty * materialUnitPrice)}
                    </td> */}
                    <td className="align-middle">
                        {
                            !isReadOnly ? (
                                <input id={`materials-${keyRow}-date`}
                                    name={`materials-${keyRow}-date`}
                                    key={`materials-${keyRow}-date`} type="date" className="form-control"
                                    min={getValueMinDataToday()} defaultValue={checkDate} onChange={(e) => { setSomethingChanged(true); setCheckDate(e.target.value); }}></input>
                            ) : (<>{checkDate} </>)
                        }
                    </td>
                    <td className="align-middle">
                        {
                            !isReadOnly ? (
                                <input id={`materials-${keyRow}-section`}
                                    name={`materials-${keyRow}-section`}
                                    key={`materials-${keyRow}-section`} type="text" className="form-control" defaultValue={section} onChange={(e) => { setSomethingChanged(true); setSection(e.target.value); }}></input>
                            ) : (<>{section}</>)
                        }
                    </td>
                    <td className="align-middle">
                        {
                            !isReadOnly ? (
                                <input id={`materials-${keyRow}-notes`}
                                    name={`materials-${keyRow}-notes`}
                                    key={`materials-${keyRow}-notes`} type="text" className="form-control" defaultValue={entryNotes} onChange={(e) => { setSomethingChanged(true); setEntryNotes(e.target.value); }}></input>
                            ) : (<>{entryNotes}</>)
                        }
                    </td>
                    <td className="align-middle">
                        <Image key={`materials-image-${keyRow}`} style={{ width: 24, height: 24, cursor: 'pointer' }} src={entryCheckItem.material?.photo} roundedCircle onClick={(e) => showModalPhotos(entryCheckItem)} />
                    </td>
                    <td className="align-middle">
                        <MaterialEntryCheckItemPhotos key={`materials-${keyRow}-photos`} keyRow={keyRow} entryCheckItem={entryCheckItem} status={(status ?? '')} isReadOnlyD={isReadOnly}></MaterialEntryCheckItemPhotos>
                    </td>
                    <td className="align-middle">
                        {
                            !isReadOnly ? (
                                <div className="text-center align-middle">
                                    <button type="button"
                                        key={`material-${keyRow}-button-save`}
                                        id={`material-${keyRow}-button-save`}
                                        name={`material-${keyRow}-button-save`}
                                        className="btn btn-sm btn-light text-body waves-effect"
                                        onClick={(e) => saveMaterialDispatchCheckItem()}
                                        style={{ display: somethingChanged ? "block" : "none" }}
                                        disabled={saving}><i className={!saving ? "fas fa-save" : "fas fa-spinner"}></i> {!saving ? "Save" : "Saving..."}</button>
                                </div>
                            ) : (<>{" "}</>)
                        }
                    </td>
                </tr>
                <Modal
                    isOpen={modalPhotos}
                    toggle={() => {
                        tog_photo();
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="photoModalLabel">
                            {modalPhotosTitle}
                        </h5>

                        <button
                            onClick={() => {
                                setModalPhotos(false);
                            }}
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">

                        <Row key="modal-photo-row-2">
                            <div className="col-md-12 mb-3">
                                <img style={{ width: 470, height: 500 }} src={photoPath} alt="material" />
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="PhotoComments">Comments</label>
                                    <textarea className="form-control" id="PhotoComments" value={comments} maxLength={250} disabled></textarea>
                                </div>
                            </div>
                        </Row>
                        <Row key="modal-photo-row-3" className="mt-2">
                            <div className="col-12 text-end">
                                <button
                                    type="button"
                                    className="btn btn-danger me-2"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setModalPhotos(false);
                                    }}
                                >
                                    <i className="bx bx-x me-1"></i> Close
                                </button>
                            </div>
                        </Row>
                    </div>
                </Modal>
            </>
        ) : (<></>)

    )
}

export default MaterialDistributionCheckItem;