import React from 'react';
import { isNullOrEmpty } from '../../util/commons-utilities';

const BreadCrumb = ({breadCrumbItems}) => {
    return (
        <React.Fragment>
           { 
            !isNullOrEmpty(breadCrumbItems.title)?
                (
                    <div className="d-none d-sm-block ms-3 align-self-center">
                        <h4 className="page-title font-size-26 fw-bolder">{breadCrumbItems.title}</h4>
                    </div>
                ):(<></>)
            }
        </React.Fragment>
    );
};

export default BreadCrumb;