import { useEffect } from 'react';

const usePageTitle = (title) => {
  const defaultTitle = "Warehouse";

  useEffect(() => {
    document.title = title || defaultTitle;
  }, [defaultTitle, title]);
};

export default usePageTitle;