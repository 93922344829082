import httpCommon from "./http-common";

const PeopleInChargeService = {
    getPeopleInCharge() {
        let data = httpCommon.get("/mdm/pic");
        return data;
    },
    getPeopleInChargeById(id) {
        let data = httpCommon.get(`/mdm/pic/${id}`)
        return data;
    },
    saveNewPeopleInCharge(data) {
        let response = httpCommon.post("/mdm/pic",JSON.stringify(data))
        return response;
    },
    updatePeopleInCharge(data) {
        let response = httpCommon.put(`/mdm/pic/${data.id}`,JSON.stringify(data))
        return response;
    },  
    deletePeopleInCharge(data) {
        let response = httpCommon.delete(`/mdm/pic/${data.id}`,JSON.stringify(data))
        return response;
    },  
};

export default PeopleInChargeService;