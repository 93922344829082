import { Collapse, Container } from 'reactstrap';

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

const NavBar = (props) => {

    const [dashboard, setdashboard] = useState(false);

    useEffect(() => {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        removeActivation(items);
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    });

    const removeActivation = items => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                if (parent.classList.contains("active")) {
                    parent.classList.remove("active");
                }
            }
        }
    };

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    return (
        <React.Fragment>
            <div className="topnav">
                <Container fluid>
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu active" id='navigation'>

                        <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle arrow-none"
                                        onClick={e => {
                                            e.preventDefault();
                                            setdashboard(!dashboard);
                                        }}
                                        to="/dashboard"
                                    >
                                        <i className="bx bx-home-circle me-2"></i>
                                        Home {props.menuOpen}
                                        <div className="arrow-down"></div>
                                    </Link>
                                </li>


                            </ul>
                        </Collapse>
                    </nav>
                </Container>
            </div>
        </React.Fragment>
    );
};


NavBar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
};

const mapStatetoProps = state => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(
    connect(mapStatetoProps, {})((NavBar))
);