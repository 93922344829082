import { useEffect, useState } from "react";
import DispatchService from "../../services/dispatch-service";
import { getInventoryByMaterial, getQuantity, hasValue, isNumeric } from "../../util/commons-utilities"
const DistributionDetailItem = ({ keyRow, dispatchItem, status, setTotal, setTotalAmount, total, totalAmount, removeItem, allMaterials, materials, setMaterials, setAllowConfirm }) => {
    const [data, setData] = useState(null);
    const [dispatchItemId, setDispatchItemId] = useState(0);
    const [dispatchId, setDispatchId] = useState(0);
    const [materialId, setMaterialId] = useState(0);
    const [divisionName, setDivisionName] = useState('');
    const [typeName, setTypeName] = useState('');
    const [subTypeName, setSubtypeName] = useState('');
    const [groupName, setGroupName] = useState('');
    const [materialName, setMaterialName] = useState('');
    const [materialInventory, setMaterialInventory] = useState(0);
    const [materialQty, setMaterialQty] = useState(0);
    const [materialUnitPrice, setMaterialUnitPrice] = useState(0);
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [errorOnSave, setErrorOnSave] = useState(false);
    const [rowClassName, setRowClassName] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [changed, setChanged] = useState(false);

    function handleQuantityChange(e) {
        const regex = /^[0-9\b]+$/;
        e.preventDefault();
        if (e.target.value === "")
            e.target.value = 0;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setSomethingChanged(true);
            if (isNumeric(e.target.value)) {
                let quantity = getQuantity(e.target.value);
                // console.log(quantity)

                if (quantity <= materialInventory) {
                    const totalAmountT = quantity * parseFloat(dispatchItem.unit_price);
                    setMaterialQty(quantity);
                    if (!changed) {
                        setTotal(total - dispatchItem.quantity + quantity);
                        setTotalAmount(totalAmount - (parseFloat(dispatchItem.unit_price) * dispatchItem.quantity) + totalAmountT);
                    }
                    else {
                        setTotal(total - materialQty + quantity);
                        setTotalAmount(totalAmount - (materialUnitPrice * materialQty) + totalAmountT);
                    }
                    updateQuantityInMaterials(quantity);
                }

            }
            setChanged(true);
        }
    }
    const updateQuantityInMaterials = (value) => {
        const currentMaterials = materials;
        const foundIndex = currentMaterials.findIndex(x => x.material.id === materialId);
        currentMaterials[foundIndex].quantity = value;
        setMaterials(currentMaterials);
    }

    useEffect(() => {
        setDispatchItemId(dispatchItem.id);
        setDispatchId(hasValue(dispatchItem.dispatch.id) ? dispatchItem.dispatch.id : dispatchItem.dispatch);
        setMaterialId(dispatchItem.material.id);
        setDivisionName(dispatchItem.material.division.name);
        setTypeName(dispatchItem.material.type.value);
        setSubtypeName(dispatchItem.material.subtype.value);
        setGroupName(dispatchItem.material.group.name);
        setMaterialName(dispatchItem.material.name);
        setMaterialInventory(getInventoryByMaterial(dispatchItem, allMaterials));
        setMaterialQty(dispatchItem.quantity || 0);
        setMaterialUnitPrice(dispatchItem.unit_price);
        setIsReadOnly(status !== 'CREATED');
        setData(dispatchItem);
    }, [dispatchItem, status]);

    useEffect(() => {
        let cssClassName = somethingChanged ? "border border-primary" : (errorOnSave ? "border border-danger" : "");
        setRowClassName(cssClassName);
    }, [somethingChanged, errorOnSave]);

    function saveDispatchDetailItem() {
        if (somethingChanged) {
            let detailItem = {
                id: dispatchItemId,
                quantity: materialQty,
                unit_price: materialUnitPrice,
                notes: '',
                dispatch: dispatchId,
                material: materialId
            };
            setSaving(true);
            DispatchService.updateDispatchItem(detailItem)
                .then((response) => {
                    setSomethingChanged(false);
                    setAllowConfirm(materialQty>0 || total>0?true:false);
                })
                .catch(err => {
                    console.dir(err);
                    setSomethingChanged(false);
                    setErrorOnSave(true);
                    setTimeout(() => {
                        setErrorOnSave(false);
                    }, 3000);
                })
                .finally(() => {
                    setSaving(false);
                });
        }
    }
    const deleteItemtoDispatch = async () => {
        setDeleting(true);
        await removeItem(dispatchItem);
        setDeleting(false);
    };

    return (

        !!data ? (
            <>
                <tr className={rowClassName}
                    key={`materials-table-tbody-tr-${keyRow}`}>
                    <td className="align-middle">
                        {divisionName}
                    </td>
                    <td className="align-middle">
                        {typeName}
                    </td>
                    <td className="align-middle">
                        {subTypeName}
                    </td>
                    <td className="align-middle">
                        {groupName}
                    </td>
                    <td className="align-middle">
                        {materialName}
                    </td>
                    <td className="align-middle">
                        {materialInventory}
                    </td>
                    {/* <td className="align-middle">
                        {materialUnitPrice}
                    </td> */}
                    <td className="align-middle">
                        {
                            !isReadOnly ? (
                                <input id={`materials-${keyRow}-qty`}
                                    name={`materials-${keyRow}-qty`}
                                    key={`materials-${keyRow}-qty`} type="text" className="form-control" value={materialQty} onChange={handleQuantityChange} readOnly={isReadOnly}></input>
                            ) : (<>{materialQty}</>)
                        }
                    </td>
                    {/* <td className="align-middle">
                        {usCurrency.format(materialQty * materialUnitPrice)}
                    </td> */}
                    <td className="align-middle">
                        {
                            !isReadOnly ? (
                                <div className="text-center align-middle">
                                    {somethingChanged && <button type="button"
                                        key={`material-${keyRow}-button-save`}
                                        id={`material-${keyRow}-button-save`}
                                        name={`material-${keyRow}-button-save`}
                                        className="btn btn-sm btn-light text-body waves-effect"
                                        onClick={(e) => saveDispatchDetailItem()}
                                        disabled={saving}><i className={!saving ? "fas fa-save" : "fas fa-spinner"}></i> {!saving ? "Save" : "Saving..."}</button>}
                                    <button type="button"
                                        key={`material-${keyRow}-button-delete`}
                                        id={`material-${keyRow}-button-delete`}
                                        name={`material-${keyRow}-button-delete`}
                                        className="btn btn-sm btn-light text-body waves-effect"
                                        onClick={(e) => { deleteItemtoDispatch(); }}
                                        disabled={deleting}><i className={!deleting ? "fas fa-trash" : "fas fa-spinner"}></i> {!deleting ? "Remove" : "Removing..."}</button>
                                </div>
                            ) : (<>{" "}</>)
                        }
                    </td>
                </tr>
            </>
        ) : (<></>)

    )
}

export default DistributionDetailItem;