import { React, useEffect, useState, useContext } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// redux
import { useSelector } from "react-redux";

//constants
import { layoutTypes } from "../components/constants/layout";

// layouts
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/Index";
import HorizontalLayout from "../Layout/HorizontalLayout/Index";
import { AccessRoute } from "./AuthProtected";

import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { UserContext } from "../context/userContext";
import { getFirstPath } from "../util/commons-utilities";
import UpdateContext from "../util/update-context";
import LoginService from "../services/login-service";
import UserService from "../services/user-service";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));



  const Layout = getLayout(layoutType);

  // localStorage.setItem("passw", pass);

  // const passwo = localStorage.getItem("passw");



  //Use state
  const { appData, setAppData } = useContext(UserContext);
  const token = appData.token || localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (!token || token === null) {
      history.push("/login");
      localStorage.removeItem("token");
    } else {
      LoginService.getTree(token).then((response) => {
        UpdateContext.updateViewAccess(setAppData, appData, response.data);
        // setViewAccess(response.data);
        const path = getFirstPath(response.data)
        //go to the first route in the view access
        if (window.location.href.includes("/login")) {
          UpdateContext.updateCurrentRoute(setAppData,appData,path)
          history.push(path);
        }
        else
          UpdateContext.updateCurrentRoute(setAppData,appData,"/"+window.location.href.split("/")[3]);


      }).catch((error) => {
        console.log("Error geting tree in index", error);
        localStorage.removeItem("token");
        window.location.href = "/login";
      });
      //get and set usr info
      UserService.getUserInfo().then((response) => {
        UpdateContext.updateUserInfo(setAppData, appData, response.data);
      });

    }
  }, [token]);

  return token && token !== undefined ? (
    <Route path={availableAuthRoutesPath}>
      <Layout>
      <div>
        {authProtectedRoutes.map((route, idx) => (
          <AccessRoute
            path={route.path}
            component={route.component}
            key={`proute-${idx}`}
            exact={true}
          />
        ))}
        </div>
      </Layout>
    </Route>
  ) : (
    <Route path={availablePublicRoutesPaths}>
      <NonAuthLayout>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            component={route.component}
            key={`route-${idx}`}
            exact={true}
          />
        ))}
      </NonAuthLayout>
    </Route>
  );
};

export default Index;
