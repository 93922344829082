import httpCommon from "./http-common";

const CountryService = {
    getCountries() {
        let data = httpCommon.get("/mdm/country")
        return data;
    },
    saveNewCountry(data) {
        let response = httpCommon.post("/mdm/country",JSON.stringify(data))
        return response;
    },
    updateCountry(data) {
        let response = httpCommon.put(`/mdm/country/${data.id}`,JSON.stringify(data))
        return response;
    }
};

export default CountryService;