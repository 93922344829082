import { useEffect, useState } from "react";
import { Row, Modal } from 'reactstrap';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import MaterialEntryCheckItemPhotos from "./material-entry-check-item-photos";
import EntryCheckService from "../../services/entry-check-service";
import { isNullOrEmpty, formatDate, usCurrency, getValueMinDataToday, getQuantity, isNumeric, tryParseJson } from "../../util/commons-utilities"
const MaterialEntryCheckItem = ({keyRow, entryCheckItem, status, setTotal, setTotalAmount, total, totalAmount, toggleSavePermission, setToggleSavePermission }) =>{
    const [data, setData] = useState(null);
    const [entryItemId, setEntryItemId] = useState(0);
    const [entryCheckId, setEntryCheckId] = useState(0);
    const [materialId, setMaterialId] = useState(0);
    const [materialName, setMaterialName] = useState('');
    const [materialEQty, setMaterialEQty] = useState(0);
    const [materialQty, setMaterialQty] = useState(0);    
    const [materialUnitPrice, setMaterialUnitPrice] = useState(0);
    const [entryNotes, setEntryNotes] = useState('');
    const [checkDate, setCheckDate] = useState('');
    const [selected, setSelected] = useState(false);
    const [section, setSection] = useState('');
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorOnSave, setErrorOnSave] = useState(false);
    const [rowClassName, setRowClassName] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isVisible, SetIsVisible] = useState(false);

    const [show, setShow] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const [modalPhotosTitle, setModalPhotosTitle] = useState('');
    const [modalPhotos, setModalPhotos] = useState(false);
    const [photoPath, setPhotoPath] = useState('');
    const [comments, setComments] = useState('');

    const [quantityValidationClassName, setQuantityValidationClassName] = useState('');
    const [quantityValidationMessage, setQuantityValidationMessage] = useState('');
    const [checkDateValidationClassName, setCheckDateValidationClassName]= useState('');
    const [checkDateValidationMessage, setCheckDateValidationMessage]= useState('');
    
    const [changed, setChanged] = useState(false);

    function validateData(){
        let isValid = true;
        if(isNullOrEmpty(materialQty) || parseInt(materialQty)===0){
            setQuantityValidationClassName("text-danger");
            setQuantityValidationMessage("The Received Qty field is required.");
            isValid = false;
        }
        else{
            if(parseInt(materialQty)>parseInt(materialEQty)){
                setQuantityValidationClassName("text-danger");
                setQuantityValidationMessage("The Received Qty must be less than or equal to the Expected Qty.");
                isValid = false;
            }
            else{
                setQuantityValidationClassName("d-none");
                setQuantityValidationMessage("");
            }
        }
        

        if(isNullOrEmpty(checkDate)){
            setCheckDateValidationClassName("text-danger");
            setCheckDateValidationMessage("The Entry Date field is required.");
            isValid = false;
        }
        else{
            setCheckDateValidationClassName("d-none");
            setCheckDateValidationMessage("");
        }
        return isValid;
    }
    function handleQuantityChange(e){
        const regex = /^[0-9\b]+$/;
        e.preventDefault();
        setSomethingChanged(true); 
        if (e.target.value === "" || regex.test(e.target.value)) {
            setMaterialQty(e.target.value);
            if (isNumeric(e.target.value)) {
                const quantity = getQuantity(e.target.value);
                if (quantity <= entryCheckItem.quantity) {
                    const totalAmountT = quantity * parseFloat(entryCheckItem.unit_price);
                    setMaterialQty(quantity);
                    if (!changed) {
                        setTotal(total - entryCheckItem.quantity + quantity);
                        setTotalAmount(totalAmount - (parseFloat(entryCheckItem.unit_price) * entryCheckItem.quantity) + totalAmountT);
                    }
                    else {
                        setTotal(total - materialQty + quantity);
                        setTotalAmount(totalAmount - (materialUnitPrice * materialQty) + totalAmountT);
                    }
                }
            }
            setChanged(true);
        }
    }

    function handleSomethingChanged(e){
        setSomethingChanged(true);
        let row = e.target.parentElement.parentElement;
        let button = row.cells[13].getElementsByTagName('button')[0];
        button.style.display = "block";
        validateData();
        setToggleSavePermission(!toggleSavePermission);
    }

    function showModalPhotos(item){
        setModalPhotosTitle(`${item.material.name}`);
        setPhotoPath(item.material.photo);
        tog_photo();
    }

    function tog_photo(){
        setModalPhotos(!modalPhotos);
    }

    function showMessage(data){
        setAlertType(data.type);
        setAlertTitle(data.title);
        setAlertMessage(data.message);
        setShow(true);
        setTimeout(()=>{setShow(false)},5000);
    }

    useEffect(()=>{
        setEntryItemId(entryCheckItem.id);
        setEntryCheckId(entryCheckItem.entrycheck.id);
        setMaterialId(entryCheckItem.material.id);
        setMaterialName(entryCheckItem.material.name);
        setMaterialEQty(entryCheckItem.expected_qty);
        setMaterialQty(entryCheckItem.received_qty);
        setMaterialUnitPrice(entryCheckItem.unit_price);
        setEntryNotes(entryCheckItem.notes??'');
        setCheckDate(entryCheckItem.check_date);
        setSelected(entryCheckItem.checked);
        setSection(entryCheckItem.section??'');
        setData(entryCheckItem);
        setIsReadOnly(status!=='CREATED');
    }, []);

    useEffect(()=>{
        let cssClassName = somethingChanged?"border border-primary":(errorOnSave?"border border-danger":"");
        setRowClassName(cssClassName);
        let visible = status!=='CREATED';
        SetIsVisible(visible);
    },[somethingChanged,errorOnSave]);

    useEffect(() => {
        validateData();
    }, [materialQty,checkDate])

    function saveMaterialEntryCheckItem(e){
        if(validateData()){
            if(somethingChanged){
                let entry = {
                    id: entryItemId,
                    expected_qty: materialEQty,
                    received_qty: materialQty,
                    unit_price: materialUnitPrice,
                    notes: entryNotes,
                    checked: selected,
                    section: section,
                    entrycheck: entryCheckId,
                    material: materialId,
                    check_date: !isNullOrEmpty(checkDate)?checkDate:(selected?formatDate(new Date()):null)
                };
                setSaving(true);
                EntryCheckService.updateEntryCheckItem(entry)
                .then((response) => { 
                    setSomethingChanged(false);
                })
                .catch(err => {
                    console.dir(err);
                    setSomethingChanged(false);
                    setErrorOnSave(true);
                    setTimeout(()=>{
                        setErrorOnSave(false);
                    }, 3000);
                    let response = tryParseJson(err.request.responseText);
                    showMessage({ type: 'danger', title: 'Error', message: response.valid===true? response.value.detail : response.value });
                })
                .finally(() => {
                    setSaving(false);
                    setToggleSavePermission(!toggleSavePermission);
                    if(e.target.tagName==="I"){
                        e.target.parentElement.style.display = "none";
                    }
                    else{
                        e.target.style.display = "none";
                    }                
                }); 
            }
        }
    }

    return(
        
            !!data?(
                <>
                    <tr className={rowClassName} 
                        key={`materials-table-tbody-tr-${keyRow}`}>
                        <td className="align-middle">
                            <input 
                            id={`materials-${keyRow}-checked`} 
                            name={`materials-${keyRow}-checked`} 
                            key={`materials-${keyRow}-checked`} 
                            className="form-check-input" 
                            type="checkbox" defaultChecked={selected} 
                            value="" onChange={(e)=> { handleSomethingChanged(e); setSelected(!selected); }} 
                            disabled={isReadOnly}></input>
                        </td>                    
                        <td className="align-middle">
                            {materialName}
                        </td>
                        <td className="align-middle">
                            {materialEQty}                                                     
                        </td>
                        <td className="align-middle">
                            {usCurrency.format(materialUnitPrice)}                                             
                        </td>
                        <td className="align-middle">
                            {usCurrency.format(materialEQty*materialUnitPrice)}                                             
                        </td>                
                        <td className="align-middle">
                            {
                                !isReadOnly?(
                                    <>
                                        <input id={`materials-${keyRow}-qty`}
                                        name={`materials-${keyRow}-qty`}
                                        key={`materials-${keyRow}-qty`} 
                                        type="text" 
                                        className="form-control" 
                                        value={materialQty} 
                                        onChange={handleQuantityChange} 
                                        readOnly={isReadOnly}></input>
                                        <span className={quantityValidationClassName} >{quantityValidationMessage}</span>
                                    </>
                                ):(<>{materialQty}</>)
                            }                                                                   
                        </td>   
                        <td className="align-middle">
                            {materialEQty-materialQty}                                             
                        </td>               
                        <td className="align-middle">
                            {usCurrency.format(materialQty*materialUnitPrice)}                                             
                        </td>                 
                        <td className="align-middle">
                            {
                                !isReadOnly?(
                                    <>
                                        <input id={`materials-${keyRow}-date`}
                                        name={`materials-${keyRow}-date`}
                                        key={`materials-${keyRow}-date`} type="date" className="form-control"
                                        min= {getValueMinDataToday()} defaultValue={checkDate} onChange={(e)=> { handleSomethingChanged(e); setCheckDate(e.target.value); }}></input>        
                                        <span className={checkDateValidationClassName} >{checkDateValidationMessage}</span>
                                    </>                            
                                ):(<>{checkDate} </>)
                            }                                                                                              
                        </td>
                        <td className="align-middle">
                            {
                            !isReadOnly?(
                                <input id={`materials-${keyRow}-section`} 
                                name={`materials-${keyRow}-section`}
                                key={`materials-${keyRow}-section`} type="text" className="form-control" defaultValue={section} onChange={(e)=> { handleSomethingChanged(e);  setSection(e.target.value); }}></input>
                                ):(<>{section}</>)
                            }
                        </td>                               
                        <td className="align-middle">
                            {
                                !isReadOnly?(
                                    <input id={`materials-${keyRow}-notes`} 
                                    name={`materials-${keyRow}-notes`}
                                    key={`materials-${keyRow}-notes`} type="text" className="form-control" defaultValue={entryNotes} onChange={(e)=> { handleSomethingChanged(e);  setEntryNotes(e.target.value); }}></input>
                                ):(<>{entryNotes}</>)
                            }
                        </td>
                        <td className="align-middle">                    
                            <Image key={`materials-image-${keyRow}`} style={{ width: 24, height: 24, cursor:'pointer' }} src={entryCheckItem.material.photo} roundedCircle onClick={(e)=> showModalPhotos(entryCheckItem)} />   
                        </td>
                        <td className="align-middle">
                            <MaterialEntryCheckItemPhotos key={`materials-${keyRow}-photos`}  keyRow={keyRow} entryCheckItem={entryCheckItem} status={(status??'')}></MaterialEntryCheckItemPhotos>                                       
                        </td> 
                        <td className="align-middle">
                            {
                                !isReadOnly?(
                                    <div className="text-center align-middle">
                                        <button type="button" 
                                        key={`material-${keyRow}-button-save`}
                                        id={`material-${keyRow}-button-save`}
                                        name={`material-${keyRow}-button-save`}
                                        className="btn btn-sm btn-light text-body waves-effect btn-item-save"
                                        style={{display: isVisible ? 'block' : 'none' }}
                                        onClick={(e)=> saveMaterialEntryCheckItem(e)}
                                        disabled={saving}><i className={!saving?"fas fa-save":"fas fa-spinner"}></i> {!saving?"Save":"Saving..."}</button>
                                    </div>
                                ):(<>{" "}</>)
                            }
                        </td>
                    </tr>   
                    {
                        show?(
                            <tr>
                                <td colSpan={14}>
                                    <Alert key="error-alert" variant={alertType} onClose={() => setShow(false)} dismissible>
                                        <Alert.Heading>{alertTitle}</Alert.Heading>
                                        <p>{alertMessage}</p>
                                    </Alert>  
                                </td>
                            </tr>   
                        ):(<></>)
                    }            
                    <Modal key={`materials-modal-photos-${keyRow}`}
                        isOpen={modalPhotos}
                        toggle={() => {
                            tog_photo();
                        }}
                        centered
                        >
                        <div className="modal-header">
                            <h5 className="modal-title" id="photoModalLabel">
                                {modalPhotosTitle}
                            </h5>

                            <button
                                onClick={() => {
                                    setModalPhotos(false);
                                }}
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">

                            <Row key="modal-photo-row-2">
                                <div className="col-md-12 mb-3">
                                    <img style={{ width: 470, height: 500 }} src={photoPath} alt="material"/>                                        
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="PhotoComments">Comments</label>
                                        <textarea className="form-control" id="PhotoComments" value={comments} maxLength={250} disabled></textarea>
                                    </div>
                                </div>                                    
                            </Row>                                          
                            <Row key="modal-photo-row-3" className="mt-2">
                                <div className="col-12 text-end">
                                    <button
                                        type="button"
                                        className="btn btn-danger me-2"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setModalPhotos(false);
                                        }}
                                    >
                                        <i className="bx bx-x me-1"></i> Close
                                    </button>
                                </div>
                            </Row>
                        </div>
                    </Modal>                 
                </>
        ):(<></>)
    
    )  
}

export default MaterialEntryCheckItem;