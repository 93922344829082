import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Hooks/useFullPageLoader"
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import usePageTitle from "../../util/head-element";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { objectToCsv, searchInTable, isNullOrEmpty, formatDate, getValueMinDataToday, hasValue } from "../../util/commons-utilities";
import EntryService from "../../services/material-entry-service";
import CountryService from '../../services/country-service';
import WarehouseService from "../../services/warehouse-service";

const MaterialEntry = () => {
    const dispatch = useDispatch();
    usePageTitle("Material Entry | Warehouse");
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Material Entry",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [editing, setEditing] = useState(0);
    const [entryId, setEntryId] = useState(0);
    const [country, setCountry] = useState(0);
    const [warehouse, setWarehouse] = useState('');
    const [comments, setComments] = useState('');
    const [date, setDate] = useState('');
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');
    const [status, setStatus] = useState('');
    const [modalEntryTitle, setModalEntryTitle] = useState('');
    const [message, setMessage] = useState('');
    const [modalEntry, setModalEntry] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    let history = useHistory();

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const captions = ["Type", "Subtype", "Name", "Price"];    

    const [isEDValid, setIsEDValid] = useState(true);
    const eDValidationMessage = "The Expected Delivery Date field is required.";
    const [isCountryValid, setIsCountryValid] = useState(true);
    const countryValidationMessage = "The Country field is required.";
    const [isWarehouseValid, setIsWarehouseValid] = useState(true);
    const warehouseValidationMessage = "The Warehouse field is required.";

    const [countryOptions, setCountryOptions] = useState([]);
    const [countrySelected, setCountrySelected] = useState(null);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [warehouseSelected, setWarehouseSelected] = useState(null);

    function resetValidationMessages(){
        setIsEDValid(true);
        setIsCountryValid(true);
        setIsWarehouseValid(true);
    }

    function tog_entry() {
        setModalEntry(!modalEntry);
    }

    function fillWarehouseList(countryId){
        return new Promise((resolve, reject) => {
            let result = warehouses.filter(w => {return parseInt(w.country.id) === countryId});
            let options = result.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                return({value: item.id, label:item.name})
            })
            setWarehouseOptions(options);
            resolve(options);
        });
    }

    function handleCountryChange(option){
        if(hasValue(option) && option.hasOwnProperty('value')){
            let value = option.value;
            setCountry(parseInt(value));
            setCountrySelected(option);
            fillWarehouseList(parseInt(value));
        }
        else {
            setCountry(0);
            setCountrySelected(null);
            setWarehouseOptions([]);
            setWarehouseSelected(null);
        }
    }
    
    function handleWarehouseChange(option){
        if(hasValue(option) && option.hasOwnProperty('value')){
            let value = option.value;
            setWarehouse(parseInt(value));
            setWarehouseSelected(option);
        }
        else{
            setWarehouse(0);
            setWarehouseSelected(null);
        }
    }

    function newEntry(){
        setModalEntryTitle('New Entry');
        setEntryId(0);
        setCountry(0);
        handleCountryChange(null);
        setWarehouse(0);
        setComments('');
        setDate('');
        setExpectedDeliveryDate('');
        setStatus("CREATED");      
        setEditing(false);
        tog_entry();
        setTimeout(()=> {
            resetValidationMessages();
        }, 100);
    }

    function editEntry(entry){
        setModalEntryTitle(`Edit Entry ...${entry.hash_code.substring(entry.hash_code.length - 7)}`);
        setEntryId(entry.id);

        setCountry(entry.country.id);
        setCountrySelected(countryOptions.find(co => {
            return co.value === entry.country.id;
        }));
        fillWarehouseList(parseInt(entry.country.id))
            .then((response)=>{
                setWarehouse(entry.warehouse.id);
                setWarehouseSelected(response.find(wo => {
                    return wo.value === entry.warehouse.id;
                }));
            });       

        setComments(entry.comments);
        setDate(entry.date);
        setExpectedDeliveryDate(entry.expected_delivery_date);
        setStatus(entry.status);
        tog_entry();
        setEditing(true);
    }

    function tog_success() {
        setModalSuccess(!modalSuccess);
    }

    function refreshData(entry){
        let currentData = data;
        let objwh = warehouses.find(w => {
            return w.id === entry.warehouse;
        });
        let obctry = countries.find(c => {
            return c.id === entry.country;
        });        
               
        let entryIndex = currentData.findIndex(e => {return e.id === entry.id;});
        
        entry.warehouse = objwh;
        entry.country = obctry;
        currentData[entryIndex] = entry;
        setData(currentData);
    }

    function save_entry() {
        if(validateData()){
            showLoader();
            console.log ("date: ", date);
            let entry = {
                id: entryId,
                warehouse: warehouse,
                comments: comments,
                date: !isNullOrEmpty(date)?date:formatDate(new Date()),
                expected_delivery_date: expectedDeliveryDate,
                country: country,
                status: status
            };
            if(!editing){
                EntryService.saveEntry(entry)
                .then((response) => {
                    setModalEntry(false);
                    history.push(`/material-entry-detail?meid=${response.data.id}`);
                })
                .catch(err => console.dir(err))
                .finally(() => {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    hideLoader();
                }); 
            }
            else{
                EntryService.updateEntry(entry)
                .then((response) => {                             
                    setEntryId(response.data.id); 
                    refreshData(response.data);
                    setModalEntry(false);
                    setMessage('Entry updated successfully');
                    setModalSuccess(true);
                    setTimeout(()=>{
                        setModalSuccess(false);
                    }, 2000);
                })
                .catch(err => console.dir(err))
                .finally(() => hideLoader())
            }
        }
    }

    //validate Expected Delivery Date
    function isValidExpectedDeliveryDate(){
        let isValid = true;
        if(isNullOrEmpty(expectedDeliveryDate)){
            setIsEDValid(false);
            isValid = false;
        }
        else{            
            setIsEDValid(true);
        }
        return isValid;
    }
    //Validate selected country
    function isValidSelectedCountry(){
        let isValid = true;
        if(!hasValue(country) || parseInt(country)===0){
            setIsCountryValid(false);
            isValid = false;
        }
        else{
            setIsCountryValid(true);
        }
        return isValid;
    }
    //Validate selected warehouse
    function isValidSelectedWarehouse(){
        let isValid = true;
        if(!hasValue(warehouse) || parseInt(warehouse)===0){
            setIsWarehouseValid(false);
            isValid = false;
        }
        else{
            setIsWarehouseValid(true);
        }
        return isValid;
    }

    function validateData(){
        //Return values are obtained separately to display all messages if necessary.
        let a = isValidExpectedDeliveryDate();
        let b = isValidSelectedCountry();
        let c = isValidSelectedWarehouse();
        return a && b && c;
    }
    
    useEffect(() => {
        isValidExpectedDeliveryDate();
    }, [expectedDeliveryDate])

    useEffect(() => {
        isValidSelectedCountry();
    }, [country])

    useEffect(() => {
        isValidSelectedWarehouse();
    }, [warehouse])

    function apply_entry(item){
        showLoader();
        setEditing(true);
        let entry = {
            id: item.id,
            country:item.country.id,
            warehouse: item.warehouse.id,
            comments: item.comments,
            date: item.date,
            expected_delivery_date: item.expected_delivery_date,
            status: 'APPLIED'
        };
        EntryService.updateEntry(entry)
        .then((response) => {
            setMessage('Entry applied successfully');
            setModalSuccess(true);
            setEditing(false);
            setTimeout(()=>{
                setModalSuccess(false);
                history.push(`/entry-check-detail?ckid=${response.data.id}`);
            }, 2000);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        });
    }


    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        showLoader();

        EntryService.getEntries()
        .then((response) => {              
            setData(response.data);
            //get country list
            CountryService.getCountries()
            .then((response) => {
                setCountries(response.data);

                let options = response.data.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                    return({value: item.id, label:item.name})
                })
                setCountryOptions(options);

            })
            .catch(err => console.dir(err))
            
            //get warehouse list
            WarehouseService.getWarehouses()
            .then((response) => {              
                setWarehouses(response.data);
            })
            .catch(err => console.dir(err))
            .finally(() => {
            });
        })
        .catch(err => console.dir(err))
        .finally(() => {
            hideLoader();
        });   

        //Validation messages
        setTimeout(()=> {
            resetValidationMessages();
        }, 1000);

    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                        <Col key="page-content-container-row-1-col-1" md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col key="page-content-container-row-1-col-2" md={8}>
                            <Row key="page-content-container-row-1-col-2-row-1">
                                <Col key="page-content-container-row-1-col-2-row-1-col-1" md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"materials-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col key="page-content-container-row-1-col-2-row-1-col-2" md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("materials", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={()=>newEntry()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row key="page-content-container-row-2">
                        <div id="entries-table-container" className="table-responsive">
                            <table id="entries-table" className="table table-hover mb-0">
                                <thead>
                                    <tr className="header">
                                        <th className="dark">Entry No.</th>
                                        <th className="dark">Code</th>
                                        <th className="dark">Country</th>
                                        <th className="dark">Warehouse</th>
                                        <th className="dark">Date</th>
                                        <th className="dark">Expected Delivery Date</th>                                        
                                        <th className="dark">Status</th>
                                        <th className="dark">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!!data && data.length > 0 ? data.map((item,key) => {
                                    return(
                                        <tr key={`entries-table-tbody-tr-${key}`}>
                                            <td>{item.id}</td>
                                            <td>{item.hash_code}</td>
                                            <td>{item.country?.name}</td>
                                            <td>{item.warehouse?.name}</td>
                                            <td>{item.date}</td>
                                            <td>{item.expected_delivery_date}</td>                                            
                                            <td>{item.status}</td>
                                            <td>
                                                <Link key={`entry_check_link_${key}`} className="btn btn-outline-secondary btn-sm waves-effect waves-light"
                                                    to={{
                                                            pathname: '/material-entry-detail',
                                                            search: `?meid=${item.id}`
                                                        }}
                                                    > <i className="bx bx-grid me-1"></i> Details
                                                </Link>
                                                &nbsp;
                                                {
                                                    item.status==="CREATED"?(
                                                        <Button variant="outline-secondary" size="sm" onClick={()=> editEntry(item)}><i className="bx bx-edit me-1"></i> Edit</Button>                          
                                                    ):(<></>)
                                                } 
                                                &nbsp;
                                                {
                                                    item.status==="CONFIRMED"?(
                                                        <Button variant="outline-secondary" size="sm" onClick={()=> apply_entry(item)}><i className="fas fa-check-double"></i> Generate entry check</Button>                            
                                                    ):(<></>)
                                                }                                                
                                            </td>
                                        </tr>
                                    )   
                                    }):(<tr><td colSpan={5}>No data found.</td></tr>)
                                }
                                </tbody>
                            </table>    
                        </div>
                    </Row>
                </Container>
            </div>
            {loader}

            
            <Modal
                isOpen={modalEntry}
                toggle={() => {
                    tog_entry();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="entryModalLabel">
                        {modalEntryTitle}
                    </h5>

                    <button
                        onClick={() => {
                            setModalEntry(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">                
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="entryDate">Expected delivery date</label>
                                <input type="date" id="entryDate" name="entryDate" className="form-control" 
                                min= {getValueMinDataToday()} value={expectedDeliveryDate} onChange={(e)=> setExpectedDeliveryDate(e.target.value)}></input>                                
                                {!isEDValid && <small className="text-danger">{eDValidationMessage}</small>}
                            </div>
                        </div>                                     
                    </div>                     
                    <div className="row">                
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="entryCountryName">Country</label>
                                {
                                    !!countryOptions && countryOptions.length>0?
                                    (<Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={countrySelected}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="entryCountryName"
                                        options={countryOptions}
                                        onChange={handleCountryChange} 
                                        value={countrySelected}
                                        placeholder="Select the country"
                                    />):(<></>)
                                }                                                               
                                {!isCountryValid && <small className="text-danger">{countryValidationMessage}</small>}
                            </div>
                        </div>                                     
                    </div>                   
                    <div className="row">                
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="entryWarehouseName">Warehouse</label>
                                <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={warehouseSelected}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="entryCountryName"
                                        options={warehouseOptions}
                                        onChange={handleWarehouseChange} 
                                        value={warehouseSelected}
                                        placeholder="Select the warehouse"
                                    />
                                {!isWarehouseValid && <small className="text-danger">{warehouseValidationMessage}</small>}
                            </div>
                        </div>                                     
                    </div>                   
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalEntry(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_entry();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modalSuccess}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{message}</p>
                    </div>
                </div>
            </Modal>      
        </React.Fragment>
    );
};

export default MaterialEntry;