import httpCommon from "./http-common";
import axios from "axios";
import  TokenStorage from '../util/token-storage'
import { appHostName } from '../util/commons-utilities'

const MaterialService = {
    getMaterials() {
        let data = httpCommon.get("/material")
        return data;
    },
    getMaterialById(id) {
        let data = httpCommon.get(`/material/${id}`)
        return data;
    },   
    getReportByMaterial(id) {
        let data = httpCommon.get(`/materialreport/by_products/0/${id}`)
        return data;
    },
    saveNewMaterial(data) {
        let response = httpCommon.post("/material",JSON.stringify(data))
        return response;
    },
    updateMaterial(data) {
        let response = httpCommon.put(`/material/${data.id}`,JSON.stringify(data))
        return response;
    },
    getReportByWhAndProd(wh,id) {
        let data = httpCommon.get(`/materialreport/quantity_by/${wh}/${id}`)
        return data;
    },
    uploadPhoto(photoData){
        console.log("photoData: ", photoData);
        let bodyFormData = new FormData();
        bodyFormData.append('id', photoData.id);
        bodyFormData.append('photo', photoData.photo);
        bodyFormData.append('comments', photoData.comments);

        return axios({
            method: "put",
            url: appHostName() + "/material/photo",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    }
};

export default MaterialService;