import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
import usePageTitle from "../../util/head-element";
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import EntryService from "../../services/material-entry-service";
import EntryCheckService from "../../services/entry-check-service";
import { searchInTable, objectToCsv, getValueMinDataToday, hasValue, isNullOrEmpty } from "../../util/commons-utilities";

const MaterialEntriesCheck = () => {
    const dispatch = useDispatch();
    usePageTitle("Entries Check | Warehouse");
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Entries Check",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [data, setData] = useState([]);
    const [entryCheckId, setEntryCheckId] = useState(0);
    const [entryCode, setEntryCode] = useState('');
    const [entry, setEntry] = useState('');
    const [status, setStatus] = useState('');
    const [comments, setComments] = useState('');
    const [date, setDate] = useState('');
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [modalEntryTitle, setModalEntryTitle] = useState('');
    const [message, setMessage] = useState('');
    const [modalEntry, setModalEntry] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const[editing, setEditing] = useState(false);
    const captions = ["Id", "Project", "Warehouse", "Date", "Comments"];
    let history = useHistory();

    const [countryName, setCountryName] = useState('');
    const [warehouseName, setWarehouseName] = useState('');

    function handleBlur(e){
        if(hasValue(e) && hasValue(e.target) && !isNullOrEmpty(e.target.value)){
            EntryService.getEntryByCode(e.target.value).then((response) => {
                if(response.data!=null){
                        console.log("response: ", response);
                        setCountryName(response.data.country.name);
                        setWarehouseName(response.data.warehouse.name);
                }
                else{
                    alert("The entry hash code provided does not exists.")
                }            
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader());
        }
    }

    function editEntryCheck(entrycheck){
        setModalEntryTitle('Edit Entry Check');
        setEntryCheckId(entrycheck.id);
        setEntryCode(entrycheck.entry.hash_code);
        setCountryName(entrycheck.entry.country.name);
        setWarehouseName(entrycheck.entry.warehouse.name);
        setComments(entrycheck.comments);
        setDate(entrycheck.date);
        setStatus(entrycheck.status);
        setEntry(entrycheck.entry);
        tog_entry();
        setEditing(true);
    }

    function tog_entry() {
        setModalEntry(!modalEntry);
    }

    function newEntry(){
        setModalEntryTitle('New Entry Check');
        setStatus("CREATED");
        tog_entry();
        setEditing(false);
    }
    function tog_success() {
        setModalSuccess(!modalSuccess);
    }

    function errorHandler(error){
        console.dir(error)
    }

    function refreshData(entrycheck){
        let currentData = data;
        if(!editing){
            currentData.push(entrycheck);
        }
        else{
           let entryIndex = currentData.findIndex(e => {return entrycheck.id === entryCheckId;});
           entrycheck.id = entryCheckId;
           currentData[entryIndex] = entrycheck;
        }
        setData(currentData);
    }

    function save_entry() {
        showLoader();
        if(!editing){
            EntryService.getEntryByCode(entryCode).then((response) => {
                if(response.data!=null){
                        console.log("response: ", response);
                        let entry_id = response.data.id;
                        let entry = {
                            id: entryCheckId,
                            comments: comments,
                            entry: entry_id,
                            date: date,
                            status:status
                        };
                        EntryCheckService.saveEntryCheck(entry)
                        .then((response) => {
                            setModalEntry(false);
                            history.push(`/entry-check-detail?ckid=${response.data.id}`);
                        })
                        .catch(err => errorHandler(err))
                        .finally(() => {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            hideLoader();
                        }); 
                }
                else{
                    alert("The entry hash code provided does not exists.")
                }            
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader());
        }
        else{
            EntryCheckService.updateEntryCheck(entry)
            .then((response) => {               
                refreshData(response.data);
                setModalEntry(false);
                setMessage('Entry check updated successfully');
                setModalSuccess(true);
                setTimeout(()=>{
                    setModalSuccess(false);
                }, 2000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader());
        }
    }

    useEffect(() => {
        showLoader();
        EntryCheckService.getEntriesCheck()
        .then((response) => {
            setData(response.data);            
        })
        .catch(err => console.dir(err))
        .finally(() => {
            hideLoader();
        });
        
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"entries-check-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("EntiesCheck", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" title="Generate Entry Check" onClick={() => newEntry()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>                                      
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="entries-check-table-container" className="table-responsive">
                            <table id="entries-check-table" className="table table-hover mb-0">
                                <thead>
                                    <tr className="header">
                                        <th className="dark">Entry check No.</th>
                                        <th className="dark">Source entry code</th>
                                        <th className="dark">Warehouse</th>
                                        <th className="dark">Date</th>
                                        <th className="dark">Status</th>
                                        <th className="dark">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!!data && data.length > 0 ? data.map((item,key) => {
                                    return(
                                        <tr key={`entries-check-table-tbody-tr-${key}`}>
                                            <td>{item.id}</td>
                                            <td>{item.entry.hash_code}</td>
                                            <td>{item.entry.warehouse.name}</td>
                                            <td>{item.date}</td>
                                            <td>{item.status}</td>
                                            <td>
                                                <Button variant="outline-secondary" size="sm" onClick={()=> editEntryCheck(item)}><i className="bx bx-edit me-1"></i> Edit</Button>      
                                                &nbsp;  
                                                <Link key={`entry_check_link_${key}`} className="btn btn-outline-secondary btn-sm waves-effect waves-light"
                                                    to={{
                                                            pathname: '/entry-check-detail',
                                                            search: `?ckid=${item.id}`
                                                        }}
                                                    > <i className="bx bx-grid me-1"></i> Details
                                                    </Link>                                                  
                                            </td>
                                        </tr>
                                    )   
                                    }):(<tr><td colSpan={5}>No data found.</td></tr>)
                                }
                                </tbody>
                            </table>    
                        </div>
                    </Row>
                </Container>                
            </div>            
            {loader}

            
            <Modal
                isOpen={modalEntry}
                toggle={() => {
                    tog_entry();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="entryModalLabel">
                        {modalEntryTitle}
                    </h5>

                    <button
                        onClick={() => {
                            setModalEntry(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">                
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="entryDate">Date</label>
                                <input type="date" id="entryDate" name="entryDate" className="form-control"
                                min= {getValueMinDataToday()} value={date} onChange={(e)=> setDate(e.target.value)}></input>
                            </div>
                        </div>                                     
                    </div>               
                    <div className="row">                
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="entryCode">Entry code</label>
                                <input type="text" id="entryCode" name="entryCode" className="form-control" value={entryCode} onChange={(e)=> setEntryCode(e.target.value)} onBlur={handleBlur}></input>
                            </div>
                        </div>                                     
                    </div>                     
                    <div className="row">                
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="entryCountryName">Country</label>
                                <input type="text" id="countryName" name="countryName" className="form-control" value={countryName} readOnly={true}></input>
                            </div>
                        </div>                                     
                    </div>                   
                    <div className="row">                
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="entryWarehouseName">Warehouse</label>
                                <input type="text" id="warehouseName" name="warehouseName" className="form-control" value={warehouseName} readOnly={true}></input>
                            </div>
                        </div>                                     
                    </div>                    
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalEntry(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_entry();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modalSuccess}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{message}</p>
                    </div>
                </div>
            </Modal>            
        </React.Fragment>
    );
};

export default MaterialEntriesCheck;