import React, { useEffect, useState } from 'react';
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../store/actions";

import usePageTitle from '../util/head-element'; 

import { Container } from 'reactstrap';
import Markdown from "react-markdown";
import { playbookmd } from './playbook-md';
import { isNullOrEmpty } from '../util/commons-utilities';

import  './playbook.css'

const Playbook = () => {
    usePageTitle("Docs | Warehouse");
    const dispatch = useDispatch();
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [markdown, setMarkdown] = useState('');

    useEffect(() => {

        setMarkdown(playbookmd);
        
    }, []);

    //To assign id to titles for TOC
    useEffect(()=>{
        let titles = ["h1", "h2", "h3", "h4", "h5", "h6"];
        
        titles.forEach((h) => {
            let listh = document.getElementsByTagName(h);
            for (let item of listh) {
                item.id = item.textContent.replace(/[.,-/#!$%^&*;:{}=\-_`~()]/g,"").toLowerCase().replace(/\s+/g,'-');
            }
        });
        
    }, [markdown])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container key="page-content-container" fluid>
                {!!markdown && !isNullOrEmpty(markdown)? 
                (
                    <Markdown>
                        {markdown}
                    </Markdown>
                ):
                (
                    <div className='row mb-4'>
                        <div className="spinner-border text-primary m-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>                                
                        <span>Loading Playbook...</span>
                    </div>
                )
                }                    
                </Container>
            </div>        
        </React.Fragment>
    );
};

export default Playbook;