import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Container, Row } from 'reactstrap';
import usePageTitle from "../../util/head-element";

// Import Images
import error404 from "../../assets/images/error-img.png";
const Error404 = () => {
    usePageTitle("Error 404 | Warehouse");
    return (
        <React.Fragment>
            <div className="bg-light">
                <div className="authentication-bg min-vh-100 py-5 py-lg-0">
                    <div className="bg-overlay bg-light"></div>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <div className="col-lg-12">
                                        <div className="text-center mb-5">
                                            <h1 className="error-title"> <span className="blink-infinite">404</span></h1>
                                            <h4 className="text-uppercase">Sorry, page not found</h4>
                                            <p className="font-size-15 mx-auto text-muted w-50 mt-4">It will be as simple as Occidental in fact, it will Occidental to an English person</p>
                                            <div className="mt-5 text-center">
                                                <Link className="btn btn-primary waves-effect waves-light" to="/">Back to Dashboard</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <Row className="justify-content-center">
                                    <div className="col-md-8 col-xl-7">
                                        <div className="mt-2">
                                            <img src={error404} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment >
    );
};

export default Error404;