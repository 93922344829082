import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link } from "react-router-dom";
import usePageTitle from "../../util/head-element";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from "leaflet"
import "leaflet/dist/leaflet.css";
import "./warehouse-styles.css";

import WarehouseService from '../../services/warehouse-service';
import { Widgets } from './warehouse-summaries';
import { hasValue, isNullOrEmpty, usCurrency } from '../../util/commons-utilities';

const WarehouseDetail = () => {
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState(null);
    const [materials, setMaterials] = useState(null);
    const [bodyTitle, setBodyTitle]= useState('');
    const [products, setProducts] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);

    usePageTitle("Warehouse Detail | Warehouse");
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: !isNullOrEmpty(bodyTitle)?bodyTitle:"Warehouse Detail",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch, bodyTitle]);

    const [query] = useState( new URLSearchParams(useLocation().search));
    const [qid] = useState(query.get("wid"));

    const [center, setCenter] = useState({lat:9.0226016, lng:-79.4755589});

    const markerIcon = new L.Icon({
        iconUrl: require("../../assets/images/location_pin_icon.png"),
        iconSize: [25,35],
        iconAnchor: [17,46],
        popupAnchor:[0, -46]
    });

    const summarize = function (data){
        let sumAmount = 0.0;
        let sumQuantity = 0;
        if(hasValue(data)){
            data.forEach(function(item) {
              sumAmount += item.qty *  item.material.unit_price;
              sumQuantity += item.qty;
            });
        }
        setTotalAmount(sumAmount);
        setTotalQuantity(sumQuantity);
    }

    const ZOOM_LEVEL = 5;
    const mapRef = useRef();

    useEffect(() => {
        showLoader();
        WarehouseService.getWarehouseById(qid)
        .then((response) => {     
            setData(response.data);
            setBodyTitle(response.data.name);
            setCenter([response.data.latitude, response.data.longitude]);
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        }); 

        
        WarehouseService.getMaterialsByWarehouse(qid)
        .then((response) => {      
            setMaterials(response.data); 
            summarize(response.data);          
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        }); 

        WarehouseService.getReportByWarehouse(qid)
        .then((response) => {      
            console.log("getReportByWarehouse: ", response.data)
            setProducts(response.data);            
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader();
        }); 
        

    }, [qid])
    

    return (
        <React.Fragment>
            <div key="page-content-wd" className="page-content">
                <div key="page-content-card-header-wd" className="card-header">
                    <h4 className="card-title mb-0">&nbsp;</h4>
                </div>                
                <Container key="page-content-container" fluid>
                    <Row key="page-content-container-row-1" className="align-items-center">
                        <Col xl={6} lg={6} key="col-map-container">
                            <Card key="col-card-map-container">
                                <CardBody key="col-card-cardbody" className="card-body">
                                    <MapContainer key="col-card-cardbody-mapcontainer" center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
                                        <TileLayer key="col-card-cardbody-mapcontainer-titlelayer"
                                            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {!!data ?  
                                            (
                                                <Marker position={[data.latitude, data.longitude]}
                                                icon={markerIcon}
                                                key="col-card-cardbody-mapcontainer-marker-map"
                                                >
                                                    <Popup key="col-card-cardbody-mapcontainer-popup">
                                                        <b>{data.name}</b><br/>`{!!data.region? data.region.name + "," : ""} ${data.country.name}`
                                                    </Popup>
                                                </Marker>
                                            ):(null)
                                        }
                                    </MapContainer>                                     
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6} lg={6} key="col-warehouse-data">
                        {!!data && !!materials && materials.length > 0 ?(<Widgets warehouse={data} materials={materials}/>):(null) }                             
                        </Col>
                    </Row>
                    
                    <Row key="page-content-container-row-2">
                        <div id="products-table-containeer" className="table-responsive">
                            <table id="products-table" className="table table-hover mb-0">
                                <thead key="products-table-thead">
                                    <tr key="products-table-thead-tr" className="header">
                                        <th className="dark">Name</th>
                                        <th className="dark">Type</th>
                                        <th className="dark">Subtype</th>
                                        <th className="dark">Group</th>
                                        <th className="dark">Subgroup</th>                                        
                                        <th className="dark">Price</th>
                                        <th className="dark">Stock</th>
                                        <th className="dark">Amount</th>
                                        <th className="dark">Last update</th>
                                        <th className="dark">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody key="products-table-tbody">
                                {!!products && products.length > 0 ? products.map((item, key) => {
                                    return(
                                        <tr key={`products-table-tbody-tr-${key}`}>
                                            <td>{item.material}</td>
                                            <td>{item.type}</td>
                                            <td>{item.subtype}</td>
                                            <td>{item.group}</td>
                                            <td>{item.subgroup}</td>                                
                                            <td>{usCurrency.format(item.unit_price)}</td>
                                            <td>{item.quantity}</td>
                                            <td>{usCurrency.format(item.quantity * item.unit_price)}</td>
                                            <td>{item.max_move_date}</td>
                                            <td>
                                                <Link key={`Product_Row_Link_${key}`} className="btn btn-outline-secondary btn-sm waves-effect waves-light"
                                                    data-toggle="tooltip" title="View history"
                                                    to={{
                                                            pathname: '/warehouse-product-history',
                                                            search: `?qwh=${qid}&qpr=${item.id}`
                                                        }}
                                                    > <i className="fas fa-history "></i> History
                                                </Link>                                               
                                            </td>
                                        </tr>
                                    )   
                                    }):(<tr><td colSpan={9}>No data found.</td></tr>)
                                }
                                </tbody>
                                {!!data && !!materials && materials.length > 0 ?(
                                        <tfoot className="table-group-divider">
                                            <tr>
                                                <th colSpan={6} className="">Totals </th>
                                                <th>{totalQuantity}</th>
                                                <th>{usCurrency.format(totalAmount)}</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </tfoot>
                                    ):(<></>) 
                                }
                            </table>    
                        </div>  
                    </Row>                        

                    {loader}

                </Container>
            </div>         
        </React.Fragment>
    );
};

export default WarehouseDetail;