import React, { useEffect } from 'react';
import usePageTitle from "../../util/head-element";
// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import AuthSlider from './authCarousel';

const Logout = () => {

    const { layoutMode } = useSelector((state) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    usePageTitle("Logout | Warehouse");

    useEffect(() => {
        if (layoutMode === "dark") {
            document.body.classList.remove("bg-transparent");
        } else {

            document.body.className = "bg-transparent";
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">
                                                            <div className="mb-4 mb-md-5">
                                                                <Link to="/index" className="d-block auth-logo">
                                                                    <img src={logodark} alt="" height="22" className="auth-logo-dark me-start" />
                                                                    <img src={logolight} alt="" height="22" className="auth-logo-light me-start" />
                                                                </Link>
                                                            </div>

                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <div className="avatar-lg mx-auto">
                                                                        <div className="avatar-title bg-light text-primary h1 rounded-circle">
                                                                            <i className="bx bxs-user"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-4 pt-2">
                                                                        <h5>You are Logged Out</h5>
                                                                        <p className="text-muted font-size-15">Thank you for using <span className="fw-semibold text-dark">Borex</span></p>
                                                                        <div className="mt-4">
                                                                            <Link to="/login" className="btn btn-primary w-100 waves-effect waves-light">Sign In</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-4 pt-3 text-center">
                                                                    <p className="text-muted mb-0">Don't have an account ? <Link to="/auth-register"
                                                                        className="text-primary fw-semibold"> Signup</Link></p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-4 text-center">
                                                                <p className="mb-0">© {new Date().getFullYear()} Borex   . Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <AuthSlider />
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Logout;