import React, { useEffect, useState } from "react";
import useFullPageLoader from "../../Hooks/useFullPageLoader";
import { Col, Container, Row, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import usePageTitle from "../../util/head-element";
import PeopleInChargeService from "../../services/pic-service";
import { FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { hasValue, objectToCsv, searchInTable } from "../../util/commons-utilities";
import CountryService from "../../services/country-service";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// just a test

const MaterialPic = () => {
  const dispatch = useDispatch();
  usePageTitle("People in charge | Warehouse");
  /*
    set breadcrumbs
    */

  useEffect(() => {
    const breadCrumbItems = {
      title: "People in charge",
    };
    dispatch(setBreadcrumb(breadCrumbItems));
  }, [dispatch]);

  const [data, setData] = useState([]);
  const [modal_PeopleInCharge, setmodal_PeopleInCharge] = useState(false);
  const [modal_success, setmodal_success] = useState(false);
  const [picID, setPicID] = useState(0);
  const [email, setEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const captions = ["Id", "Name", "Country"];

  function setCurrentPeopleInCharge(resp) {
    setPicID(resp.id);
    setEmail(resp.email);
    setCountry(resp.country);
  }

  function newPeopleInCharge() {
    setModalTitle("Add new");
    setCurrentPeopleInCharge({
      id: 0,
      name: "",
      country: "",
    });
    handleCountryChange(0);
    tog_PeopleInCharge();
    setEditing(false);
    setDeleting(false);
  }

  function editPeopleInCharge(resp) {    
    setModalTitle(`Update ${hasValue(resp.email)?resp.email:""}`);
    setCurrentPeopleInCharge(resp);
    handleCountryChange(resp.country.id);
    tog_PeopleInCharge();
    setEditing(true);
  }

  function deletePeopleInCharge(pic) {
    PeopleInChargeService.deletePeopleInCharge(pic)
      .then(() => {
        setModalMessage("Deleted successfully");
        setmodal_success(true);
        setTimeout(() => {
          setmodal_success(false);
        }, 2000);
      })
      .catch((err) => console.dir(err))
      .finally(() => {
        const newList = data.filter((item) => item.id !== pic.id);
        setData(newList);
        hideLoader();
      });
  }

  function save_PeopleInCharge() {
    let pic = {
      id: picID,
      email: email,
      country: country,
    };
    showLoader();
    if (!editing) {
      PeopleInChargeService.saveNewPeopleInCharge(pic)
        .then((response) => {
          console.log(data);
          refreshData(response.data);
          setmodal_PeopleInCharge(false);
          setModalMessage("Person in charge added successfully");
          setmodal_success(true);
          setTimeout(() => {
            setmodal_success(false);
          }, 1000);
        })
        .catch((err) => console.dir(err))
        .finally(() => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          hideLoader();
        });
    } else {
      PeopleInChargeService.updatePeopleInCharge(pic)
        .then((response) => {
          refreshData(response.data);
          setmodal_PeopleInCharge(false);
          setModalMessage("Person in charge updated successfully");
          setmodal_success(true);
          setTimeout(() => {
            setmodal_success(false);
          }, 2000);
        })
        .catch((err) => console.dir(err))
        .finally(() => hideLoader());
    }
  }

  function tog_PeopleInCharge() {
    setmodal_PeopleInCharge(!modal_PeopleInCharge);
  }
  function tog_success() {
    setmodal_success(!modal_success);
  }

  function refreshData(resp) {
    let currentData = data;
    if (!editing) {
      currentData.push(resp);
    } else {
      let index = currentData.findIndex((m) => {
        return m.id === picID;
      });
      currentData[index] = resp;
    }
    setData(currentData);
  }

  function handleCountryChange(value) {
    setCountry(value);
  }

  useEffect(() => {
    showLoader();
    PeopleInChargeService.getPeopleInCharge()
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => console.dir(err))
      .finally(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        hideLoader();
      });

    CountryService.getCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((err) => console.dir(err));
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={4}>
              <div className="mb-3"></div>
            </Col>

            <Col md={8}>
              <Row>
                <Col md={8}>
                  <FormControl
                    id="filter-text"
                    type="input"
                    placeholder="Search..."
                    onKeyUp={() =>
                      searchInTable({
                        tableId: "material-types-table",
                        inputSearchId: "filter-text",
                      })
                    }
                  ></FormControl>
                </Col>
                <Col md={4}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target=".add-new"
                        className="btn btn-outline-secondary"
                        onClick={() => newPeopleInCharge()}
                      >
                        <i className="bx bx-plus me-1 red"></i> Add New
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div
              id="material-types-table-container"
              className="table-responsive"
            >
              <table
                id="material-types-table"
                className="table table-hover mb-0"
              >
                <thead>
                  <tr className="header">
                    <th className="dark">Id</th>
                    <th className="dark">Email</th>
                    <th className="dark">Country</th>
                    <th className="dark">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!!data && data.length > 0 ? (
                    data.map((item, key) => {
                      return (
                        <tr key={`material-types-table-tbody-tr-${key}`}>
                          <td>{item.id}</td>
                          <td>{item.email}</td>
                          <td>{item.country.name}</td>

                          <td>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              onClick={() => editPeopleInCharge(item)}
                            >
                              <i className="bx bx-edit me-1"></i> Edit
                            </Button>{" "}
                            <Button
                              color="red"
                              variant="outline-secondary"
                              size="sm"
                              onClick={() => deletePeopleInCharge(item)}
                            >
                              <i className="bx bx-no-entry me-1"></i> Delete
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Row>
        </Container>
      </div>
      {loader}

      {/* Add New Modal */}
      <Modal
        isOpen={modal_PeopleInCharge}
        toggle={() => {
          tog_PeopleInCharge();
        }}
        className="new-customer"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="divisionModalLabel">
            {` ${modalTitle} `}
          </h5>

          <button
            onClick={() => {
              setmodal_PeopleInCharge(false);
            }}
            type="button"
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label" htmlFor="AddNew-Description">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  id="AddNew-Description"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label" htmlFor="AddNew-Description">
                    Country
                  </label>
                  <select
                    placeholder="Select a country"
                    id="PeopleInChargeCountry"
                    class="form-control form-select"
                    onChange={(e) => handleCountryChange(e.target.value)}
                    value={country}
                  >
                    <option key="ddl-warehouse-country-option-empty">
                      Select the country
                    </option>
                    {!!countries && countries.length > 0 ? (
                      countries
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((item, key) => {
                          return (
                            <option
                              key={`ddl-country-option-${key}`}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })
                    ) : (
                      <option></option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <Row className="mt-2">
            <div className="col-12 text-end">
              <button
                type="button"
                className="btn btn-danger me-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setmodal_PeopleInCharge(false);
                }}
              >
                <i className="bx bx-x me-1"></i> Cancel
              </button>

              <button
                type="submit"
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#success-btn"
                id="btn-save-event"
                onClick={() => {
                  save_PeopleInCharge();
                }}
              >
                <i className="bx bx-save me-1"></i> Save
              </button>
            </div>
          </Row>
        </div>
      </Modal>

      {/* Success Modal */}
      <Modal
        isOpen={modal_success}
        toggle={() => {
          tog_success();
        }}
        id="success-btn"
        centered
      >
        <div className="modal-body">
          <div className="text-center">
            <i className="bx bx-check-circle display-1 text-success"></i>
            <p className="mt-3">{modalMessage}</p>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MaterialPic;
