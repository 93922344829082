import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import MaterialTypesManagement from './material-types-management';
import MaterialSubtypesManagement from './material-subtypes-management';
import MaterialDivisionsManagement from './material-division';
import MaterialDivisionsManagementReplicate from './material-pic';
import MaterialGroupsManagement from './material-group';
import MaterialSubgroupsManagement from './material-subgroup';
import CampaignsManagement from './material-campaign';

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import usePageTitle from "../../util/head-element";

const MdmManagement = () => {
    const dispatch = useDispatch();   
    /*
    set breadcrumbs
    */
    useEffect(() => {
        dispatch(setBreadcrumb({
            title: "Management",
        }));
    }, [dispatch]);
    const [key, setKey] = useState('material-types');
    usePageTitle("Management | Warehouse");

    
    const changePageTitle = (tabkey) => {
        let element = document.querySelector('.page-title');
        switch(tabkey) {
            case 'material-types':
                element.innerHTML = "Material Types";
                break;
            case 'material-subtypes':
                element.innerHTML = "Material Subtypes";
                break;
            case 'material-division':
                element.innerHTML = "Material Divisions";
                break;  
            case 'material-division-replicate':
                element.innerHTML = "Material Divisions Replicate";
                break;    
            case 'material-group':
                element.innerHTML = "Material Groups";
                break;   
            case 'material-subgroup':
                element.innerHTML = "Material Subgroups";
                break;    
            case 'material-campaign':
                element.innerHTML = "Campaigns";
                break;        
            default:
                element.innerHTML = "Material Types";
                break;
          }        
    };


    const changeTitle = (tabkey) => {

        setKey(tabkey);

        switch(tabkey) {
            case 'material-types':
                document.title = "Material Types | Warehouse";
                break;
            case 'material-subtypes':
                document.title = "Material Subtypes | Warehouse";
                break;                            
            case 'material-division':
                document.title = "Material Divisions | Warehouse";
                break;
            case 'material-division-replicate':
                document.title = "Material Divisions Replicate | Warehouse";
                break;    
            case 'material-group':
                document.title = "Material Groups | Warehouse";
                break;
            case 'material-subgroup':
                document.title = "Material Subgroups | Warehouse";
                break;
            case 'material-campaign':
                document.title = "Campaigns | Warehouse";
                break;                
            default:
              break;
          }        
          
        changePageTitle(tabkey);
    };

    return (
        <React.Fragment>
            <div className="page-content">               
                <Container fluid>
                    <Tabs
                        defaultActiveKey="material-types"
                        transition={true}
                        id="mdm-management-tab"
                        activeKey={key}
                        onSelect={(k) => changeTitle(k)}
                        className="mb-3"
                        >
                        <Tab eventKey="material-types" title="Material Types">
                            <MaterialTypesManagement></MaterialTypesManagement>
                        </Tab>
                        <Tab eventKey="material-subtypes" title="Material Subtypes">
                            <MaterialSubtypesManagement></MaterialSubtypesManagement>
                        </Tab>      
                        <Tab eventKey="material-division" title="Material Divisions">
                            <MaterialDivisionsManagement></MaterialDivisionsManagement>
                        </Tab>      
                        <Tab eventKey="material-division-replicate" title="Material Divisions Replicate">
                            <MaterialDivisionsManagementReplicate></MaterialDivisionsManagementReplicate>
                        </Tab>   
                        <Tab eventKey="material-group" title="Material Groups">
                            <MaterialGroupsManagement></MaterialGroupsManagement>
                        </Tab> 
                        <Tab eventKey="material-subgroup" title="Material Subgroups">
                            <MaterialSubgroupsManagement></MaterialSubgroupsManagement>
                        </Tab>    
{/*                         <Tab eventKey="material-campaign" title="Campaigns">
                            <CampaignsManagement></CampaignsManagement>
                        </Tab>               */}                                                               
                    </Tabs>
                </Container>
            </div>  
        </React.Fragment>
    );
};

export default MdmManagement;