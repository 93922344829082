import httpCommon from "./http-common";
import axios from "axios";
import  TokenStorage from '../util/token-storage'
import { appHostName } from "../util/commons-utilities";

const DispatchCheckService = {
    getDispatchCheck() {
        let data = httpCommon.get("/transactions/dispatchcheck");
        return data;
    },
    getMaterialsByDispatchCheckId(id) {
        let data = httpCommon.get(`/transactions/dispatchcheck/${id}/material`);
        return data;
    },
    getDispatchCheckById(id) {
        let data = httpCommon.get(`/transactions/dispatchcheck/${id}`);
        return data;
    },
    getPhotosByMaterialDispatchCheck(id) {
        let data = httpCommon.get(`/transactions/dispatchcheckmaterial/photos/${id}`);
        return data;
    },
    saveDispatchCheck(data) {
        let response = httpCommon.post("/transactions/dispatchcheck",JSON.stringify(data))
        return response;
    },
    saveMaterialToDispatchCheck(data) {
        let response = httpCommon.post("/transactions/dispatchcheck/material",JSON.stringify(data))
        return response;
    },
    addPhoto(photoData) {
        let bodyFormData = new FormData();
        bodyFormData.append('dispatchcheckmaterial', photoData.dispatchcheckmaterial);
        bodyFormData.append('photo', photoData.photo);
        bodyFormData.append('comments', photoData.comments);
        return axios({
            method: "post",
            url: appHostName() + "/transactions/dispatchcheckmaterial/photos",
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${TokenStorage.getToken()}`,
                "Content-Type": "multipart/form-data"
              }
          });
    },
    updateDispatchCheck(data) {
        let response = httpCommon.put(`/transactions/dispatchcheck/${data.id}`,JSON.stringify(data))
        return response;
    },
    updateDispatchCheckItem(data) {
        let response = httpCommon.put(`/transactions/dispatchcheck/${data.id}/material`,JSON.stringify(data))
        return response;
    },
    applyDispatch(dispatch) {
        let response = httpCommon.put(`/transactions/dispatchcheck/${dispatch.id}`,JSON.stringify(dispatch))
        return response;
    }
};

export default DispatchCheckService;