import React, { useEffect, useState } from 'react';
// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../store/actions";
import usePageTitle from '../util/head-element'; 
import { Container, Row, Col } from 'reactstrap';
import { isNullOrEmpty, toggleVerticalMenu } from '../util/commons-utilities';
import  './playbook.css'

const Pdfviewer = () => {
    usePageTitle("Docs | Warehouse");
    const dispatch = useDispatch();
    /*
    set breadcrumbs
    */
    useEffect(() => {
        const breadCrumbItems = {
            title: "Manual de Warehouse Management Web Application",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [fileName, setFileName] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [fileHref, setFileHref] = useState('');

    function handleFileName(filename){
        setFileName(filename);
    }

    useEffect(() => {
        setFileUrl(`/pdf/${fileName}#view=FitH`);
        setFileHref(`/pdf/${fileName}`);
    }, [fileName]);

    useEffect(() => {
        toggleVerticalMenu();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content h-100">
                <Container key="page-content-container" fluid>
                    <Row key="row-1">
                        <Col md={3} key="row-1-col-1">
                            <ul className='list-group'>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("Divisiones.pdf")}>Divisiones</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("PersonasACargo.pdf")}>Personas a cargo (People in charge)</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("TipoDeMaterial.pdf")}>Tipo de material (Material Types)</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("SubtiposMaterial.pdf")}>Subtipos de materiales</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("GruposDeMaterial.pdf")}>Grupos de material</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("Materiales.pdf")}>Creación y registro de materiales</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("OrdenEntradasBodegas.pdf")}>Orden de entradas a bodegas (Entries)</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("ConfirmacionRecepcionBodega.pdf")}>Confirmación de recepción en bodega y registro de inventario (Entries Check)</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("Distribucion.pdf")}>Módulo de Distribución</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("ConfirmacionRecepcionMateriales.pdf")}>Confirmación de recepción de materiales (Release Check)</span> 
                                </li>
                                <li className='list-group-item'>
                                    <span role="button" onClick={(e)=> handleFileName("Reports.pdf")}>Reports</span> 
                                </li>
                            </ul>                            
                        </Col> 
                        <Col md={9} key="row-1-col-2">
                            {
                                !!fileName && !isNullOrEmpty(fileName) && !!fileUrl && !isNullOrEmpty(fileUrl)?(
                                    <object data={fileUrl} type="application/pdf" className='pdf-container'>
                                        <p><span href={fileHref}>{fileName}</span> </p>
                                    </object>
                                ):(<></>)
                            }
                        </Col>                     
                    </Row>               
                </Container>
            </div>        
        </React.Fragment>
    );
};

export default Pdfviewer;