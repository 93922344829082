import { hasValue } from "./commons-utilities";
const TokenStorage = {
  getToken() {
    let tokenString = sessionStorage.getItem('token');
    let userToken = null;
    if(hasValue(tokenString) ){
      userToken = JSON.parse(tokenString);
    }
    if (hasValue(userToken))
      return userToken?.access_token
    else
      return localStorage.getItem('token');
  },
  saveToken (userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
  }
};

export default TokenStorage;