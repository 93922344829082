import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-eva-icons';

//Import images
import logoDarkSm from "../../assets/images/logo-dark-sm.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSm from "../../assets/images/logo-light-sm.png";

import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/Common/BreadCrumb';

import { connect } from "react-redux";

//redux
import { useDispatch } from "react-redux";

// Redux Store
import {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
} from "../../store/actions";

import { isNullOrEmpty } from '../../util/commons-utilities';

const Header = ({ breadCrumbItems, showRightSidebar }) => {
    const dispatch = useDispatch();

    //scroll navbar
    const [navClass, setnavClass] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("sticky");
        } else {
            setnavClass("");
        }
    }

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("sidebar-enable");
            body.setAttribute("data-sidebar-size","lg");
        }
    }

    return (
        <React.Fragment>
           { 
            !isNullOrEmpty(breadCrumbItems.title)?
                (
                    <header id="page-topbar" className={"isvertical-topbar " + navClass}>
                        <div className="navbar-header">
                            <div className="d-flex">
        
                                <div className="navbar-brand-box">
                                    <Link to="/" className="logo logo-dark">
                                        <span className="logo-sm">
                                            <img src={logoDarkSm} alt="" height="22" />
                                        </span>
                                        <span className="logo-lg">
                                            <img src={logoDarkSm} alt="" height="22" />
                                        </span>
                                    </Link>
        
                                    <Link to="/" className="logo logo-light">
                                        <span className="logo-lg">
                                            <img src={logoLight} alt="" height="22" />
                                        </span>
                                        <span className="logo-sm">
                                            <img src={logoLightSm} alt="" height="22" />
                                        </span>
                                    </Link>
                                </div>
        
                                <button type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger" onClick={() => tToggle() }>
                                    <div className="hamburger-icon open">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </button>
                                
                                <BreadCrumb breadCrumbItems={breadCrumbItems} />
                            </div>
                            <div className="d-none">
                            
                                <div className="dropdown d-inline-block">
                                    <button
                                        onClick={() => {
                                            dispatch(showRightSidebarAction(!showRightSidebar));
                                        }}
                                        type="button"
                                        className="btn header-item noti-icon right-bar-toggle"
                                        id="right-bar-toggle">
                                        <Icon name="settings-outline" fill="#555b6d" size="large" />
                                    </button>
                                </div>
        
                            </div>
                        </div>
                    </header>
                ):(<></>)
            }            
        </React.Fragment>
    );
};

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const {
        layoutType,
        showRightSidebar,
        leftMenu,
        leftSideBarType,
    } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})((Header));
