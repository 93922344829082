import React, { useEffect, useState } from 'react';
import useFullPageLoader from "../../Hooks/useFullPageLoader"
import { 
    Col, 
    Container, 
    Row,
    Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import usePageTitle from "../../util/head-element";
import MaterialTypesService from '../../services/material-types-service';
import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { objectToCsv, searchInTable } from "../../util/commons-utilities";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const MaterialTypesManagement = () => {
    const dispatch = useDispatch();
    usePageTitle("Types | Warehouse");
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Types",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);


    const [data, setData] = useState(null)

    const [modal_MaterialType, setmodal_MaterialType] = useState(false);
    const [modal_success, setmodal_success] = useState(false);
    const [typeID, setTypeID] = useState(0);
    const [materialTypeDescription, setMaterialTypeDescription] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const[editing, setEditing] = useState(false);
    const captions = ["Id","Name"];

    function setCurrentMaterialType(assettype){
        setTypeID(assettype.id);
        setMaterialTypeDescription(assettype.value);
        setModalTitle('Update ' + assettype.value);
    }

    function newMaterialType(){
        setCurrentMaterialType({
            id:0,
            value:""
        });
        setModalTitle('Add New');
        tog_MaterialType();
        setEditing(false);
    }

    function editMaterialType(assettype){
        setCurrentMaterialType(assettype);
        tog_MaterialType();
        setEditing(true);
    }

    function save_MaterialType() {
        let materialType = {
            id: typeID,
            value: materialTypeDescription
        };
        showLoader();
        if(!editing){
            MaterialTypesService.saveNewMaterialType(materialType)
            .then((response) => {                
                refreshData(response.data);
                setmodal_MaterialType(false);
                setModalMessage('Material Type Added Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);},1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }
        else{
            MaterialTypesService.updateMaterialType(materialType)
            .then((response) => {               
                refreshData(response.data);
                setmodal_MaterialType(false);
                setModalMessage('Material Type Updated Successfully');
                setmodal_success(true);
                setTimeout(()=>{setmodal_success(false);},1000);
            })
            .catch(err => console.dir(err))
            .finally(() => hideLoader()) 
        }       
    }

    function tog_MaterialType() {
        setmodal_MaterialType(!modal_MaterialType);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    function refreshData(materialType){
        let currentData = data;
        if(!editing){
            currentData.push(materialType);
        }
        else{
            let index = currentData.findIndex(m => {return m.id === typeID;});
            currentData[index] = materialType;
        }
        setData(currentData);
    }

    useEffect(() => {
        showLoader();
        MaterialTypesService.getMaterialTypes()
            .then((response) => {              
                setData(response.data)
            })
            .catch(err => console.dir(err))
            .finally(() => {hideLoader()})   
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <div className="mb-3">

                            </div>
                        </Col>

                        <Col md={8}>
                            <Row>
                                <Col md={8}>
                                    <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"material-types-table", inputSearchId:"filter-text"})}></FormControl>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <Button variant="outline-secondary" onClick={() => objectToCsv("categories", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                                </li>
                                            </ul>
                                        </div>                   
                                        <div>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newMaterialType()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div id="material-types-table-container" className="table-responsive">
                                        <table id="material-types-table" className="table table-hover mb-0">
                                            <thead>
                                                <tr className="header">
                                                    <th className="dark">Id</th>
                                                    <th className="dark">Description</th>
                                                    <th className="dark">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {!!data && data.length > 0 ? data.map((item, key) => {
                                                return(
                                                    <tr key={`material-types-table-tbody-tr-${key}`}>
                                                        <td>{item.id}</td>
                                                        <td>{item.value}</td>
                                                        <td>
                                                            <Button variant="outline-secondary" size="sm" onClick={()=>editMaterialType(item)}><i className="bx bx-edit me-1"></i> Edit</Button>                                                        
                                                        </td>
                                                    </tr>
                                                )   
                                                }):(<tr><td colSpan={3}>No data found.</td></tr>)
                                            }
                                            </tbody>
                                        </table>    
                        </div>
                    </Row>
                </Container>
            </div>

            {loader}

            {/* Add New Modal */}
            <Modal
                isOpen={modal_MaterialType}
                toggle={() => {
                    tog_MaterialType();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="assetTypeModalLabel">
                        {` ${modalTitle} `}
                    </h5>

                    <button
                        onClick={() => {
                            setmodal_MaterialType(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Description">Description</label>
                                <input type="text" className="form-control" placeholder="Enter Description" id="AddNew-Description" onChange={(e) => setMaterialTypeDescription(e.target.value)} value={materialTypeDescription} />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_MaterialType(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_MaterialType();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{modalMessage}</p>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default MaterialTypesManagement;