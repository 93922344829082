import httpCommon from "./http-common";

const UserService = {
  getUsers() {
    let response = httpCommon.get(
      "/administration/user"
    );
    return response;
  },
  getUserInfo() {
    let response = httpCommon.get(
      "/administration/user/me"
    );
    return response;
  },
};
 

export default UserService;
