import React, { useEffect, useState, useRef } from 'react';
import useFullPageLoader from "../../Hooks/useFullPageLoader";
import { Link } from 'react-router-dom';

import { Row, Col, Card, CardBody, Modal } from 'reactstrap';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from "leaflet"
import "leaflet/dist/leaflet.css";
import "./warehouse-styles.css";

import CountryService from '../../services/country-service';
import WarehouseService from '../../services/warehouse-service';
import { FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { objectToCsv, searchInTable } from "../../util/commons-utilities";

const WarehousesList = () => {

    const [modal_warehouse, setModalWarehouse] = useState(false);
    const [modal_success, setModalSuccess] = useState(false);

    const [data, setData] = useState(null);
    const [countries, setCountries] = useState([]);

    const [warehouseID, setWarehouseID] = useState(0);
    const [active, setActive] = useState(false);
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [contactFirstName, setContactFirstName] = useState('');
    const [contactLastName, setContactLastName] = useState('');
    const [contactPhoneNumber, setContactPhoneNumber] = useState('');
    const [contactEmailAddress, setContactEmailAddress] = useState('');
    const [contactOffice, setContactOffice] = useState('');
    const [contactMemo, setContactMemo] = useState('');

    const [editing, setEditing] = useState(false);
    const [message, setMessage] = useState('');
    const [modaltitle, setModaltitle] = useState('');
    

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const captions = ["Id", "Name", "Country", "Region", "City", "Address", "Latitude", "Longitude"];

    function newWarehouse(){
        setCurrentWarehouse({
            id:0,
            active:true,
            name:"",
            country:0,
            region:0,
            city:0,
            latitude:0,
            longitude:0,
            contact_first_name: '',
            contact_last_name:'',
            contact_phone_number:'',
            contact_email_address:'',
            contact_office:'',
            contact_memo:''
        });

        setModaltitle('Add New');
        setEditing(false);
        tog_warehouse();
    }


    function editWarehouse(warehouse){
        setCurrentWarehouse(warehouse);
        setModaltitle(`Edit ${warehouse.name}`);
        setEditing(true);
        tog_warehouse();
        console.log("editing: ", editing);
    }

    function setCurrentWarehouse(warehouse){
        setWarehouseID(warehouse.id);
        setActive(warehouse.active);
        setName(warehouse.name);
        setCountry(!!warehouse.country? warehouse.country.id: 0);
        setRegion(!!warehouse.region? warehouse.region.id : 0);
        setCity(!!warehouse.city? warehouse.city.id : 0);
        setAddress(!!warehouse.address?warehouse.address:'');
        setLatitude(warehouse.latitude);
        setLongitude(warehouse.longitude);
        setContactFirstName(!!warehouse.contact_first_name?warehouse.contact_first_name:'');
        setContactLastName(!!warehouse.contact_last_name?warehouse.contact_last_name:'');
        setContactPhoneNumber(!!warehouse.contact_phone_number?warehouse.contact_phone_number:'');
        setContactEmailAddress(!!warehouse.contact_email_address?warehouse.contact_email_address:'');
        setContactOffice(!!warehouse.contact_office?warehouse.contact_office:'');
        setContactMemo(!!warehouse.contact_memo?warehouse.contact_memo:'');
    }

    function save_warehouse() {

        let warehouse = {
            id:warehouseID,
            name: name,
            country: country,
            region: region===0?null:region,
            city: city===0?null:city,
            address: address,
            latitude: latitude,
            longitude: longitude,
            contact_first_name: contactFirstName,
            contact_last_name: contactLastName,
            contact_phone_number: contactPhoneNumber,
            contact_email_address: contactEmailAddress,
            contact_office: contactOffice,
            contact_memo: contactMemo,
            active: active
        }
        showLoader();
        if(!editing){
            WarehouseService.saveNewWarehouse(warehouse)
            .then((response) => {             
                refreshData(response.data);
                setModalWarehouse(false);
                setMessage("Warehouse added successfully");
                setModalSuccess(true);
            })
            .catch(err => errorHandler(err))
            .finally(() => hideLoader())     
        }
        else {            
            WarehouseService.updateWarehouse(warehouse)
            .then((response) => {             
                refreshData(response.data);
                setEditing(false);
                setModalWarehouse(false);
                setMessage("Warehouse updated successfully");
                setModalSuccess(true);
                setTimeout(()=>{setModalSuccess(false)}, 1000);
            })
            .catch(err => console.dir(err))
            .finally(() => { hideLoader(); }) 
        }
    }

    function errorHandler(error){
        alert(error.request.responseText);
        console.dir(error)
    }

    function tog_warehouse() {
        setModalWarehouse(!modal_warehouse);
    }
    function tog_success() {
        setModalSuccess(!modal_success);
    }

    function refreshData(warehouse){
        let objCountry = countries.find(t => {
            return t.id === warehouse.country;
        });
        let currentData = data;
        if(!editing){
            currentData.push(warehouse);
        }
        else{
           let prodIndex = currentData.findIndex(p => {return p.id === warehouseID;});
           warehouse.id = warehouseID;
           warehouse.country = objCountry;
           currentData[prodIndex] = warehouse;
        }
        setData(currentData);
    }   

    useEffect(() => {
        showLoader();
        WarehouseService.getWarehouses()
        .then((response) => {      
            setData(response.data);            
        })
        .catch(err => console.dir(err))
        .finally(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            hideLoader()
        }); 

        CountryService.getCountries()
        .then((response) => {
            setCountries(response.data);
        })
        .catch(err => console.dir(err))

    }, []);

    const markerIcon = new L.Icon({
            iconUrl: require("../../assets/images/location_pin_icon.png"),
            iconSize: [25,35],
            iconAnchor: [17,46],
            popupAnchor:[0, -46]
        });

    const [center] = useState({lat:9.0226016, lng:-79.4755589});
    const ZOOM_LEVEL = 5;
    const mapRef = useRef();

    return (
        <React.Fragment>
            <Row key="Row_1" className="align-items-center">
                <Col xl={12} lg={12} key="col-map-container">
                    <Card key="Row_1_Card_1">
                        <CardBody className="card-body">
                            <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
                                <TileLayer
                                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {!!data && data.length > 0 ? data.map((wh, key) => (
                                    <Marker position={[wh.latitude, wh.longitude]}
                                    icon={markerIcon}
                                    key={key}
                                    >
                                        <Popup>
                                            <b>{wh.name}</b><br/>{wh.region}, {wh.country}
                                        </Popup>
                                    </Marker>
                                )):(null)
                            }
                            </MapContainer>                                     
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row key="Row_2" className="align-items-center">
                <Col md={4}>
                    <div className="mb-3">

                    </div>
                </Col>

                <Col md={8}>
                    <Row>
                        <Col md={8}>
                            <FormControl id="filter-text" type="input" placeholder="Search..." onKeyUp={()=> searchInTable({tableId:"warehouses-table", inputSearchId:"filter-text"})}></FormControl>
                        </Col>
                        <Col md={4}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                <div>
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <Button variant="outline-secondary" onClick={() => objectToCsv("warehouses", data, captions)}><i className="bx bx-download me-1"></i> Export</Button>
                                        </li>
                                    </ul>
                                </div>                   
                                <div>
                                    <Link to="#" data-bs-toggle="modal" data-bs-target=".add-new" className="btn btn-outline-secondary" onClick={() => newWarehouse()}><i className="bx bx-plus me-1"></i> Add New</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row key="Row_3">
                <div id="warehouses-table-containeer" className="table-responsive">
                    <table id="warehouses-table" className="table table-hover mb-0">
                        <thead key="warehouses-table-thead">
                            <tr key="warehouses-table-thead-tr" className="header">
                                <th className="dark">Name</th>
                                <th className="dark">Address</th>
                                <th className="dark">Contact</th>
                                <th className="dark">Phone</th>
                                <th className="dark">Email</th>
                                <th className="dark">Memo</th>
                                <th className="dark">Actions</th>
                            </tr>
                        </thead>
                        <tbody key="warehouses-table-tbody">
                        {!!data && data.length > 0 ? data.sort((a, b) => a.order > b.order ? 1 : -1).map((item, key) => {
                            return(
                                <tr key={`warehouses-table-tbody-tr-${key}`}>
                                    <td>{item.name}</td>
                                    <td>{item.address}</td>
                                    <td>{!!item.contact_first_name? item.contact_first_name : ""} {!!item.contact_last_name? item.contact_last_name : ""}</td>
                                    <td>{!!item.contact_phone_number? item.contact_phone_number : ""}</td>
                                    <td>{!!item.contact_email_address? item.contact_email_address : ""}</td>
                                    <td>{!!item.contact_memo? item.contact_memo : ""}</td>
                                    <td>
                                        <Button variant="outline-secondary" size="sm" onClick={()=>editWarehouse(item)}><i className="bx bx-edit me-1"></i> Edit</Button>
                                        &nbsp;
                                        <Link key={`Warehouse_Row_Link_${key}`} className="btn btn-outline-secondary btn-sm waves-effect waves-light"
                                        to={{
                                                pathname: '/warehouse-detail',
                                                search: `?wid=${item.id}`
                                            }}
                                        > <i className="bx bx-grid me-1"></i> Details
                                        </Link>                                                 
                                    </td>
                                </tr>
                            )   
                            }):(<tr><td colSpan={3}>No data found.</td></tr>)
                        }
                        </tbody>
                    </table>    
                </div>  
            </Row>  

            {loader}

            {/* Add New Modal */}
            <Modal key="Warehouse_Modal"
                isOpen={modal_warehouse}
                toggle={() => {
                    tog_warehouse();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="warehouseModalLabel">
                        {modaltitle}
                    </h5>

                    <button
                        onClick={() => {
                            setModalWarehouse(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body grid gap-3">
                    <div className="p-2 g-col-12">
                        <div className="position-absolute top-0 end-0">
                            <div className="form-check form-switch p-2">
                                <input className="form-check-input" type="checkbox" role="switch" id="WarehouseActive" onChange={(e) => setActive(!active)} defaultChecked={active} ></input>
                                <label className="form-check-label" for="WarehouseActive">Active</label>
                            </div>                                    
                        </div>
                    </div>
                    <div className="p-2 g-col-12">
                        <Tabs
                            defaultActiveKey="warehouse-general-info"
                            transition={true}
                            id="warehouse-form-tab"
                            className="mb-3"
                            >
                            <Tab eventKey="warehouse-general-info" title="General Information">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseName">Name</label>
                                            <input type="text" maxLength={100} className="form-control" placeholder="Enter Name" id="WarehouseName" onChange={(e) => setName(e.target.value)} value={name} disabled={!active}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseCountry">Country</label>
                                            <Form.Select placeholder="Select a country" id="WarehouseCountry" class="form-select" onChange={(e) => setCountry(e.target.value)} value={country} disabled={!active}>
                                                <option value={0} key="ddl-warehouse-country-option-empty">Select the country</option>
                                                {!!countries && countries.length > 0 ? countries.map((item, key) => {
                                                    return(
                                                        <option key={`ddl-warehouse-country-option-${key}`} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    )   
                                                    }):(<option></option>)
                                                }                                    
                                            </Form.Select>
                                        </div>
                                    </div>          
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseAddress">Address</label>
                                            <textarea maxLength={250} className="form-control" placeholder="Enter Address" id="WarehouseAddress" onChange={(e) => setAddress(e.target.value)} value={address}  readOnly={!active}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseLatitude">Latitude</label>
                                            <input type="text" maxLength={11} className="form-control" placeholder="Enter Latitude" id="WarehouseLatitude" onChange={(e) => setLatitude(e.target.value)} value={latitude} readOnly={!active}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseLongitude">Longitude</label>
                                            <input type="text" maxLength={10} className="form-control" placeholder="Enter Longitude" id="WarehouseLongitude" onChange={(e) => setLongitude(e.target.value)} value={longitude}readOnly={!active} />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="warehouse-contact-person" title="Contact Person">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseContactFirstName">First Name</label>
                                            <input type="text" maxLength={50} className="form-control" placeholder="Enter First Name" id="WarehouseContactFirstName" onChange={(e) => setContactFirstName(e.target.value)} value={contactFirstName} readOnly={!active}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseContactLastName">Last Name</label>
                                            <input type="text" maxLength={50} className="form-control" placeholder="Enter Last Name" id="WarehouseContactLastName" onChange={(e) => setContactLastName(e.target.value)} value={contactLastName} readOnly={!active}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseContactPhoneNumber">Phone Number</label>
                                            <input type="tel" maxLength={20} className="form-control" placeholder="Enter Phone Number" id="WarehouseContactPhoneNumber" onChange={(e) => setContactPhoneNumber(e.target.value)} value={contactPhoneNumber} readOnly={!active}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseContactEmailAddress">Email Address</label>
                                            <input type="email" maxLength={100} className="form-control" placeholder="Enter Email Address" id="WarehouseContactEmailAddress" onChange={(e) => setContactEmailAddress(e.target.value)} value={contactEmailAddress} readOnly={!active}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseContactOffice">Office</label>
                                            <input type="text" maxLength={100} className="form-control" placeholder="Enter Office" id="WarehouseContactOffice" onChange={(e) => setContactOffice(e.target.value)} value={contactOffice} readOnly={!active}/>
                                        </div>
                                    </div>
                                </div>   
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="WarehouseContactMemo">Memo</label>
                                            <textarea maxLength={250} className="form-control" placeholder="Enter Memo" id="WarehouseContactMemo" onChange={(e) => setContactMemo(e.target.value)} value={contactMemo} readOnly={!active}></textarea>
                                        </div>
                                    </div>
                                </div>                                           
                            </Tab>                      
                        </Tabs>                  
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setModalWarehouse(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    save_warehouse();
                                }}
                            >
                                <i className="bx bx-save me-1"></i> Save
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                key="Message_Modal"
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <p className="mt-3">{message}</p>
                    </div>
                </div>
            </Modal>     
        </React.Fragment>
    );
};

export default WarehousesList;