import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import WarehousesList from './warehouse-list';

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";
import usePageTitle from "../../util/head-element";
const WarehousesManagement = () => {
    const dispatch = useDispatch();   
    /*
    set breadcrumbs
    */
    useEffect(() => {
        dispatch(setBreadcrumb({
            title: "Warehouses - List",
        }));
    }, [dispatch]);
    const [key, setKey] = useState('warehouse-list');
    usePageTitle("Warehouses -List | Warehouse");

    
    const changePageTitle = (tabkey) => {
        let element = document.querySelector('.page-title');
        switch(tabkey) {
            case 'warehouse-list':
                element.innerHTML = "Warehouses - List";
                break;
            case 'material-entry-check':
                element.innerHTML = "Warehouses - Material Entry Check";
                break;
            case 'material-realese-check':
                element.innerHTML = "Warehouses - Material Release Check";
                break;
            case 'material-inventory-report':
                element.innerHTML = "Warehouses - Inventory Report";
                break;                       
            default:
                element.innerHTML = "Warehouses - List";
                break;
          }        
    };


    const changeTitle = (tabkey) => {

        setKey(tabkey);

        switch(tabkey) {
            case 'warehouse-list':
                document.title = "Warehouses - List | Warehouse";
                break;
            case 'material-entry':
                document.title = "Warehouses - Material Entry | Warehouse";
                break;
            case 'material-entry-check':
                document.title = "Warehouses - Material Entry Check | Warehouse";
                break;
             case 'material-distribution-list':
                document.title = "Warehouses - Distribution List | Warehouse";
                break;
            case 'material-inventory-report':
                document.title = "Warehouses - Inventory Report | Warehouse";
                break;                             
            default:
              break;
          }        
          
        changePageTitle(tabkey);
    };

    return (
        <React.Fragment>
            <div className="page-content">               
                <Container fluid>
                    <Tabs
                        defaultActiveKey="warehouse-list"
                        transition={true}
                        id="warehouse-management-tab"
                        activeKey={key}
                        onSelect={(k) => changeTitle(k)}
                        className="mb-3"
                        >
                        <Tab eventKey="warehouse-list" title="List">
                            <WarehousesList></WarehousesList>
                        </Tab>                  
                    </Tabs>
                </Container>
            </div>  
        </React.Fragment>
    );
};

export default WarehousesManagement;